import { axios } from '../config/axios';
import security from './security';

const WebServices = {};

WebServices.CNPJ = (cnpjcpf) => {
  return security.validation(axios.get('/ws/cnpj/' + cnpjcpf));
};
WebServices.CPF = (cnpjcpf) => {
  return security.validation(axios.get('/ws/cpf/' + cnpjcpf));
};
WebServices.CEP = (cep) => {
  return security.validation(axios.get('/ws/enderecos/' + cep));
};


export default WebServices;
