import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Table } from 'react-bootstrap'
import { IoSearch } from "react-icons/io5";
import ReactPaginate from 'react-paginate';
import Pacientes from '../../services/Pacientes';
import { SweetAlert } from '../sweetalert';
import { formatarTelefone } from '../../utilities/formatarTelefoneInput';
import { parseISO, differenceInYears } from 'date-fns';
import { calcIdade } from '../../utilities/calcIdade';

const ModalSearchPacientes = ({
  show,
  handleClose,
  setValues,
  handleShowModalCadastroPessoa,
  // setEnderecoFormatado
  loading,
  setLoading,
  // setPhoto
  registerName = 'paciente',
  registerNameId = 'paciente_id'
}) => {

  const defaultFilter = 'nome'

  const [filterValue, setFilterValue] = useState('');
  const [filterType, setFilterType] = useState(defaultFilter);

  const [totalPagesPacientes, setTotalPagesPacientes] = useState(1);
  const [totalRegistrosClientes, setTotalRegistrosPacientes] = useState(1);
  const [pacientes, setPacientes] = useState([]);
  const [tablePacientes, setTableClientes] = useState([]);

  useEffect(() => {
    if (show === true) {
      listAllPacientes(1)
    }
  }, [show]);

  function listAllPacientes(pageNumber, filter = '') {

    Pacientes.listAllClientes(pageNumber, filter)
      .then((data) => {
        setPacientes(data.data.data)
        setTableClientes(data.data.data)
        setTotalPagesPacientes(data.data.paging.total_pages)
        setTotalRegistrosPacientes(data.data.paging.total_records)
      })
      .catch((error) => {
        SweetAlert.error(error.response.data.message, 'Pacientes');
      })

  }

  const handlePageChange = (selected) => {
    listAllPacientes(selected.selected + 1);
  };

  const handleFilter = () => {
    const filter = {
      [filterType]: filterValue
    }
    const filtered = listAllPacientes(1, filter)

    setTableClientes(filtered);
  };

  function closeAll() {
    setFilterType(defaultFilter)
    setFilterValue('')
    setTableClientes(pacientes)
    handleClose();
  }

  function enterClick(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      handleFilter();
    }
  }

  const handleClick = (categoryData) => {
    setLoading(true);

    Pacientes.listPessoaById(categoryData.id)
      .then((dados) => {

        setValues(registerName, categoryData.nome + calcIdade(dados.data.data_nascimento));
        setValues(registerNameId, categoryData.id);
        setValues('idade', dados.data.data_nascimento);

        // setValues('idade', idade);
        // setValues('cpf', dados.data.cpf);
        // setValues('telefone1', dados.data.telefone);
        // setValues('telefone2', dados.data.telefone2);
        // setValues('email', dados.data.email);

        // setPhoto(dados.data.foto)

        setLoading(false);
      })
      .catch((error) => {
        SweetAlert.error(error.response.data.message, 'Pacientes');
        setLoading(false);
      });
  };


  return (
    <Modal show={show} onHide={handleClose} centered size='xl' backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Pesquisa de pacientes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex flex-column'>
          <Form.Group className=" d-flex gap-2 align-items-center">
            <Form.Label style={{ minWidth: 100 }}>Campo</Form.Label>
            <Form.Select
              size="sm"
              value={filterType}
              onChange={(e) => setFilterType(e.target.value)}
            >
              <option value='nome'>NOME</option>
              <option value='cpf'>CPF</option>
              {/* <option value='codigo'>CÓDIGO</option> */}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mt-2 d-flex gap-2 align-items-center">
            <Form.Label style={{ minWidth: 100 }}>Pesquisar por</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
              placeholder="Filtrar"
              onKeyDown={(e) => enterClick(e)}
              autoFocus
            />
            <Button
              onClick={handleFilter}
              variant='outline-secondary d-flex align-items-center'>
              <IoSearch size={17} color='blue' />
            </Button>
          </Form.Group>

          <div className='mt-3' style={{ border: '1px solid black', height: 350, overflowY: 'scroll' }}>
            <Table striped bordered size='sm'>
              <thead>
                <tr>
                  <th style={{ whiteSpace: "nowrap", paddingLeft: 10, paddingRight: 10 }}>Nome</th>
                  <th style={{ whiteSpace: "nowrap", paddingLeft: 10, paddingRight: 10 }}>Tel. Fixo</th>
                  <th style={{ whiteSpace: "nowrap", paddingLeft: 10, paddingRight: 10 }}>Celular</th>
                </tr>
              </thead>
              <tbody>
                {tablePacientes?.map(cliente => {
                  return (
                    <tr key={cliente.codigo}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        handleClick(cliente)
                        closeAll()
                      }}>
                      <td style={{ whiteSpace: "nowrap", overflowX: "auto", paddingLeft: 10, paddingRight: 10 }}>{cliente.nome}</td>
                      <td style={{ whiteSpace: "nowrap", overflowX: "auto", paddingLeft: 10, paddingRight: 10 }}>{formatarTelefone(cliente.telefone)}</td>
                      <td style={{ whiteSpace: "nowrap", overflowX: "auto", paddingLeft: 10, paddingRight: 10 }}>{formatarTelefone(cliente.telefone2)}</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
          <p className='m-0 p-0' style={{ color: 'red', fontSize: 12 }}> * Somente registros ativos serão mostrados!</p>
        </div>

        <div className='mt-4 d-flex justify-content-between px-3 flex-wrap gap-4'>
          <span> Total: {totalRegistrosClientes}</span>
          <ReactPaginate
            pageCount={totalPagesPacientes}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={handlePageChange}
            containerClassName={'pagination_container'}
            activeClassName={'active'}
            previousLabel={'<'}
            nextLabel={'>'}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-between'>
        <Button variant="outline-success" onClick={handleShowModalCadastroPessoa}>
          Cadastrar novo paciente
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Fechar
        </Button>
        {/* <Button variant="primary" onClick={handleClose}>
          Save Changes
        </Button> */}
      </Modal.Footer>
    </Modal>
  )
}

export default ModalSearchPacientes