import React, { useState } from "react";
import { Alert, Button } from 'react-bootstrap'

import Router from "./router";
import Header from "../components/Header";
import { useAuth } from '../context/auth';
import AsideBar from '../components/AsideBar';
import { useVersion } from '../context/version';

function Main(props) {

  const { isConnected, user, sessionCurrent, showMenu } = useAuth()
  const { attVersion } = useVersion()
  const [open, setOpen] = useState(false)

  return (
    <div style={{ backgroundColor: '#f8f9fb', minHeight: '100vh' }}>
      {isConnected === true ?
        <>
          <Header
            open={open}
            setOpen={setOpen}
          />
          <AsideBar
            open={open}
            setOpen={setOpen}
          >
            {attVersion === true ?
              <div
                className='d-flex justify-content-center align-items-center'
                style={{ position: 'absolute', left: '40%', top: '3%', zIndex: 1000 }}
              >
                <Alert variant='warning' className='d-flex gap-3 align-items-center' style={{ zIndex: 1000 }}>
                  Atualize a página! Há uma nova versão disponível
                  <Button size='sm' variant='danger' onClick={() => window.location.reload(true)}>Atualizar</Button>
                </Alert>
              </div>
              : ''}
            <div style={{ marginTop: 60 }}>
              <Router />
            </div>
          </AsideBar>
        </>
        : <Router />
      }
      {/* <Container fluid> */}
      {/* </Container> */}
    </div >
  );
}

export default Main;
