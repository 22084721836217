import React, { useEffect, useState, useRef } from 'react';
import { Button, Card, Container, Form, Row, Modal, Col, Tab, Nav, Table } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Prontuarios from '../../services/Prontuarios';
import Perguntas from '../../services/Perguntas';
import Pacientes from '../../services/Pacientes';
import { SweetAlert } from '../../components/sweetalert';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../context/auth';
import { LoadingSkeleton } from '../../components/LoadingSkeleton';
import HeaderLabel from '../../components/HeaderLabel';
import { format, addHours } from 'date-fns';
import Loading from '../../routers/loading';
import { formatarTelefone } from '../../utilities/formatarTelefoneInput';
import ButtonSaveContent from '../../components/ButtonSaveContent';
import ModalSearchPacientes from '../../components/ModalSearch/ModalSearchPacientes';
import CadastroPaciente from '../cadastro_paciente';
import { IoSearch } from "react-icons/io5";
import { FaX } from "react-icons/fa6";
import { differenceInYears, parseISO } from 'date-fns'
import { FaRegEye } from "react-icons/fa";

import './style.css'
import ModalSearchTecnicos from '../../components/ModalSearch/ModalSearchTecnicos';
import Imagem from '../imagem_prontuario';
import { calcIdade } from '../../utilities/calcIdade';
import ModalAtendimentosProntuario from '../../components/Modals/ModalAtendimentosProntuario';
import ModalExamesProntuario from '../../components/Modals/ModalExamesProntuario';
import ModalAtestadosProntuario from '../../components/Modals/ModalAtestadosProntuario';
import ImagemAtendimento from '../imagem_prontuario_atendimentos';

const CadastroProntuarios = ({ origem, handleCloseModalCadastroProntuario, setValueModal, handleCloseProntuarios, values }) => {
  const { handleSubmit, register, getValues, setValue, reset, watch, setFocus } = useForm();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);

  const tipo_tecnico = watch('tipo_tecnico')

  const allowedExtensions = ['.jpg', '.jpeg', '.png', '.pdf', '.doc', '.docx', '.xls', '.xlsx'];

  const [key, setKey] = useState('geral');

  const [photo, setPhoto] = useState(null);
  const [files, setFiles] = useState([]);
  const [atendimentos, setAtendimentos] = useState([]);
  const [exames, setExames] = useState([]);
  const [atestados, setAtestados] = useState([]);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [perguntas, setPerguntas] = useState([]);
  const [progresso, setProgresso] = useState(null);

  const [imagemServidor, setImagemServidor] = useState(null);
  const [imagemServidor2, setImagemServidor2] = useState(null);

  const [currentAtendimentoIndex, setCurrentAtendimentoIndex] = useState(null);
  const [currentExamesIndex, setCurrentExamesIndex] = useState(null);
  const [currentAtestadosIndex, setCurrentAtestadosIndex] = useState(null);

  const [showCliente, setShowCliente] = useState(false);
  const [showTecnico, setShowTecnico] = useState(false);
  const [showModalCadastroPessoa, setShowModalCadastroPessoa] = useState(false);
  const [showModalAtendimentos, setShowModalAtendimentos] = useState(false);
  const [showModalExames, setShowModalExames] = useState(false);
  const [showModalAtestados, setShowModalAtestados] = useState(false);

  const [showDeleteButton, setShowDeleteButton] = useState(false);

  const params = useParams()
  const navigate = useNavigate()
  const canvasRef = useRef(null);
  const canvasRef2 = useRef(null);
  // const semNumero = watch('semNumero');

  const handleCloseCliente = () => setShowCliente(false);
  const handleShowCliente = () => setShowCliente(true);
  const handleCloseTecnico = () => setShowTecnico(false);
  const handleShowTecnico = () => setShowTecnico(true);
  const handleShowModalCadastroPessoa = () => setShowModalCadastroPessoa(true);
  const handleCloseModalCadastroPessoa = () => setShowModalCadastroPessoa(false);
  const handleShowModalAtendimentos = () => {
    setValue('tecnico_atendimento', getValues('tecnico'))
    setValue('tecnico_atendimento_id', getValues('tecnico_id'))
    setValue('data_atendimento', format(new Date(), 'yyyy-MM-dd'))
    setValue('descricao_atendimento', '(DTV) Diversified Technique \n- Duplo transverso torácico \n- Costela média \n- Costela baixa\n- Joelho\n\n(MRB) Modified Rotary Break\n- Cervical alta\n- Cervical baixa\n- Costela alta\n⁠-Duplo torácica alta\n-⁠ ATM\n\nLumbar Roll\n- Lombar\n- ⁠Sacro\n⁠- Ilíaco\n\nDROP\n- Toggle Recoil\n\nSOT\n\nAtivador\n\nDistração Lombosacra\n\nDistração Sacroilíaca\n\nDistração Coxofemural\n\nDistração Tíbiotalar\n\nManipulação Fascial\n- Manual\n- Gastron\n- Trapézio\n- Piriforme\n⁠- Glúteo médio\n⁠- Torácico anterior com Body Drop')
    setShowModalAtendimentos(true)
    setTimeout(() => {
      setFocus('descricao_atendimento')
    }, 500);
  };

  const handleCloseModalAtendimentos = () => {
    setCurrentAtendimentoIndex(null)
    setShowModalAtendimentos(false);
    setShowDeleteButton(false);
    limpaModalAtendimentos()
  };

  const handleShowModalExames = () => {
    setValue('tecnico_exame', getValues('tecnico'))
    setValue('tecnico_exame_id', getValues('tecnico_id'))
    setValue('data_solicitacao', format(new Date(), 'yyyy-MM-dd'))
    setShowModalExames(true)
    setTimeout(() => {
      setFocus('descricao_atendimento')
    }, 500);
  };

  const handleCloseModalExames = () => {
    setCurrentExamesIndex(null)
    setShowModalExames(false);
    setShowDeleteButton(false);
    limpaModalExames()
  };

  const handleShowModalAtestados = () => {
    setValue('tecnico_atestado', getValues('tecnico'))
    setValue('tecnico_atestado_id', getValues('tecnico_id'))
    setValue('data_atestado', format(new Date(), 'yyyy-MM-dd'))
    setValue('tipo_atendimento', 'QUIROPRÁTICO')
    setShowModalAtestados(true)
    setTimeout(() => {
      setFocus('descricao_atendimento')
    }, 500);
  };

  const handleCloseModalAtestados = () => {
    setCurrentAtestadosIndex(null)
    setShowModalAtestados(false);
    setShowDeleteButton(false);
    limpaModalAtestados()
  };

  useEffect(() => {

    if (origem === 'modal') {
      setValue('paciente', values.paciente);
      setValue('paciente_id', values.paciente_id)
      setValue('tecnico', values.tecnico);
      setValue('tecnico_id', values.tecnico_id)
      setValue('cpf', values.cpf)
      setValue('idade', values.idade)
      setValue('email', values.email)
      setValue('telefone1', values.telefone1)
      setValue('telefone2', values.telefone2)
    }

    if (user.tecnico?.id) {
      // setValue('tecnico_id', user.tecnico.id)
      setValue('tecnico_id', user.tecnico.id)
      setValue('tecnico', user.tecnico.nome)
    }


    if (params.id) {
      setLoading(true)
      Prontuarios.listId(params.id)
        .then((data2) => {
          const data = JSON.parse(JSON.stringify(data2))

          let newPerguntas = []

          setImagemServidor(data2.data.imagem_url)
          setImagemServidor2(data2.data.imagem_atendimento_url)

          data2.data.perguntas.forEach(perguntas => {
            newPerguntas.push(perguntas.pergunta)
            setValue('pergunta ' + perguntas.pergunta.id, perguntas.resposta)
          })

          setPerguntas(newPerguntas)

          const newAtendimentos = []

          data2.data.atendimentos.forEach(atendimento => {

            newAtendimentos.push({
              data_atendimento: format(addHours(new Date(atendimento.data_atendimento), 3), 'yyyy-MM-dd'),
              tecnico_atendimento: atendimento.tecnico_atendimento.nome,
              tecnico_atendimento_id: atendimento.tecnico_atendimento.id,
              descricao: atendimento.descricao,
              titulo: atendimento.titulo,
            })
          })

          setAtendimentos(newAtendimentos)

          let newExames = data.data.exames

          newExames = newExames.map(item => {
            item.tecnico_atendimento_id = item.tecnico_atendimento.id
            return item
          })

          setExames(newExames);

          let newAtestado = data.data.atestados

          newAtestado = newAtestado.map(item => {
            item.tecnico_atendimento_id = item.tecnico_atendimento.id
            return item
          })

          setAtestados(newAtestado)

          setValue('imagem_atendimento_observacao', data2.data.imagem_atendimento_observacao);
          setValue('tecnico', data2.data.tecnico_responsavel.nome);
          setValue('tecnico_id', data2.data.tecnico_responsavel.id);
          setValue('titulo', data2.data.titulo);
          setValue('data_cadastro', data2.data.data_cadastro);
          setProgresso(data2.data.progresso)

          let newFiles = []

          data2.data.anexos.map(file => {
            return (
              newFiles.push({
                "id": file.id,
                "descricao": file.descricao,
                "nome_arquivo": file.nome_arquivo,
                "name": file.nome_arquivo,
                "url": file.url
              })
            )
          })

          setFiles(newFiles)

          Pacientes.listId(data.data.paciente.id)
            .then((data3) => {

              let cpfCnpj = data3.data.cpf

              cpfCnpj = cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4').slice(0, 14);

              const dataNascimento = data3.data.data_nascimento;
              const idade = differenceInYears(new Date(), parseISO(dataNascimento));

              setValue('paciente', data3.data.nome + calcIdade(data3.data.data_nascimento));
              setValue('paciente_id', data3.data.id);
              setValue('cpf', cpfCnpj);
              setValue('idade', idade);
              setValue('email', data3.data.email);
              setValue('telefone1', formatarTelefone(data3.data?.telefone));
              setValue('telefone2', formatarTelefone(data3.data?.telefone2));
              setPhoto(data3.data.foto)

            })
            .catch((error) => {
              console.log(error)
              SweetAlert.error(error?.response?.data?.message, 'Paciente');
            })

        })
        .catch((error) => {
          console.log(error)
          SweetAlert.error(error?.response?.data?.message, 'Paciente');
        })
        .finally(() => {
          setLoading(false)
        })

    } else {
      setValue('data_cadastro', format(new Date(), 'yyyy-MM-dd'))
      Perguntas.listAllAtivos()
        .then((data) => {
          setPerguntas(data.data.data)
        })
        .catch((error) => {
          console.log(error)
          SweetAlert.error(error?.response?.data?.message, 'Paciente');
        })
    }

  }, []);

  function onSubmit(data) {

    const perguntas = Object.keys(data)
      .filter(key => key.startsWith("pergunta"))
      .map(key => {
        let newKey = key.split(' ')
        return ({
          pergunta_id: newKey[1],
          resposta: data[key]
        })
      });

    if (data.data_cadastro === '' || data.data_cadastro === undefined || data.data_cadastro === null) {
      return SweetAlert.error('Data de cadastro é um campo obrigatório', 'Prontuário');
    }
    if (data.paciente_id === '' || data.paciente_id === undefined || data.paciente_id === null) {
      return SweetAlert.error('Paciente é um campo obrigatório', 'Prontuário');
    }
    if (data.tecnico_id === '' || data.tecnico_id === undefined || data.tecnico_id === null) {
      return SweetAlert.error('Doutor é um campo obrigatório', 'Prontuário');
    }
    if (data.titulo === '' || data.titulo === undefined || data.titulo === null) {
      return SweetAlert.error('Título é um campo obrigatório', 'Prontuário');
    }

    setLoading(true)

    atendimentos.forEach(item => {
      item.tecnico_atendimento_id = item.tecnico_atendimento_id
    })

    const prontuario = {
      "data_cadastro": data.data_cadastro,
      "paciente_id": data.paciente_id,
      "tecnico_responsavel_id": data.tecnico_id,
      "perguntas": perguntas,
      "atendimentos": atendimentos,
      "titulo": data.titulo,
      "imagem_atendimento_observacao": data.imagem_atendimento_observacao ?? '',
      "exames": exames,
      "atestados": atestados,
    }

    const canvas = canvasRef.current;
    const formDataImagem = new FormData();

    canvas.toBlob(async (blob) => {
      formDataImagem.append('file', blob, 'imagem_com_riscos.png');
    })

    const canvas2 = canvasRef2.current;
    const formDataImagem2 = new FormData();

    canvas2.toBlob(async (blob) => {
      formDataImagem2.append('file', blob, 'imagem_com_riscos2.png');
    })



    if (params.id) {
      Prontuarios.update(params.id, prontuario)
        .then((data) => {

          Prontuarios.imagem(params.id, formDataImagem)
            .then((data) => { })
            .catch((error) => {
              SweetAlert.error(error?.response?.data?.message, 'Prontuários');
            });
          Prontuarios.imagemAtendimento(params.id, formDataImagem2)
            .then((data) => { })
            .catch((error) => {
              SweetAlert.error(error?.response?.data?.message, 'Prontuários');
            });

          const newFiles = [];

          files.forEach(file => {
            if (file.id === undefined) {
              newFiles.push(file);
            }
          })

          if (newFiles.length > 0) {

            let formData = new FormData();
            newFiles.forEach((file, index) => {
              formData.append(`file${index}`, file); // Use uma chave única para cada arquivo
            });

            Prontuarios.createPhoto(data.data.id, formData)
              .then(() => {

              })
              .catch((error) => {
                SweetAlert.error(error?.response?.data?.message, 'Prontuários');
              });
          }

          if (deletedFiles.length > 0) {

            deletedFiles.forEach((deletedFile) => {
              Prontuarios.deletePhoto(data.data.id, deletedFile)
                .then(() => {
                  // SweetAlert.success('Registro atualizado com sucesso', 'Prontuários')
                  // navigate(-1)
                })
                .catch((error) => {
                  SweetAlert.error(error?.response?.data?.message, 'Prontuários');
                });
            })

          }

          SweetAlert.success('Registro atualizado com sucesso', 'Prontuários')
          navigate(-1)

        })
        .catch((error) => {
          SweetAlert.error(error?.response?.data?.message, 'Prontuário');
        })
        .finally(() => { setLoading(false) })
    } else {
      Prontuarios.create(prontuario)
        .then((data) => {

          Prontuarios.imagem(data.data.id, formDataImagem)
            .then((data) => { })
            .catch((error) => {
              SweetAlert.error(error?.response?.data?.message, 'Prontuários');
            });
          Prontuarios.imagemAtendimento(data.data.id, formDataImagem2)
            .then((data) => { })
            .catch((error) => {
              SweetAlert.error(error?.response?.data?.message, 'Prontuários');
            });

          if (files && files.length > 0) {

            let formData = new FormData();
            files.forEach((file, index) => {
              formData.append(`file${index}`, file); // Use uma chave única para cada arquivo
            });

            Prontuarios.createPhoto(data.data.id, formData)
              .then(() => {
                SweetAlert.success('Prontuário cadastrado com sucesso', 'Prontuário');
                reset();
                setAtendimentos([])
                setFiles(null)
              })
              .catch((error) => {
                SweetAlert.error(error?.response?.data?.message, 'Paciente');
              });
          } else {
            SweetAlert.success('Prontuário cadastrado com sucesso', 'Prontuário');
            reset();
            setAtendimentos([])
            setValue('data_cadastro', format(new Date(), 'yyyy-MM-dd'))
          }

          if (origem === 'modal') {
            setValueModal('prontuario', data.data.titulo)
            setValueModal('prontuario_id', data.data.id)
            handleCloseModalCadastroProntuario()
            handleCloseProntuarios()
          }

          setKey('geral')


        })
        .catch((error) => {
          SweetAlert.error(error?.response?.data?.message, 'Prontuário');
        })
        .finally(() => {
          setLoading(false);
        });

    }

  }

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const removeFile = (index, file) => {

    if (file.id !== undefined) {
      deletedFiles.push(file.id);
    }

    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const openFileInNewTab = (fileUrl) => {
    window.open(fileUrl.url, '_blank');
  };

  const limpaModalAtendimentos = () => {
    setValue('data_atendimento', '');
    setValue('tecnico_atendimento', '');
    setValue('tecnico_atendimento_id', '');
    setValue('descricao_atendimento', '');
    setValue('titulo_atendimento', '');
  }
  const limpaModalExames = () => {
    setValue('data_solicitacao', '');
    setValue('tecnico_exame', '');
    setValue('tecnico_exame_id', '');
    setValue('cervical_ap_transoral_descricao', '');
    setValue('cervical_lateral_descricao', '');
    setValue('cervical_obliquo_descricao', '');
    setValue('dorsal_ap_descricao', '');
    setValue('dorsal_lateral_descricao', '');
    setValue('lombar_ap_lombopelvica_descricao', '');
    setValue('lombar_lateral_descricao', '');
    setValue('lombar_obliquo_descricao', '');
    setValue('outros_1_nome', '');
    setValue('outros_1_descricao', '');
    setValue('outros_2_nome', '');
    setValue('outros_2_descricao', '');
    setValue('outros_3_nome', '');
    setValue('outros_3_descricao', '');
  }
  const limpaModalAtestados = () => {
    setValue('data_atestado', '');
    setValue('tecnico_atestado', '');
    setValue('tecnico_atestado_id', '');
    setValue('hora_inicio_atestado', '');
    setValue('hora_final_atestado', '');
    setValue('tipo_atendimento', '');
    setValue('observacao_atestado', '');
  }

  const handleEditAtendimento = (index) => {
    const atendimento = atendimentos[index];
    setValue('data_atendimento', format(addHours(new Date(atendimento.data_atendimento), 3), 'yyyy-MM-dd'));
    setValue('tecnico_atendimento', atendimento.tecnico_atendimento);
    setValue('tecnico_atendimento_id', atendimento.tecnico_atendimento_id);
    setValue('descricao_atendimento', atendimento.descricao);
    setValue('titulo_atendimento', atendimento.titulo);
    setShowDeleteButton(true);
    setCurrentAtendimentoIndex(index);
    setShowModalAtendimentos(true);
  };

  const handleEditExame = (index) => {
    const exame = exames[index];
    setValue('data_solicitacao', format(addHours(new Date(exame.data_solicitacao), 3), 'yyyy-MM-dd'));
    setValue('tecnico_exame', exame.tecnico_atendimento.nome);
    setValue('tecnico_exame_id', exame.tecnico_atendimento.id);
    setValue('cervical_ap_transoral_descricao', exame.cervical_ap_transoral_descricao);
    setValue('cervical_ap_descricao', exame.cervical_ap_descricao);
    setValue('cervical_lateral_descricao', exame.cervical_lateral_descricao);
    setValue('cervical_obliquo_descricao', exame.cervical_obliquo_descricao);
    setValue('dorsal_ap_descricao', exame.dorsal_ap_descricao);
    setValue('dorsal_lateral_descricao', exame.dorsal_lateral_descricao);
    setValue('lombar_ap_lombopelvica_descricao', exame.lombar_ap_lombopelvica_descricao);
    setValue('lombar_lateral_descricao', exame.lombar_lateral_descricao);
    setValue('lombar_obliquo_descricao', exame.lombar_obliquo_descricao);
    setValue('outros_1_nome', exame.outros_1_nome);
    setValue('outros_1_descricao', exame.outros_1_descricao);
    setValue('outros_2_nome', exame.outros_2_nome);
    setValue('outros_2_descricao', exame.outros_2_descricao);
    setValue('outros_3_nome', exame.outros_3_nome);
    setValue('outros_3_descricao', exame.outros_3_descricao);

    setShowDeleteButton(true);
    setCurrentExamesIndex(index);
    setShowModalExames(true);

  };

  const handleEditAtestado = (index) => {
    const atestado = atestados[index];
    setValue('data_atestado', format(addHours(new Date(atestado.data_atendimento), 3), 'yyyy-MM-dd'));
    setValue('tecnico_atestado', atestado.tecnico_atendimento.nome);
    setValue('tecnico_atestado_id', atestado.tecnico_atendimento.id);
    setValue('hora_inicio_atestado', atestado.horario_inicial);
    setValue('hora_final_atestado', atestado.horario_final);
    setValue('tipo_atendimento', atestado.tipo_atendimento);
    setValue('observacao_atestado', atestado.observacao);

    setShowDeleteButton(true);
    setCurrentAtestadosIndex(index);
    setShowModalAtestados(true);

  };

  const handleDeleteAtendimento = (index) => {
    setAtendimentos(atendimentos.filter((_, i) => i !== index));
    handleCloseModalAtendimentos()
    limpaModalAtendimentos()
    setShowDeleteButton(false)
  };

  const handleDeleteExame = (index) => {
    setExames(exames.filter((_, i) => i !== index));
    handleCloseModalExames()
    limpaModalExames()
    setShowDeleteButton(false)
  };

  const handleDeleteAtestados = (index) => {
    setAtestados(atestados.filter((_, i) => i !== index));
    handleCloseModalAtestados()
    limpaModalAtestados()
    setShowDeleteButton(false)
  };

  function showSelectedPaciente() {
    const paciente_id = getValues('paciente_id')

    if (paciente_id === undefined) {
      return SweetAlert.error('Selecione um paciente antes de visualizar seus dados', 'Paciente')
    }

    const url = `/pacientes/cadastro/${paciente_id}`
    window.open(url, '_blank')
  }

  const salvarAtendimento = () => {
    const values = getValues();

    const newAtendimento = {
      data_atendimento: addHours(values.data_atendimento, 3),
      tecnico_atendimento: values.tecnico_atendimento,
      tecnico_atendimento_id: values.tecnico_atendimento_id,
      descricao: values.descricao_atendimento,
      titulo: values.titulo_atendimento,
    };

    const updatedAtendimentos = [...atendimentos];

    if (currentAtendimentoIndex !== null) {
      updatedAtendimentos[currentAtendimentoIndex] = newAtendimento;
    } else {
      updatedAtendimentos.push(newAtendimento);
    }

    setAtendimentos(updatedAtendimentos);
    limpaModalAtendimentos()
    setCurrentAtendimentoIndex(null);
    handleCloseModalAtendimentos();
    setShowDeleteButton(false)
  };

  const salvarExame = () => {

    setLoading(true)

    const values = getValues();

    const newExame = {
      "tecnico_atendimento_id": values.tecnico_exame_id,
      "cervical_ap_transoral_descricao": values.cervical_ap_transoral_descricao,
      "cervical_ap_descricao": values.cervical_ap_descricao,
      "cervical_lateral_descricao": values.cervical_lateral_descricao,
      "cervical_obliquo_descricao": values.cervical_obliquo_descricao,
      "dorsal_ap_descricao": values.dorsal_ap_descricao,
      "dorsal_lateral_descricao": values.dorsal_lateral_descricao,
      "lombar_ap_lombopelvica_descricao": values.lombar_ap_lombopelvica_descricao,
      "lombar_lateral_descricao": values.lombar_lateral_descricao,
      "lombar_obliquo_descricao": values.lombar_obliquo_descricao,
      "outros_1_nome": values.outros_1_nome,
      "outros_1_descricao": values.outros_1_descricao,
      "outros_2_nome": values.outros_2_nome,
      "outros_2_descricao": values.outros_2_descricao,
      "outros_3_nome": values.outros_3_nome,
      "outros_3_descricao": values.outros_3_descricao,
      "data_solicitacao": format(addHours(new Date(values.data_solicitacao), 3), "yyyy-MM-dd")
    }

    const updatedExames = [...exames];

    if (currentExamesIndex !== null) {
      updatedExames[currentExamesIndex] = newExame;
    } else {
      updatedExames.push(newExame);
    }

    const allExames = {
      "exames": updatedExames
    }

    Prontuarios.update(params.id, allExames)
      .then((data) => {

        let newExames = data.data.exames

        newExames = newExames.map(item => {
          item.tecnico_atendimento_id = item.tecnico_atendimento.id
          return item
        })

        setExames(newExames);
      })
      .catch((error) => {
        SweetAlert.error(error?.response?.data?.message, 'Prontuário');
      })
      .finally(() => { setLoading(false) })

    limpaModalExames()
    setCurrentExamesIndex(null);
    handleCloseModalExames();
    setShowDeleteButton(false)
  };
  const salvarAtestados = () => {

    const values = getValues();

    if (values.tecnico_atestado_id === '' || values.tecnico_atestado_id === undefined || values.tecnico_atestado_id === null) {
      return SweetAlert.error('Doutor é um campo obrigatório', 'Prontuário');
    }
    if (values.data_atestado === '' || values.data_atestado === undefined || values.data_atestado === null) {
      return SweetAlert.error('Data de atendimento é um campo obrigatório', 'Prontuário');
    }
    if (values.hora_inicio_atestado === '' || values.hora_inicio_atestado === undefined || values.hora_inicio_atestado === null) {
      return SweetAlert.error('Horário de início é um campo obrigatório', 'Prontuário');
    }
    if (values.hora_final_atestado === '' || values.hora_final_atestado === undefined || values.hora_final_atestado === null) {
      return SweetAlert.error('Horário do final é um campo obrigatório', 'Prontuário');
    }
    if (values.tipo_atendimento === '' || values.tipo_atendimento === undefined || values.tipo_atendimento === null) {
      return SweetAlert.error('Tipo de atendimento é um campo obrigatório', 'Prontuário');
    }

    setLoading(true)

    const newAtestado = {
      "tecnico_atendimento_id": values.tecnico_atestado_id,
      "data_atendimento": format(addHours(new Date(values.data_atestado), 3), "yyyy-MM-dd"),
      "horario_inicial": values.hora_inicio_atestado,
      "horario_final": values.hora_final_atestado,
      "tipo_atendimento": values.tipo_atendimento,
      "observacao": values.observacao_atestado,
    }

    const updatedAtestados = [...atestados];

    if (currentAtestadosIndex !== null) {
      updatedAtestados[currentAtestadosIndex] = newAtestado;
    } else {
      updatedAtestados.push(newAtestado);
    }

    const allAtestados = {
      "atestados": updatedAtestados
    }

    Prontuarios.update(params.id, allAtestados)
      .then((data) => {

        let newAtestados = data.data.atestados

        newAtestados = newAtestados.map(item => {
          item.tecnico_atendimento_id = item.tecnico_atendimento.id
          return item
        })

        setAtestados(newAtestados);

      })
      .catch((error) => {
        SweetAlert.error(error?.response?.data?.message, 'Prontuário');
      })
      .finally(() => { setLoading(false) })

    limpaModalAtestados()
    setCurrentAtestadosIndex(null);
    handleCloseModalAtestados();
    setShowDeleteButton(false)
  };

  const isValidFileType = (fileName) => {
    const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2).toLowerCase();
    return allowedExtensions.includes(`.${fileExtension}`);
  };

  const handlePaste = (event) => {
    const items = (event.clipboardData || event.originalEvent.clipboardData).items;
    const files = [];

    for (const item of items) {
      if (item.kind === 'file') {
        const file = item.getAsFile();
        if (file && isValidFileType(file.name)) {
          files.push(file);
        } else {
          SweetAlert.error(`O arquivo ${file.name} tem uma extensão inválida. Apenas arquivos permitidos serão adicionados.`, 'Anexos');
        }
      }
    }

    if (files.length > 0) {
      setFiles((prevFiles) => [...prevFiles, ...files]);
    }
  };

  const imprimirExame = (idExame) => {
    Prontuarios.pdfExame(params.id, idExame)
      .then((dados) => {
        const pdfBlob = new Blob([dados.data], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(pdfBlob);

        window.open(pdfUrl, '_blank');
      })
      .catch((error) => {
        SweetAlert.error(error?.response?.data?.message, 'Prontuário');
      })
  }

  const imprimirAtestado = (idAtestado) => {
    Prontuarios.pdfAtestado(params.id, idAtestado)
      .then((dados) => {
        const pdfBlob = new Blob([dados.data], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(pdfBlob);

        window.open(pdfUrl, '_blank');
      })
      .catch((error) => {
        SweetAlert.error(error?.response?.data?.message, 'Prontuário');
      })
  }

  return (
    <>
      {loading === true ? <Loading /> : ''}

      <ModalSearchPacientes
        show={showCliente}
        handleClose={handleCloseCliente}
        setValues={setValue}
        handleShowModalCadastroPessoa={handleShowModalCadastroPessoa}
        handleCloseModalCadastroPessoa={handleCloseModalCadastroPessoa}
        loading={loading}
        setLoading={setLoading}
        setPhoto={setPhoto}
      />

      <ModalSearchTecnicos
        show={showTecnico}
        handleClose={handleCloseTecnico}
        setValues={setValue}
        loading={loading}
        setLoading={setLoading}
        type={tipo_tecnico}
      />

      {/* <Modal show={showModalCadastroPessoa} fullscreen centered >
        <Modal.Header closeButton onHide={handleCloseModalCadastroPessoa} />
        <Modal.Body>
          <CadastroPaciente
            origem='modal'
            handleCloseModalCadastroPessoa={handleCloseModalCadastroPessoa}
            setValueModal={setValue}
            handleCloseCliente={handleCloseCliente}
          />
        </Modal.Body>
      </Modal> */}

      {/* <Modal show={showModalAtendimentos} centered size='xl'>
        <Modal.Header closeButton onHide={handleCloseModalAtendimentos} />
        <Modal.Body>

          <Row className='pb-2'>
            <Form.Group className="col-12 col-lg-5">
              <Form.Label>Doutor</Form.Label>
              {loading ? <LoadingSkeleton /> :
                <div className='d-flex'>
                  <Form.Control size='sm' type="text" {...register('tecnico_atendimento')} disabled />
                  <Button
                    variant='outline-secondary'
                    className='p-0 px-2 d-flex align-items-center ms-1'
                    onClick={() => { setValue('tipo_tecnico', 'atendimento'); handleShowTecnico() }}
                  >
                    <IoSearch size={20} />
                  </Button>
                </div>
              }
            </Form.Group>

            <Form.Group className="col-12 col-lg-3">
              <Form.Label>Data do atendimento</Form.Label>
              {loading ? <LoadingSkeleton /> : <Form.Control size='sm' type="date" {...register('data_atendimento')} />}
            </Form.Group>
          </Row>

          <Row className='pb-2'>
            <Form.Group className="col-12">
              <Form.Label>Descrição</Form.Label>
              {loading ? <LoadingSkeleton /> : <Form.Control size='sm' as="textarea" rows={3} {...register('descricao_atendimento')} />}
            </Form.Group>
          </Row>

        </Modal.Body>
        <Modal.Footer className={`d-flex ${showDeleteButton === true ? 'justify-content-between' : 'justify-content-end'}`}>
          {showDeleteButton === true ? <Button
            variant="outline-danger"
            // size='sm'
            onClick={() => handleDelete(currentAtendimentoIndex)}
          >
            Excluir
          </Button> : ''}
          <Button onClick={() => salvarAtendimento()}>Salvar</Button>
        </Modal.Footer>
      </Modal> */}

      <ModalAtendimentosProntuario
        showModal={showModalAtendimentos}
        handleCloseModal={handleCloseModalAtendimentos}
        register={register}
        handleShowTecnico={handleShowTecnico}
        setValue={setValue}
        showDeleteButton={showDeleteButton}
        handleDelete={handleDeleteAtendimento}
        currentIndex={currentAtendimentoIndex}
        salvar={salvarAtendimento}
        loading={loading}
        setLoading={setLoading}
        getValues={getValues}
      />

      <ModalExamesProntuario
        showModal={showModalExames}
        handleCloseModal={handleCloseModalExames}
        register={register}
        handleShowTecnico={handleShowTecnico}
        setValue={setValue}
        showDeleteButton={showDeleteButton}
        handleDelete={handleDeleteExame}
        currentIndex={currentExamesIndex}
        salvar={salvarExame}
        loading={loading}
        setLoading={setLoading}
        getValues={getValues}
      />

      <ModalAtestadosProntuario
        showModal={showModalAtestados}
        handleCloseModal={handleCloseModalAtestados}
        register={register}
        handleShowTecnico={handleShowTecnico}
        setValue={setValue}
        showDeleteButton={showDeleteButton}
        handleDelete={handleDeleteAtestados}
        currentIndex={currentAtestadosIndex}
        salvar={salvarAtestados}
        loading={loading}
        setLoading={setLoading}
        getValues={getValues}
      />

      {/* <Container> */}
      <Form onSubmit={handleSubmit(onSubmit)} className='p-3'>

        <HeaderLabel
          label='Prontuários'
          button={origem !== 'modal'}
        />
        <div className='d-flex justify-content-end mt-3 mb-3'>
          <Button variant="success" type="submit" className="col-12 col-lg-auto d-flex align-items-center gap-1" disabled={loading}>
            <ButtonSaveContent />
          </Button>
        </div>


        <Tab.Container id="left-tabs-example" activeKey={key} onSelect={(k) => setKey(k)}>
          <Nav justify variant="tabs" defaultActiveKey="geral">
            <Nav.Item>
              <Nav.Link eventKey="geral">Geral</Nav.Link>
            </Nav.Item>
            {/* <Nav.Item>
                <Nav.Link eventKey="geral">Geral</Nav.Link>
              </Nav.Item> */}
            <Nav.Item>
              <Nav.Link eventKey="perguntas">Perguntas</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="anexos">Anexos</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="atendimentos">Atendimentos</Nav.Link>
            </Nav.Item>
            {params.id !== undefined ?
              <>
                <Nav.Item>
                  <Nav.Link eventKey="exames">Exames</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="atestados">Atestados</Nav.Link>
                </Nav.Item>
              </>
              : ''}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="geral">
              <Card className='cardShadow'>
                <Card.Body className='p-3'>
                  <Row className='d-flex align-items-end'>
                    <Form.Group className="col-12 col-lg-auto mb-2">
                      <h4 className='textPrimary'>Geral</h4>
                    </Form.Group>
                  </Row>

                  <Row>
                    <Form.Group className="col-12 col-lg-2">
                      <Form.Label>Data <span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Control size='sm' type="date" {...register('data_cadastro')} />
                    </Form.Group>
                  </Row>

                  <Row className='pb-2 mt-2'>

                    <Form.Group className="col-12 col-lg-10">
                      <Form.Label>Paciente <span style={{ color: 'red' }}>*</span></Form.Label>
                      <div className='d-flex'>
                        <Form.Control size='sm' type="text" {...register('paciente')} disabled />
                        <Button
                          variant='outline-secondary'
                          className='p-0 px-2 d-flex align-items-center ms-1'
                          onClick={handleShowCliente}
                        >
                          <IoSearch size={20} />
                        </Button>
                        <Button
                          variant='outline-secondary'
                          className='p-0 px-2 d-flex align-items-center ms-1'
                          onClick={showSelectedPaciente}
                        >
                          <FaRegEye size={20} />
                        </Button>

                      </div>
                    </Form.Group>

                    <Form.Group className="col-12 col-lg-5 mt-2">
                      {loading ? <LoadingSkeleton /> :
                        <>
                          <Form.Label>Doutor <span style={{ color: 'red' }}>*</span></Form.Label>
                          <div className='d-flex'>
                            <Form.Control size='sm' type="text" {...register('tecnico')} disabled />
                            <Button
                              variant='outline-secondary'
                              className='p-0 px-2 d-flex align-items-center ms-1'
                              onClick={() => { setValue('tipo_tecnico', 'prontuario'); handleShowTecnico() }}
                            >
                              <IoSearch size={20} />
                            </Button>
                          </div>
                        </>
                      }
                    </Form.Group>
                  </Row>
                  <Row className='pb-2'>
                    <Form.Group className="col-12 col-lg-5">
                      <Form.Label>Título <span style={{ color: 'red' }}>*</span></Form.Label>
                      {loading ? <LoadingSkeleton /> :
                        <Form.Control size='sm' type="text" {...register('titulo')} />
                      }
                    </Form.Group>
                  </Row>

                  {loading ? "" : <Imagem
                    canvasRef={canvasRef}
                    imagemServidor={imagemServidor}
                    setImagemServidor={setImagemServidor}
                  />}

                </Card.Body>
              </Card>
            </Tab.Pane>

            <Tab.Pane eventKey="perguntas">
              <Card className='cardShadow'>
                <Card.Body className='p-3'>
                  <Row className='d-flex align-items-end'>
                    <Form.Group className="col-12 col-lg-auto mb-2">
                      <h4 className='textPrimary'>Perguntas</h4>
                    </Form.Group>
                  </Row>
                  {perguntas?.map((pergunta, index) => (
                    <Row className='pt-2' key={pergunta.id}>
                      <Form.Group className="col-12">
                        <Form.Label>{pergunta.descricao} </Form.Label>
                        {loading ? <LoadingSkeleton /> : <Form.Control size='sm' type="text" {...register('pergunta ' + pergunta.id)} />}
                      </Form.Group>
                    </Row>
                  ))}
                </Card.Body>
              </Card>
            </Tab.Pane>

            <Tab.Pane eventKey="anexos">
              <Card className='cardShadow'>
                <Card.Body className='p-3' onPaste={handlePaste}>
                  <Row className='d-flex align-items-end'>
                    <Form.Group className="col-12 col-lg-auto mb-2">
                      <h4 className='textPrimary'>Anexos</h4>
                    </Form.Group>
                  </Row>
                  <Form.Group>
                    <div className="file-upload-container">
                      <span className="file-upload-text">Clique para adicionar arquivos</span>
                      <Form.Control
                        id="fileUpload"
                        type="file"
                        multiple
                        accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                        onChange={handleFileChange}
                      />
                    </div>
                  </Form.Group>
                  <Row className='mt-3'>
                    {files?.length > 0 && files.map((file, index) => (
                      <Col key={index} xs={12} sm={6} md={4} lg={3} className='mb-3'>
                        <div className={file.url !== undefined ? 'file-preview-save' : 'file-preview-not-save'}>
                          <p onClick={() => file.url !== undefined ? openFileInNewTab(file) : ''}>{file.name}</p>
                          <Button variant="danger" className='m-0 p-0 p-1' onClick={() => removeFile(index, file)}><FaX /></Button>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Card.Body>
              </Card>
            </Tab.Pane>

            <Tab.Pane eventKey="atendimentos">
              <Card className='cardShadow'>
                <Card.Body className='p-3'>
                  <Row className='d-flex align-items-end'>
                    <Form.Group className="col-12 col-lg-auto mb-2 d-flex">
                      <h4 className='textPrimary'>Atendimentos</h4>
                    </Form.Group>
                  </Row>
                  <div className='d-flex justify-content-between align-items-center'>
                    <Button className='mb-2' onClick={handleShowModalAtendimentos}>Adicionar atendimento</Button>
                    <h5>Progresso: {progresso === null ? '0/1' : progresso}</h5>
                  </div>
                  <div className='d-flex justify-content-between flex-wrap'>

                    <div className='col-12 col-lg-7 '>


                      <Table striped bordered hover responsive >
                        <thead>
                          <tr>
                            <th className='col-2 text-center'>Data</th>
                            <th className='col-3'>Doutor</th>
                            <th>Título</th>
                            <th className='col-1 text-center'>Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {atendimentos.map((atendimento, index) => (
                            <tr key={index}>
                              <td className='text-center'>{format(addHours(atendimento.data_atendimento, 3), 'dd/MM/yyyy')}</td>
                              <td>{atendimento.tecnico_atendimento}</td>
                              <td>{atendimento.titulo}</td>
                              <td className='d-flex justify-content-center align-items-center gap-3'>
                                <Button
                                  variant="outline-secondary"
                                  size='sm'
                                  onClick={() => handleEditAtendimento(index)}
                                >
                                  Editar
                                </Button>

                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>

                    <div className='col-12 col-lg-4'>
                      {loading ? "" : <ImagemAtendimento
                        canvasRef={canvasRef2}
                        imagemServidor={imagemServidor2}
                        setImagemServidor={setImagemServidor2}
                        register={register}
                      />}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Tab.Pane>

            {params.id !== undefined ?
              <>
                <Tab.Pane eventKey="exames">
                  <Card className='cardShadow'>
                    <Card.Body className='p-3'>
                      <Row className='d-flex align-items-end'>
                        <Form.Group className="col-12 col-lg-auto mb-2 d-flex">
                          <h4 className='textPrimary'>Exames</h4>
                        </Form.Group>
                      </Row>
                      <div className='d-flex justify-content-between align-items-center'>
                        <Button className='mb-2' onClick={handleShowModalExames}>Adicionar exame</Button>
                      </div>
                      <Table striped bordered hover responsive>
                        <thead>
                          <tr>
                            <th className='col-2 text-center'>Data da solicitação</th>
                            <th className='col-3'>Doutor</th>
                            {/* <th>Descrição</th> */}
                            <th className='col-1 text-center'>Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {exames.map((exame, index) => (
                            <tr key={index}>
                              <td className='text-center'>{format(addHours(exame.data_solicitacao, 3), 'dd/MM/yyyy')}</td>
                              <td>{exame.tecnico_atendimento.nome}</td>
                              {/* <td>{atendimento.descricao}</td> */}
                              <td className='d-flex justify-content-center align-items-center gap-3'>
                                <Button
                                  variant="outline-secondary"
                                  size='sm'
                                  onClick={() => handleEditExame(index)}
                                >
                                  Editar
                                </Button>
                                <Button
                                  variant="outline-secondary"
                                  size='sm'
                                  onClick={() => imprimirExame(exame.id)}
                                >
                                  Imprimir
                                </Button>

                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                </Tab.Pane>

                <Tab.Pane eventKey="atestados">
                  <Card className='cardShadow'>
                    <Card.Body className='p-3'>
                      <Row className='d-flex align-items-end'>
                        <Form.Group className="col-12 col-lg-auto mb-2 d-flex">
                          <h4 className='textPrimary'>Atestados</h4>
                        </Form.Group>
                      </Row>
                      <div className='d-flex justify-content-between align-items-center'>
                        <Button className='mb-2' onClick={handleShowModalAtestados}>Adicionar atestado</Button>
                      </div>
                      <Table striped bordered hover responsive>
                        <thead>
                          <tr>
                            <th className='col-2 text-center'>Data do Atendimento</th>
                            <th className='col-3'>Doutor</th>
                            {/* <th>Descrição</th> */}
                            <th className='col-1 text-center'>Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {atestados.map((atestado, index) => (
                            <tr key={index}>
                              <td className='text-center'>{format(addHours(atestado.data_atendimento, 3), 'dd/MM/yyyy')}</td>
                              <td>{atestado.tecnico_atendimento.nome}</td>
                              {/* <td>{atestado.descricao}</td> */}
                              <td className='d-flex justify-content-center align-items-center gap-3'>
                                <Button
                                  variant="outline-secondary"
                                  size='sm'
                                  onClick={() => handleEditAtestado(index)}
                                >
                                  Editar
                                </Button>
                                <Button
                                  variant="outline-secondary"
                                  size='sm'
                                  onClick={() => imprimirAtestado(atestado.id)}
                                >
                                  Imprimir
                                </Button>

                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                </Tab.Pane>
              </>
              : ''}

          </Tab.Content>
        </Tab.Container>

        <div className='d-flex justify-content-end mt-3 mb-3'>
          <Button variant="success" type="submit" className="col-12 col-lg-auto d-flex align-items-center gap-1" disabled={loading}>
            <ButtonSaveContent />
          </Button>
        </div>

      </Form>
      {/* </Container> */}
    </>
  );
}

export default CadastroProntuarios;
