import { axios } from '../config/axios';
import security from './security'

const Agendamentos = {};

Agendamentos.create = (body) => {
  return security.validation(axios.post('/agendamentos', body));
};

Agendamentos.listAll = (filtros = '') => {

  let filtro = ''

  if (filtros !== '') {
    filtro = filtro + '&tecnico_id=' + (filtros.tecnico_id === undefined ? '' : filtros.tecnico_id)
    filtro = filtro + '&paciente_id=' + (filtros.paciente_id === undefined ? '' : filtros.paciente_id)
    filtro = filtro + '&tipo_atendimento_id=' + (filtros.tipo_atendimento_id === undefined ? '' : filtros.tipo_atendimento_id)
    filtro = filtro + '&status_id=' + (filtros.status_agendamento === undefined ? '' : filtros.status_agendamento)
    filtro = filtro + '&data_agendamento_inicial=' + (filtros.data_agendamento_inicial === undefined ? '' : filtros.data_agendamento_inicial + 'T00:00:01')
    filtro = filtro + '&data_agendamento_final=' + (filtros.data_agendamento_final === undefined ? '' : filtros.data_agendamento_final + 'T23:59:59')
  }

  return security.validation(axios.get('/agendamentos?' + filtro));
};

// Agendamentos.listAllClientesAtivos = (page = '', filtros = '') => {

//   let filtro = ''

//   if (filtros !== '') {
//     filtro = '&nome=' + (filtros.nome === undefined ? '' : filtros.nome?.toUpperCase())
//     filtro = filtro + '&email=' + (filtros.email === undefined ? '' : filtros.email.toUpperCase())
//     filtro = filtro + '&cpf=' + (filtros.cpf === undefined ? '' : filtros.cpf)
//     filtro = filtro + '&ativo=' + (filtros.ativo === undefined ? '' : filtros.ativo)
//   }

//   return security.validation(axios.get('/pacientes?ativo=true&orderby=codigo&page_size=10&page=' + page + filtro));
// };

// Agendamentos.listPessoaById = (id) => {
//   return security.validation(axios.get('/pacientes/' + id));
// };

// Agendamentos.createPhoto = (id, newPhoto) => {
//   return security.validation(axios.patch('/pacientes/' + id + '/foto', newPhoto,
//     {
//       headers: {
//         'Content-Type': 'multipart/form-data'
//       }
//     }));
// };

// Agendamentos.deletePhoto = (id) => {
//   return security.validation(axios.delete('/pacientes/' + id + '/foto'));
// };

// Agendamentos.listId = (id) => {
//   return security.validation(axios.get('/pacientes/' + id));
// };

Agendamentos.update = (id, body) => {
  return security.validation(axios.patch('/agendamentos/' + id, body));
};

Agendamentos.delete = (id) => {
  return security.validation(axios.delete('/agendamentos/' + id));
};
export default Agendamentos;
