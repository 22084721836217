import React, { useState } from 'react'
import { Form, Button, Modal } from 'react-bootstrap'
import ModalSearchTipoAtendimento from '../ModalSearch/ModalSearchTipoAtendimento'
import { IoSearch } from "react-icons/io5";
import { FaRegEye } from "react-icons/fa";
import { SweetAlert } from '../sweetalert';

const InputTipoAtendimento = ({ setValue, loading, setLoading, getValues, register, registerNameId = 'tipo_atendimento_id', registerName = 'tipo_atendimento' }) => {

  const [showTipoAtendimento, setShowTipoAtendimento] = useState(false);

  const handleCloseTipoAtendimento = () => setShowTipoAtendimento(false);
  const handleShowTipoAtendimento = () => setShowTipoAtendimento(true);

  return (
    <>
      <ModalSearchTipoAtendimento
        show={showTipoAtendimento}
        handleClose={handleCloseTipoAtendimento}
        setValues={setValue}
        loading={loading}
        setLoading={setLoading}
        registerNameId={registerNameId}
        registerName={registerName}
      />


      <Form.Group className="col-12 col-lg-10 mt-2">
        <Form.Label>Tipo de atendimento</Form.Label>
        <div className='d-flex'>
          <Form.Control size='sm' type="text" {...register(registerName)} disabled />
          <Button
            variant='outline-secondary'
            className='p-0 px-2 d-flex align-items-center ms-1'
            onClick={handleShowTipoAtendimento}
          >
            <IoSearch size={20} />
          </Button>

        </div>

      </Form.Group>
    </>

  )
}

export default InputTipoAtendimento