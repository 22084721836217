import React from 'react'
import { Dropdown, DropdownButton, Navbar } from 'react-bootstrap'
import { useAuth } from '../context/auth'
import { useNavigate } from 'react-router-dom'
import { URL } from '../config/url'
import { MdMenu } from 'react-icons/md'
import { FaUserAlt } from "react-icons/fa";
import { FaX } from 'react-icons/fa6'
import './Header.css'

const Header = ({ open, setOpen }) => {

  const { DeleteSession, user, setShowMenu, empresaSelecionada } = useAuth()
  const navigate = useNavigate();

  const nomeEmpresa = empresaSelecionada.split(' ')

  return (
    <div>
      {/* <Navbar expand="lg" className="px-4" style={{ backgroundColor: '#2d3635' }} data-bs-theme="dark">
        <Navbar.Brand href="#home" style={{ color: '#09b35e' }}>Sige Web</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto ">
            <NavDropdown title="Produtos" >
              <NavDropdown.Item onClick={() => navigate(URL.produtos_lista)}>Listagem</NavDropdown.Item>
              <NavDropdown.Item >
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link onClick={() => navigate(URL.perfil)} >Perfil</Nav.Link>
            <Nav.Link onClick={DeleteSession} >Logout</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar> */}

      {/* <Navbar className=" px-4" style={{ backgroundColor: '#2d3635' }} data-bs-theme="dark"  > */}
      <Navbar className=" py-1" style={{
        backgroundColor: process.env.REACT_APP_PRIMARY_COLOR, position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
      }} >
        {/* <div style={{ width: '100vw', backgroundColor: '#7a31e3' }} className='d-flex justify-content-between p-0 px-2  m-0' > */}
        <div style={{ width: '100vw', backgroundColor: process.env.REACT_APP_PRIMARY_COLOR }} className='d-flex justify-content-between p-0 px-2  m-0' >
          <div className='d-flex align-items-center align-center' style={{ width: '13%', marginLeft: '-6px' }}>
            <div className='btnAbrirMenu' >
              {open === false ? <MdMenu
                onClick={() => setOpen(true)}
                style={{ display: open ? 'none' : 'block' }}
                className='my-0 py-0'
                size={25}
                color='white'
              /> :
                <FaX
                  onClick={() => setOpen(false)}
                  style={{ display: open ? 'block' : 'none' }}
                  className='my-0 py-0'
                  size={20}
                  color='white'
                />}
            </div>
            <Navbar.Brand className='m-0 p-0 ' style={{ width: '100%', color: 'white' }} >
              <div className='text-center' >
                {nomeEmpresa[0]}
              </div>
            </Navbar.Brand>
          </div>
          <DropdownButton style={{ zIndex: 4 }} variant='' title={<span style={{ color: ' white', zIndex: 20 }}><FaUserAlt /> <span className='px-2'>{user?.name}</span></span>} align='end'>
            <Dropdown.Item style={{ zIndex: 4 }} onClick={() => { navigate(URL.perfil); setOpen(false) }}>Perfil</Dropdown.Item>
            <Dropdown.Item onClick={DeleteSession}>Sair</Dropdown.Item>
          </DropdownButton>
        </div>
      </Navbar>
    </div>
  )
}

export default Header