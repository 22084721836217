import React from 'react'
import './style.css'

const TableBodyRowStart = ({ text, icon = '', menor = '' }) => {


  if (menor === true) {
    return (
      <td className='tableRowStart' style={{ fontSize: 14 }}>{text}</td>
    )
  }


  return (
    <td className='tableRowStart' >
      {text}
      {text !== null && text !== '' && text !== undefined ? <span className='ms-2'>{icon}</span> : ''}
    </td>
  )
}

export default TableBodyRowStart