import { axios } from '../config/axios';
import security from './security';

const Perguntas = {};

Perguntas.create = (body) => {
  return security.validation(axios.post('/perguntas', body));
};

Perguntas.listAll = (page = '', filtros = '') => {

  let filtro = ''

  if (filtros !== '') {
    filtro = filtros.ativo !== undefined ? filtro + '&ativo=' + filtros.ativo : ''
  }

  if (filtro === undefined) {
    filtro = ''
  }

  return security.validation(axios.get('/perguntas?orderby=sequencia&page_size=100&page=' + page + filtro));
};

Perguntas.listAllAtivos = (page = '', filtros = '') => {

  let filtro = ''

  if (filtros !== '') {
    filtro = filtros.ativo !== undefined ? filtro + '&ativo=' + filtros.ativo : ''
  }

  if (filtro === undefined) {
    filtro = ''
  }

  return security.validation(axios.get('/perguntas?ativo=true&orderby=sequencia&page_size=100&page=' + page + filtro));
};

Perguntas.listId = (id) => {
  return security.validation(axios.get('/perguntas/' + id));
};

Perguntas.update = (id, body) => {
  return security.validation(axios.patch('/perguntas/' + id, body));
};

Perguntas.delete = (id) => {
  return security.validation(axios.delete('/perguntas/' + id));
};

export default Perguntas;
