import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import SessionsServices from '../../services/sessions'
import { useParams, useNavigate } from 'react-router-dom';
import { SweetAlert } from '../../components/sweetalert';
import { URL } from '../../config/url';
import Loading from '../../components/Loading';

function CriarUsuarioConfirmar() {
  const [loadingConfirmar, setLoadingRecuperar] = useState(false)
  const { hash } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    try {
      setLoadingRecuperar(true)
      SessionsServices.activeUser(hash)
      SweetAlert.success('Usuário ativado!', 'Usuário')
      setLoadingRecuperar(false)
      navigate(URL.login)
    } catch (error) {
      setLoadingRecuperar(false)
      SweetAlert.error(error, 'Usuário')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      {loadingConfirmar ? <Loading /> : ''}
      <Container className="mt-2">
        <Row className="align-items-center justify-content-center mt-5">
          <Col xs={12} sm={12} md={7} lg={4}>
            <h2>Usuário está ativo!</h2>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default React.memo(CriarUsuarioConfirmar)