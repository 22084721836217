import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Table } from 'react-bootstrap'
import { IoSearch } from "react-icons/io5";
import ReactPaginate from 'react-paginate';
import { SweetAlert } from '../sweetalert';
import { formatarDocumento } from '../../utilities/formatCpfCnpj';
import { parseISO, differenceInYears } from 'date-fns';
import Prontuarios from '../../services/Prontuarios';
import { format } from 'date-fns'

const ModalSearchProntuarios = ({
  show,
  handleClose,
  setValues,
  loading,
  setLoading,
  handleShowModalCadastroProntuario
}) => {

  const defaultFilter = 'paciente.nome'

  const [filterValue, setFilterValue] = useState('');
  const [filterType, setFilterType] = useState(defaultFilter);

  const [totalPagesTiposAtendimentos, setTotalPagesTiposAtendimentos] = useState(1);
  const [totalRegistrosTiposAtendimentos, setTotalRegistrosTiposAtendimentos] = useState(1);
  const [tiposAtendimentos, setTiposAtendimentos] = useState([]);
  const [tableTiposAtendimentos, setTableTiposAtendimentos] = useState([]);

  useEffect(() => {
    if (show === true) {
      listAllProntuarios(1)
    }
  }, [show]);

  function listAllProntuarios(pageNumber, filter = '') {
    Prontuarios.listAll(pageNumber, filter, 'modal')
      .then((data) => {
        setTiposAtendimentos(data.data.data)
        setTableTiposAtendimentos(data.data.data)
        setTotalPagesTiposAtendimentos(data.data.paging.total_pages)
        setTotalRegistrosTiposAtendimentos(data.data.paging.total_records)
      })
      .catch((error) => {
        SweetAlert.error(error.response.data.message, 'Prontuario');
      })

  }

  const handlePageChange = (selected) => {
    listAllProntuarios(selected.selected + 1);
  };

  const handleFilter = () => {
    const filter = {
      [filterType]: filterValue
    }
    const filtered = listAllProntuarios(1, filter)

    setTableTiposAtendimentos(filtered);
  };

  function closeAll() {
    setFilterType(defaultFilter)
    setFilterValue('')
    setTableTiposAtendimentos(tiposAtendimentos)
    handleClose();
  }

  function handleCloseAndClean() {
    setFilterType(defaultFilter)
    setFilterValue('')
    setTableTiposAtendimentos(tiposAtendimentos)
    handleClose();
    setValues('prontuario', '');
    setValues('prontuario_id', null);
  }

  function enterClick(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      handleFilter();
    }
  }

  const handleClick = (categoryData) => {
    // setLoading(true);

    // TipoAtendimento.listPessoaById(categoryData.id)
    //   .then((dados) => {

    setValues('prontuario', categoryData.titulo);
    setValues('prontuario_id', categoryData.id);

    // setLoading(false);
    // })
    // .catch((error) => {
    //   SweetAlert.error(error.response.data.message, 'Tipo de atendimento');
    //   setLoading(false);
    // });
  };


  return (
    <Modal show={show} onHide={handleClose} centered size='xl' backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Pesquisa de prontuários</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex flex-column'>
          <Form.Group className=" d-flex gap-2 align-items-center">
            <Form.Label style={{ minWidth: 100 }}>Campo</Form.Label>
            <Form.Select
              size="sm"
              value={filterType}
              onChange={(e) => setFilterType(e.target.value)}
            >
              <option value='paciente.nome'>Paciente</option>
              <option value='codigo'>Código</option>
              <option value='data_cadastro'>Data</option>
              <option value='titulo'>Título</option>
              <option value='tecnico_responsavel.nome'>Doutor</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="mt-2 d-flex gap-2 align-items-center">
            <Form.Label style={{ minWidth: 100 }}>Pesquisar por</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
              placeholder="Filtrar"
              onKeyDown={(e) => enterClick(e)}
              autoFocus
            />
            <Button
              onClick={handleFilter}
              variant='outline-secondary d-flex align-items-center'>
              <IoSearch size={17} color='blue' />
            </Button>
          </Form.Group>

          <div className='mt-3' style={{ border: '1px solid black', height: 350, overflowY: 'scroll' }}>
            <Table striped bordered size='sm'>
              <thead>
                <tr>
                  <th style={{ whiteSpace: "nowrap", paddingLeft: 10, paddingRight: 10, textAlign: 'center' }}>Código</th>
                  <th style={{ whiteSpace: "nowrap", paddingLeft: 10, paddingRight: 10, textAlign: 'center' }}>Progresso</th>
                  <th style={{ whiteSpace: "nowrap", paddingLeft: 10, paddingRight: 10, textAlign: 'center' }}>Data</th>
                  <th style={{ whiteSpace: "nowrap", paddingLeft: 10, paddingRight: 10 }}>Título</th>
                  <th style={{ whiteSpace: "nowrap", paddingLeft: 10, paddingRight: 10 }}>Paciente</th>
                  <th style={{ whiteSpace: "nowrap", paddingLeft: 10, paddingRight: 10 }}>Doutor</th>
                </tr>
              </thead>
              <tbody>
                {tableTiposAtendimentos?.map(tipo => {
                  return (
                    <tr key={tipo.codigo}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        handleClick(tipo)
                        closeAll()
                      }}>
                      <td style={{ whiteSpace: "nowrap", overflowX: "auto", paddingLeft: 10, paddingRight: 10, textAlign: 'center' }}>{tipo.codigo}</td>
                      <td style={{ whiteSpace: "nowrap", overflowX: "auto", paddingLeft: 10, paddingRight: 10, textAlign: 'center' }}>{tipo.progresso}</td>
                      <td style={{ whiteSpace: "nowrap", overflowX: "auto", paddingLeft: 10, paddingRight: 10, textAlign: 'center' }}>{format(new Date(tipo.data_cadastro), 'dd/MM/yyyy')}</td>
                      <td style={{ whiteSpace: "nowrap", overflowX: "auto", paddingLeft: 10, paddingRight: 10 }}>{tipo.titulo}</td>
                      <td style={{ whiteSpace: "nowrap", overflowX: "auto", paddingLeft: 10, paddingRight: 10 }}>{tipo.paciente.nome}</td>
                      <td style={{ whiteSpace: "nowrap", overflowX: "auto", paddingLeft: 10, paddingRight: 10 }}>{tipo.tecnico_responsavel.nome}</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
          <p className='m-0 p-0' style={{ color: 'red', fontSize: 12 }}> * Somente registros ativos serão mostrados!</p>
        </div>

        <div className='mt-4 d-flex justify-content-between px-3 flex-wrap gap-4'>
          <span> Total: {totalRegistrosTiposAtendimentos}</span>
          <ReactPaginate
            pageCount={totalPagesTiposAtendimentos}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={handlePageChange}
            containerClassName={'pagination_container'}
            activeClassName={'active'}
            previousLabel={'<'}
            nextLabel={'>'}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-between'>
        <Button variant="outline-success" onClick={handleShowModalCadastroProntuario}>
          Cadastrar novo prontuário
        </Button>
        <div className='d-flex gap-2'>
          <Button variant="outline-danger" onClick={handleCloseAndClean}>
            Limpar
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </div>
        {/* <Button variant="primary" onClick={handleClose}>
          Save Changes
        </Button> */}
      </Modal.Footer>
    </Modal>
  )
}

export default ModalSearchProntuarios