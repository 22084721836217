import React, { useRef, useState, useEffect } from 'react';
import './style.css';
import { Button } from 'react-bootstrap';

function CameraPaciente() {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const fileInputRef = useRef(null);
  const [photo, setPhoto] = useState(null);
  const [isFrontCamera, setIsFrontCamera] = useState(false); // Estado para controlar se a câmera frontal está sendo usada
  const [mobile, setMobile] = useState(false); // Estado para controlar se a câmera frontal está sendo usada
  const [cameraOpen, setCameraOpen] = useState(true); // Estado para controlar se a câmera frontal está sendo usada

  const openDefaultCamera = async () => {

    if (cameraOpen === true) {
      return setCameraOpen(false)
    }

    const video = videoRef.current;

    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert('Seu navegador não suporta esse recurso');
      return;
    }

    // Verifica se estamos em um dispositivo móvel
    const isMobile = /Mobi/.test(navigator.userAgent);
    setMobile(isMobile);

    // Opções para solicitar a câmera
    const constraints = {
      video: isMobile ? { facingMode: { exact: isFrontCamera ? "user" : "environment" } } : true
    };

    setCameraOpen(true)

    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      video.srcObject = stream;
      video.play();
      setCameraOpen(true)
    } catch (error) {
      console.error('Erro ao acessar a câmera:', error);
    }
  };

  useEffect(() => {
    openDefaultCamera();
  }, []); // Executar apenas uma vez ao montar o componente

  const capturePhoto = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    // Define o tamanho do canvas para o mesmo tamanho do vídeo
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    // Desenha o frame atual do vídeo no canvas
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    // Converte o conteúdo do canvas para um formato de imagem
    const dataURL = canvas.toDataURL('image/jpeg');

    // Adiciona a imagem capturada ao array de fotos
    setPhoto(dataURL);
  };

  const toggleCamera = () => {
    setIsFrontCamera(!isFrontCamera); // Alterna entre câmera frontal e traseira
    // Fecha a câmera atual e abre novamente com a nova câmera selecionada
    const video = videoRef.current;
    if (video.srcObject) {
      video.srcObject.getTracks().forEach(track => track.stop());
    }
    openDefaultCamera();
  };

  const handleUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPhoto(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <div className='mb-3 d-flex gap-3 flex-column flex-md-row'>
        {cameraOpen === true ?
          <>
            <Button onClick={() => setCameraOpen(false)}>Fechar camera</Button>
            <Button onClick={() => { capturePhoto(); setCameraOpen(false) }}>Bater foto</Button>
          </>
          :
          <Button onClick={() => { setCameraOpen(true); openDefaultCamera() }}>Abrir camera</Button>}
        {photo === null ? '' :
          <Button onClick={() => { setPhoto(null) }}>Limpa foto</Button>
        }


        <Button onClick={() => { fileInputRef.current.click(); setCameraOpen(false) }}>Fazer upload</Button>
        <input type="file" accept=".jpg, .jpeg, .png, .gif" ref={fileInputRef} style={{ display: 'none' }} onChange={handleUpload} />
      </div>

      <div className='d-flex justify-content-center gap-4 flex-wrap'>
        {/* {cameraOpen === true ?
          <>
            <video id='myVideo' autoPlay playsInline ref={videoRef} className='webcam' onClick={capturePhoto} />
            <canvas ref={canvasRef} style={{ display: 'none' }} />
          </>
          : ''} */}

        <video id='myVideo' autoPlay playsInline ref={videoRef} className='webcam' style={{ display: `${cameraOpen === true ? 'block' : 'none'}` }} />
        <canvas ref={canvasRef} style={{ display: `${cameraOpen === true ? 'none' : 'hidden'}` }} />

        {photo === null ? '' :
          <div className='div-foto'>
            <p>Foto selecionada</p>
            <img src={photo} alt={`Foto`} className='foto' />
          </div>}


        {/* {mobile === true ? <div>
          <button onClick={toggleCamera}>Toggle Camera</button>
        </div> : ''} */}
        <div>
        </div>
      </div>
    </>
  );
}

export default CameraPaciente;
