import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import WebServices from '../../services/WebServices';
import { SweetAlert } from '../../components/sweetalert';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../context/auth';
import { LoadingSkeleton } from '../../components/LoadingSkeleton';
import HeaderLabel from '../../components/HeaderLabel';
import Pacientes from '../../services/Pacientes';
import { addHours, format } from 'date-fns';
import Loading from '../../routers/loading';
import { isValidCPF } from '../../utilities/isValidCpf';
import { formatCPF } from '../../utilities/formatCPF';
import { formatCEP } from '../../utilities/formatCEP';
import { formatTelefone } from '../../utilities/formatTelefone';
import { formatarTelefone } from '../../utilities/formatarTelefoneInput';
import ButtonSaveContent from '../../components/ButtonSaveContent';
import { validaInteiroELimitaCampo } from '../../utilities/validaInteiroELimitaCampo';
import CameraPaciente from '../cameraPaciente';

const CadastroPaciente = ({ origem, handleCloseModalCadastroPessoa, setValueModal, handleCloseCliente }) => {
  const { handleSubmit, register, getValues, setValue, reset, watch, setFocus } = useForm();
  const { sessionCurrent } = useAuth();
  const [loading, setLoading] = useState(false);

  const [photo, setPhoto] = useState(null);

  const params = useParams()
  const navigate = useNavigate()
  const semNumero = watch('semNumero');

  useEffect(() => {

    setFocus('nome')

    if (params.id && origem !== 'modal') {
      setLoading(true)
      Pacientes.listId(params.id)
        .then((data2) => {
          const data = JSON.parse(JSON.stringify(data2))

          if (data.data.data_nascimento !== null && data.data.data_nascimento !== '') {
            let dataNascimentoAjustada = addHours(data.data.data_nascimento, 3)
            let newData = format(dataNascimentoAjustada, 'yyyy-MM-dd');
            setValue('data_nascimento', newData);

          }

          let cpfCnpj = data?.data?.cpf

          if (cpfCnpj !== undefined && cpfCnpj !== undefined) {
            cpfCnpj = cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4').slice(0, 14);
          }


          setValue('nome', data.data.nome);
          setValue('cpf', cpfCnpj ?? '');
          setValue('rg', data.data?.rg);
          setValue('email', data.data.email);
          setValue('telefone1', formatarTelefone(data.data?.telefone));
          setValue('telefone2', formatarTelefone(data.data?.telefone2));
          setValue('sexo', data.data.sexo);
          setValue('profissao', data.data.profissao);
          setValue('estado_civil', data.data.estado_civil);
          setValue('cep', data.data.endereco_cep);
          setValue('logradouro', data.data.endereco_logradouro);
          setValue('complemento', data.data.endereco_complemento);
          setValue('numero', data.data.endereco_numero);
          setValue('bairro', data.data.endereco_bairro);
          setValue('cidade', data.data.endereco_cidade);
          setValue('estado', data.data.endereco_estado);
          setValue('ativo', data.data.ativo);
          setValue('conjuge_nome', data.data.conjuge_nome);

          if (data.data.foto !== '') {
            setPhoto(data.data.foto)
          }

        })
        .catch((error) => {
          console.log(error)
          SweetAlert.error(error?.response?.data?.message, 'Pessoa');
        })
        .finally(() => {
          setLoading(false)
        })

    }

  }, []);

  function onSubmit(data) {


    if (data.nome === '' || data.nome === undefined || data.nome === null) {
      return SweetAlert.error('Nome é um campo obrigatório', 'Pessoas');
    }

    let telefone1 = data.telefone1.replace(/\D/g, "")

    if (telefone1.length > 1 && telefone1.length < 10) {
      return SweetAlert.error('Informe um telefone válido', 'Pessoa')
    }

    let telefone2 = data.telefone2?.replace(/\D/g, "")

    if (telefone2?.length > 1 && telefone2?.length < 10) {
      return SweetAlert.error('Informe um telefone válido', 'Pessoa')
    }

    if (data.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
      return SweetAlert.error('Informe um email válido', 'Pessoa');
    }

    const cleanedCPF = data.cpf.replace(/\D/g, '')
    let validationFailed = false;

    if (cleanedCPF.length > 0) {
      if (cleanedCPF.length !== 11) {
        return SweetAlert.error('CPF inválido', 'CPF')
      }


      if (cleanedCPF.length === 11 && validationFailed === false) {
        const valid = isValidCPF(cleanedCPF)
        if (valid === false) {
          SweetAlert.error('CPF inválido', 'CPF')
          validationFailed = true;
        }
      }
    }

    if (validationFailed) {
      return;
    }

    setLoading(true)

    const dt = new Date(data.data_nascimento);

    let dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);

    const paciente = {
      "nome": data.nome,
      "cpf": data.cpf.replace(/[^\d]/g, ''),
      "rg": data.rg ?? '',
      "data_nascimento": dtDateOnly,
      "email": data.email ?? '',
      "telefone": telefone1 ?? '',
      "telefone2": telefone2 ?? '',
      "sexo": data.sexo ?? '',
      "profissao": data.profissao ?? '',
      "estado_civil": data.estado_civil ?? '',
      "conjuge_nome": data.conjuge_nome ?? '',
      "endereco_cep": data.cep.replace("-", "") ?? '',
      "endereco_logradouro": data.logradouro ?? '',
      "endereco_complemento": data.complemento ?? '',
      "endereco_numero": data.numero ?? '',
      "endereco_bairro": data.bairro ?? '',
      "endereco_cidade": data.cidade ?? '',
      "endereco_estado": data.estado ?? '',
      "ativo": data.ativo ?? ''
    }

    if (params.id && origem !== 'modal') {
      Pacientes.update(params.id, paciente)
        .then((data) => {

          if (photo) {

            if (typeof photo === 'string') {
              SweetAlert.success('Registro atualizado com sucesso', 'Paciente')
              navigate(-1)
              return
            }

            let formData = new FormData();
            formData.append('photo', photo); // Use uma chave descritiva para a imagem

            Pacientes.createPhoto(data.data.id, formData)
              .then(() => {
                SweetAlert.success('Registro atualizado com sucesso', 'Paciente')
                navigate(-1)
              })
              .catch((error) => {
                SweetAlert.error(error?.response?.data?.message, 'Paciente');
              });
          } else {
            Pacientes.deletePhoto(data.data.id)
              .then(() => {

                SweetAlert.success('Registro atualizado com sucesso', 'Paciente')
                navigate(-1)
              })
              .catch((error) => {
                SweetAlert.error(error?.response?.data?.message, 'Paciente');
              });
          }

        })
        .catch((error) => {
          SweetAlert.error(error?.response?.data?.message, 'Pessoa');
        })
        .finally(() => { setLoading(false) })
    } else {
      Pacientes.create(paciente)
        .then((data) => {

          if (photo) {
            let formData = new FormData();
            formData.append('photo', photo); // Use uma chave descritiva para a imagem

            Pacientes.createPhoto(data.data.id, formData)
              .then(() => {
                SweetAlert.success('Paciente cadastrado com sucesso', 'Paciente');
                reset();
                setPhoto(null)
                setTimeout(() => {
                  setFocus('cpf');
                }, 1);
              })
              .catch((error) => {
                SweetAlert.error(error?.response?.data?.message, 'Paciente');
              });
          } else {
            SweetAlert.success('Paciente cadastrado com sucesso', 'Paciente');
            reset();
            setTimeout(() => {
              setFocus('cpf');
            }, 1);
          }

          if (origem === 'modal') {
            setValueModal('paciente', data.data.nome)
            setValueModal('paciente_id', data.data.id)
            handleCloseModalCadastroPessoa()
            handleCloseCliente()
          }

          window.scrollTo(0, 0);
        })
        .catch((error) => {
          console.log(error);
          SweetAlert.error(error?.response?.data?.message, 'Paciente');
        })
        .finally(() => {
          setLoading(false);
        });

    }

  }

  function getCEP() {

    let cep = getValues('cep');
    cep = cep.replace('-', '');

    if (cep.length !== 8) {
      return SweetAlert.error('CEP inválido', 'CEP')
    }
    setLoading(true);

    WebServices.CEP(cep)
      .then((data) => {
        setValue('logradouro', data.data.logradouro);
        setValue('bairro', data.data.bairro);
        setValue('estado', data.data.estado.nome);
        setValue('cidade', data.data.cidade.nome);
      })
      .catch((error) => {
        SweetAlert.error(error.response.data.message, 'CEP');
      })
      .finally(() => setLoading(false));
  }

  return (
    <>
      {loading === true ? <Loading /> : ''}

      <Container>
        <Form onSubmit={handleSubmit(onSubmit)} className='pt-3'>

          <HeaderLabel
            label='Paciente'
            button={origem !== 'modal'}
          />

          <Card className='cardShadow'>
            <Card.Body className='p-3'>

              <Row className='d-flex align-items-end'>
                <Form.Group className="col-12 col-lg-auto mb-2">
                  <h4 className='textPrimary'>Geral</h4>
                </Form.Group>
              </Row>

              <Row className='pb-2'>
                <Form.Group className="col-12">
                  <Form.Label>Nome <span style={{ color: 'red' }}>*</span></Form.Label>
                  {loading ? <LoadingSkeleton /> : <Form.Control size='sm' type="text" {...register('nome')} />}
                </Form.Group>

                {params.id ? <Form.Group className="col-12 col-lg-3 mt-2">
                  <Form.Label>Situação</Form.Label>
                  {loading ? <LoadingSkeleton /> : <Form.Select {...register('ativo')} size='sm'>
                    <option value={true}>ATIVO</option>
                    <option value={false}>INATIVO</option>
                  </Form.Select>}
                </Form.Group> : ''}
              </Row>

              <Row className='d-flex align-items-end'>
                <Form.Group className="col-12 col-lg-3">
                  <Form.Label>CPF</Form.Label>
                  {loading ? <LoadingSkeleton /> :
                    <Form.Control
                      size='sm'
                      type="text"
                      {...register('cpf')}
                      onChange={(e) => formatCPF(e, setValue, 'cpf')}
                    />
                  }
                </Form.Group>

                <Form.Group className="col-12 col-lg-3">
                  <Form.Label>RG</Form.Label>
                  {loading ? <LoadingSkeleton /> :
                    <Form.Control
                      size='sm'
                      type="text"
                      {...register('rg')}
                    />
                  }
                </Form.Group>

                <Form.Group className="col-12 col-lg-3">
                  <Form.Label>Data de nascimento </Form.Label>
                  {loading ? <LoadingSkeleton /> : <Form.Control size='sm' type="date" {...register('data_nascimento')} />}
                </Form.Group>

              </Row>

              <Row className='pt-2'>
                <Form.Group className="col-12 col-lg-4">
                  <Form.Label>Email</Form.Label>
                  {loading ? <LoadingSkeleton /> : <Form.Control size='sm' type="text" {...register('email')} />}
                </Form.Group>

                <Form.Group className="col-12 col-lg-3">
                  <Form.Label>Telefone Fixo</Form.Label>
                  {loading ? <LoadingSkeleton /> :
                    <Form.Control
                      size='sm'
                      type="text"
                      {...register('telefone1')}
                      onChange={(e) => formatTelefone(e, setValue, 'telefone1')}
                    />}
                </Form.Group>
                <Form.Group className="col-12 col-lg-3">
                  <Form.Label>Celular</Form.Label>
                  {loading ? <LoadingSkeleton /> :
                    <Form.Control
                      size='sm'
                      type="text"
                      {...register('telefone2')}
                      onChange={(e) => formatTelefone(e, setValue, 'telefone2')}
                    />}
                </Form.Group>

              </Row>

              <Row className='pt-2'>
                <Form.Group className="col-12 col-lg-4">
                  <Form.Label>Profissão</Form.Label>
                  {loading ? <LoadingSkeleton /> : <Form.Control size='sm' type="text" {...register('profissao')} />}
                </Form.Group>

                <Form.Group className="col-12 col-lg-2">
                  <Form.Label>Sexo</Form.Label>
                  {loading ? <LoadingSkeleton /> : <Form.Select size='sm' {...register('sexo')}>
                    <option value='MASCULINO'>MASCULINO</option>
                    <option value='FEMININO'>FEMININO</option>
                    <option value='INDEFINIDO'>INDEFINIDO</option>
                    <option value='NAO_INFORMADO'>NÃO INFORMADO</option>
                  </Form.Select>}
                </Form.Group>

              </Row>

              <Row className='pt-2'>
                <Form.Group className="col-12 col-lg-4">
                  <Form.Label>Estado Civil</Form.Label>
                  {loading ? <LoadingSkeleton /> :
                    <Form.Select size='sm' {...register('estado_civil')} >
                      <option value='SOLTEIRO'>SOLTEIRO</option>
                      <option value='CASADO'>CASADO</option>
                      <option value='SEPARADO'>SEPARADO</option>
                      <option value='DIVORCIADO'>DIVORCIADO</option>
                      <option value='VIUVO'>VIÚVO</option>
                    </Form.Select>}
                </Form.Group>

                <Form.Group className="col-12 col-lg-4">
                  <Form.Label>Nome do cônjuge</Form.Label>
                  {loading ? <LoadingSkeleton /> : <Form.Control size='sm' type="text" {...register('conjuge_nome')} />}
                </Form.Group>
              </Row>

              {/* <Row className='pt-2'>
                <Form.Group className="col-12 col-lg-2">
                  <Form.Label>Filhos</Form.Label>
                  {loading ? <LoadingSkeleton /> : <Form.Select size='sm' {...register('filhos')}>
                    <option value='sim'>SIM</option>
                    <option value='nao'>NÃO</option>
                  </Form.Select>}
                </Form.Group>

                <Form.Group className="col-12 col-lg-4">
                  <Form.Label>Quantidade de filhos</Form.Label>
                  {loading ? <LoadingSkeleton /> : <Form.Control size='sm' type="text" {...register('quantidade_filhos')} />}
                </Form.Group>
              </Row> */}

              {/* <Row className='pt-2'>
                <Form.Group className="col-12 col-lg-2">
                  <Form.Label>Possibilidade de gravidez</Form.Label>
                  {loading ? <LoadingSkeleton /> : <Form.Select size='sm' {...register('gravidez')}>
                    <option value='sim'>SIM</option>
                    <option value='nao'>NÃO</option>
                  </Form.Select>}
                </Form.Group>

                <Form.Group className="col-12 col-lg-2">
                  <Form.Label>Período de amamentação</Form.Label>
                  {loading ? <LoadingSkeleton /> : <Form.Select size='sm' {...register('amamentacao')}>
                    <option value='sim'>SIM</option>
                    <option value='nao'>NÃO</option>
                  </Form.Select>}
                </Form.Group>

                <Form.Group className="col-12 col-lg-2">
                  <Form.Label>Uso de prótese</Form.Label>
                  {loading ? <LoadingSkeleton /> : <Form.Select size='sm' {...register('usa_protese')}>
                    <option value='sim'>SIM</option>
                    <option value='nao'>NÃO</option>
                  </Form.Select>}
                </Form.Group>

                <Form.Group className="col-12 col-lg-4">
                  <Form.Label>Qual?</Form.Label>
                  {loading ? <LoadingSkeleton /> : <Form.Control size='sm' type="text" {...register('nome_protese')} />}
                </Form.Group>

              </Row> */}

            </Card.Body>
          </Card>

          <Card className='cardShadow mt-3'>
            <Card.Body className='p-3'>

              <Row className='d-flex align-items-end'>
                <Form.Group className="col-12 col-lg-auto mb-2">
                  <h4 className='textPrimary'>Endereço</h4>
                </Form.Group>
              </Row>

              <Row className='d-flex align-items-end'>

                <Form.Group className="col-12 col-lg-3">
                  <Form.Label>CEP </Form.Label>
                  {loading ? <LoadingSkeleton /> :
                    <Form.Control
                      size='sm'
                      type="text"
                      {...register('cep')}
                      onChange={(e) => formatCEP(e, setValue, 'cep')}
                    />}
                </Form.Group>
                <div className='col-12 col-lg-auto mt-2 mt-lg-0'>
                  <Button
                    onClick={getCEP}
                    variant='outline-success'
                    disabled={loading}
                    size='sm'
                  >Pesquisar</Button>
                </div>

              </Row>

              <Row className='pt-2'>
                <Form.Group className="col-12 col-lg-8">
                  <Form.Label>Logradouro </Form.Label>
                  {loading ? <LoadingSkeleton /> : <Form.Control size='sm' type="text" {...register('logradouro')} />}
                </Form.Group>

                <Form.Group className="col-12 col-lg-3">
                  <Form.Label>Número </Form.Label>
                  {loading ? <LoadingSkeleton /> :
                    <Form.Control
                      size='sm'
                      type="text"
                      {...register('numero')}
                      onChange={(e) => validaInteiroELimitaCampo(e.target.value, 4, 'numero', setValue)}
                    />
                  }
                </Form.Group>

              </Row>

              <Row className='pt-2'>
                <Form.Group className="col-12 col-lg-8">
                  <Form.Label>Complemento</Form.Label>
                  {loading ? <LoadingSkeleton /> : <Form.Control size='sm' type="text" {...register('complemento')} />}
                </Form.Group>

                <Form.Group className="col-12 col-lg-4">
                  <Form.Label>Bairro </Form.Label>
                  {loading ? <LoadingSkeleton /> : <Form.Control size='sm' type="text" {...register('bairro')} />}
                </Form.Group>
              </Row>

              <Row className='pt-2'>
                <Form.Group className="col-12 col-lg-8">
                  <Form.Label>Cidade </Form.Label>
                  {loading ? <LoadingSkeleton /> : <Form.Control size='sm' type="text" {...register('cidade')} />}
                </Form.Group>

                <Form.Group className="col-12 col-lg-4">
                  <Form.Label>Estado </Form.Label>
                  {loading ? <LoadingSkeleton /> : <Form.Control size='sm' type="text" {...register('estado')} />}
                </Form.Group>
              </Row>

            </Card.Body>
          </Card>

          <Card className='cardShadow mt-3'>
            <Card.Body className='p-3'>

              <Row className='d-flex align-items-end'>
                <Form.Group className="col-12 col-lg-auto mb-2">
                  <h4 className='textPrimary'>Foto do paciente</h4>
                </Form.Group>
              </Row>

              <CameraPaciente
                photo={photo}
                setPhoto={setPhoto}
              />

            </Card.Body>
          </Card>

          <div className='d-flex justify-content-end mt-3 mb-3'>
            <Button variant="success" type="submit" className="col-12 col-lg-auto d-flex align-items-center gap-1" disabled={loading}>
              <ButtonSaveContent />
            </Button>
          </div>

        </Form>
      </Container>
    </>
  );
}

export default CadastroPaciente;
