import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import WebServices from '../../services/WebServices';
import { SweetAlert } from '../../components/sweetalert';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../context/auth';
import { URL } from '../../config/url';
import Loading from '../../components/Loading';
import { LoadingSkeleton } from '../../components/LoadingSkeleton';
import HeaderLabel from '../../components/HeaderLabel';
import TipoPagamento from '../../services/Tipo_pagamento';
import ButtonSaveContent from '../../components/ButtonSaveContent';

const CadastroTipoPagamento = () => {
  const { handleSubmit, register, setFocus, setValue, reset } = useForm();
  const { user, sessionCurrent } = useAuth();
  const [loading, setLoading] = useState(false);

  const params = useParams()
  const navigate = useNavigate()

  useEffect(() => {

    setFocus('descricao')

    if (params.id) {
      setLoading(true)
      TipoPagamento.listId(params.id)
        .then((data) => {
          setValue('descricao', data.data.descricao);
          setValue('ativo', data.data.ativo);
        })
        .catch((error) => {
          SweetAlert.error(error.response.data.message, 'Tipo de pagamento');
        })
        .finally(() => { setLoading(false) })

    }
  }, []);


  function onSubmit(data) {

    if (data.descricao === '' || data.descricao === undefined || data.descricao === null) {
      return SweetAlert.error('Descrição é um campo obrigatório', 'Tipo de pagamento');
    }

    setLoading(true)

    const tipo_pagamento = {
      "descricao": data.descricao.toUpperCase(),
      "ativo": data.ativo,
    }

    if (params.id) {
      TipoPagamento.update(params.id, tipo_pagamento)
        .then((data) => {
          // if (sessionCurrent.features.includes("read:session")) {
          //   return navigate(URL.tipo_pagamento_lista)
          // }
          SweetAlert.success('Registro atualizado com sucesso', 'Tipo de pagamento')
          navigate(URL.tipo_pagamento_lista)
          // reset()
          // setTimeout(() => {
          //   setFocus('nome')
          // }, 10);
        })
        .catch((error) => {
          SweetAlert.error(error.response.data.message, 'Tipo de pagamento');
        })
        .finally(() => { setLoading(false) })
    } else {
      TipoPagamento.create(tipo_pagamento)
        .then((data) => {

          // if (!sessionCurrent.features.includes("read:session")) {
          //   return navigate('/')
          // }

          reset()
          SweetAlert.success('Tipo de pagamento cadastrada com sucesso', 'Tipo de pagamento')

          // navigate(URL.tipo_pagamento_lista)
        })
        .catch((error) => {
          SweetAlert.error(error.response.data.message, 'Tipo de pagamento');
        })
        .finally(() => {
          setLoading(false)
          setTimeout(() => {
            setFocus('descricao')
          }, 10);
        })
    }

  }


  return (
    <>
      {/* {loading === true ? <Loading /> : ''} */}
      <Container>
        <Form onSubmit={handleSubmit(onSubmit)} className='pt-3'>
          <HeaderLabel
            label='Tipo de pagamento'
          />

          <Card className='cardShadow'>
            <Card.Body className='p-3'>
              <Row className='d-flex align-items-end'>
                <Form.Group className="col-12 col-lg-3">
                  <Form.Label>Descrição <span style={{ color: 'red' }}>*</span></Form.Label>
                  {loading ? <LoadingSkeleton /> : <Form.Control size='sm' type="text" {...register('descricao')} />}
                </Form.Group>
                {params.id ? <Form.Group className="col-12 col-lg-3">
                  <Form.Label>Situação</Form.Label>
                  {loading ? <LoadingSkeleton /> : <Form.Select {...register('ativo')} size='sm'>
                    <option value={true}>ATIVO</option>
                    <option value={false}>INATIVO</option>
                  </Form.Select>}
                </Form.Group> : ''}
              </Row>
            </Card.Body>
          </Card>

          <div className='d-flex justify-content-end mt-3 mb-3'>
            <Button variant="success" type="submit" className="col-12 col-lg-auto d-flex align-items-center gap-1" disabled={loading}>
              <ButtonSaveContent />
            </Button>
          </div>

        </Form>
      </Container>
    </>
  );
}

export default CadastroTipoPagamento;
