import { axios } from '../config/axios';
import security from './security'

const Pacientes = {};

Pacientes.create = (body) => {
  return security.validation(axios.post('/pacientes', body));
};

Pacientes.listAllClientes = (page = '', filtros = '') => {

  let filtro = ''

  if (filtros !== '') {
    filtro = '&nome=' + (filtros.nome === undefined ? '' : filtros.nome?.toUpperCase())
    filtro = filtro + '&cpf=' + (filtros.cpf === undefined ? '' : filtros.cpf.replace(/[^\d]/g, ''));
    filtro = filtro + '&ativo=' + (filtros.ativo === undefined ? '' : filtros.ativo)
    filtro = filtro + '&email=' + (filtros.email === undefined ? '' : filtros.email)
  }

  return security.validation(axios.get('/pacientes?orderby=nome&page_size=100&page=' + page + filtro));
};

Pacientes.listAllClientesAtivos = (page = '', filtros = '') => {

  let filtro = ''

  if (filtros !== '') {
    filtro = '&nome=' + (filtros.nome === undefined ? '' : filtros.nome?.toUpperCase())
    filtro = filtro + '&email=' + (filtros.email === undefined ? '' : filtros.email.toUpperCase())
    filtro = filtro + '&cpf=' + (filtros.cpf === undefined ? '' : filtros.cpf)
    filtro = filtro + '&ativo=' + (filtros.ativo === undefined ? '' : filtros.ativo)
  }

  return security.validation(axios.get('/pacientes?&orderby=codigo&page_size=10&page=' + page + filtro));
};

Pacientes.listPessoaById = (id) => {
  return security.validation(axios.get('/pacientes/' + id));
};

Pacientes.createPhoto = (id, newPhoto) => {
  return security.validation(axios.patch('/pacientes/' + id + '/foto', newPhoto,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }));
};

Pacientes.deletePhoto = (id) => {
  return security.validation(axios.delete('/pacientes/' + id + '/foto'));
};

Pacientes.listId = (id) => {
  return security.validation(axios.get('/pacientes/' + id));
};

Pacientes.update = (id, body) => {
  return security.validation(axios.patch('/pacientes/' + id, body));
};

Pacientes.deleteCliente = (id) => {
  return security.validation(axios.delete('/pacientes/' + id));
};
export default Pacientes;
