import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { events } from "./events";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Button, Modal, Form, Row, Container, Offcanvas, Card } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../context/auth';
import moment from 'moment';
import 'moment/locale/pt-br';
import ModalNovoAgendamento from '../../components/Modals/ModalNovoAgendamento';
import TopTitlePage from '../../components/TopTitlePage';
import ButtonLimparAndFiltrar from '../../components/ButtonLimparAndFiltrar';
import ButtonFilterAndSelect from '../../components/ButtonFilterAndSelect';
import ModalSearchTecnicos from '../../components/ModalSearch/ModalSearchTecnicos';
import Agendamentos from '../../services/Agendamentos';
import { IoSearch, IoRemoveCircleOutline } from 'react-icons/io5'
import Loading from '../../components/Loading'
import formats from '../../utilities/formats';
import { startOfMonth, endOfMonth, format } from 'date-fns';
import InputPaciente from '../../components/Inputs/InputPaciente'
import InputTipoAtendimento from '../../components/Inputs/InputTipoAtendimento'
import InputTecnico from '../../components/Inputs/InputTecnico'
import { calcIdade } from '../../utilities/calcIdade'
import { formatMoney } from '../../utilities/formatMoneyValue'
import { MdAttachMoney, MdOutlinePending } from "react-icons/md";
import { FaX, FaCheck } from "react-icons/fa6";
import { IoMdTime } from "react-icons/io";
import './style.css'
import Status from '../../services/Status';
import { SweetAlert } from '../../components/sweetalert';

const localizer = momentLocalizer(moment);

export default function Agenda() {
  const messages = {
    allDay: 'Dia Inteiro',
    previous: '<',
    next: '>',
    today: 'Hoje',
    month: 'Mês',
    week: 'Semana',
    day: 'Dia',
    agenda: 'Agenda',
    date: 'Data',
    time: 'Hora',
    event: 'Agendamento',
    work_week: 'Semana',
    noEventsInRange: 'Não há eventos neste intervalo',
    showMore: (total) => `+ (${total})`,
  }

  const { register, getValues, setValue, handleSubmit, reset, watch } = useForm();
  const { user } = useAuth();


  const [type, setType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [filter, setFilter] = useState(false);
  const [agendamentos, setAgendamentos] = useState([]);
  const [showModalAgendamento, setShowModalAgendamento] = useState(false);
  const [showModalTecnico, setShowModalTecnico] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentView, setCurrentView] = useState('month');
  const [resources, setResources] = useState([]);

  const [status, setStatus] = useState([]);

  const [pendente, setPendente] = useState();
  const [aguardando, setAguardando] = useState();
  const [realizado, setRealizado] = useState();
  const [cancelado, setCancelado] = useState();
  const [cards, setCards] = useState([]);

  useEffect(() => {
    setValue('date', new Date())
    const interval = setInterval(() => {
      listAll(true, getValues('date'));
    }, 10000);

    if (user.tecnico?.id) {
      // setValue('tecnico_id', user.tecnico.id)
      setValue('tecnico_id_filtro', user.tecnico?.id)
      setValue('tecnico_filtro', user.tecnico?.nome)
    }

    listAll();

    return () => clearInterval(interval);
  }, []);

  const handleShow = () => { setType('filtro'); setShow(true); }
  const handleClose = () => { setType(null); setShow(false); }
  const handleShowModalAgendamento = (newDate = true) => {
    setShowModalAgendamento(true);

    let localDateTime

    if (newDate === false) {

      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');

      localDateTime = `${year}-${month}-${day}`;

    } else {

      const now = new Date(getValues('date'));
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');

      localDateTime = `${year}-${month}-${day}`;
      setValue('status', status[0].id);
    }


    setValue('data_inicial', localDateTime);
    setValue('hora_inicial', '00:00');
    setValue('hora_final', '00:00');

    if (user.tecnico?.id) {
      setValue('tecnico_id', user.tecnico?.id)
      setValue('tecnico', user.tecnico?.nome)
    }
  };

  const handleCloseModalAgendamento = () => setShowModalAgendamento(false);
  const handleShowModalTecnico = () => setShowModalTecnico(true);
  const handleCloseModalTecnico = () => setShowModalTecnico(false);

  function listAll(timer = false, date = new Date()) {
    if (timer === false) {
      setLoading(true);

      Status.listAll()
        .then((data) => {
          setStatus(data.data.data)
        })
        .catch((error) => {
          SweetAlert.error(error.response.data.message, 'Status');
        })

    }

    const values = getValues()

    let newFiltro = {
      tecnico_id: values.tecnico_id_filtro ?? '',
      paciente_id: values.paciente_id_filtro ?? '',
      tipo_atendimento_id: values.tipo_atendimento_id_filtro ?? '',
      status_agendamento: values.status_filtro ?? '',
      data_agendamento_inicial: format(startOfMonth(date), 'yyyy-MM-dd'),
      data_agendamento_final: format(endOfMonth(date), 'yyyy-MM-dd')
    }

    Agendamentos.listAll(newFiltro)
      .then((data) => {
        const events = data.data.data
        const newEvents = []
        const newResources = [];

        const newStatus = Object.entries(data.data.analytics).map(([key, value]) => {
          return {
            status: key,
            ...value
          };
        });

        newStatus.sort((a, b) => a.sequencia - b.sequencia);

        setCards(newStatus)
        // setPendente(data.data.analytics.total_pendente)
        // setAguardando(data.data.analytics.total_aguardando)
        // setRealizado(data.data.analytics.total_realizado)
        // setCancelado(data.data.analytics.total_cancelado)

        // events.forEach((event) => {
        //   newEvents.push({
        //     id: event.id,
        //     title: event.paciente.nome + ' - ' + event.tipo_atendimento.descricao + ' - ' + event.tecnico.nome,
        //     start: new Date(Date.parse(event.data_agendamento_inicial)),
        //     end: new Date(Date.parse(event.data_agendamento_final)),
        //     status: event.status_agendamento,
        //     paciente: event.paciente,
        //     tecnico: event.tecnico,
        //     tipo_atendimento: event.tipo_atendimento,
        //     prontuario: event.prontuario,
        //     resourceId: event.tecnico.id,
        //   })

        // })

        events.forEach((event) => {
          newEvents.push({
            id: event.id,
            title: `${event.paciente.nome} - ${event.tipo_atendimento.descricao} - ${event.tecnico.nome}`,
            start: new Date(Date.parse(event.data_agendamento_inicial)),
            end: new Date(Date.parse(event.data_agendamento_final)),
            hora_inicial: format(new Date(event.data_agendamento_inicial), 'HH:mm'),
            hora_final: format(new Date(event.data_agendamento_final), 'HH:mm'),
            status: event.status,
            paciente: event.paciente,
            tecnico: event.tecnico,
            tipo_atendimento: event.tipo_atendimento,
            prontuario: event.prontuario,
            resourceId: event.tecnico.id,
          });

          if (!newResources.find(r => r.id === event.tecnico.id)) {
            newResources.push({
              id: event.tecnico.id,
              title: event.tecnico.nome,
            });
          }
        });

        const orderMap = {
          "CLAUDIA ROSADO DE ARAUJO": 0,
          "MIGUEL ROSADO DE ARAUJO": 1,
          "GABRIEL DE CARVALHO FARIAS": 2
        };

        newResources.sort((a, b) => {
          return orderMap[a.title] - orderMap[b.title];
        });


        setResources(newResources);
        setAgendamentos(newEvents)
        setLoading(false);
      })
  }

  function getBrightness(hex) {
    // Remove the hash at the start if it's there
    hex = hex.replace(/^#/, '');

    // Parse the r, g, b values
    let r = parseInt(hex.substr(0, 2), 16);
    let g = parseInt(hex.substr(2, 2), 16);
    let b = parseInt(hex.substr(4, 2), 16);

    // Calculate the brightness
    return ((r * 299) + (g * 587) + (b * 114)) / 1000;
  }

  function isColorDark(hex) {
    const brightness = getBrightness(hex);
    return brightness < 128; // You can adjust this threshold as needed
  }

  const eventStyleGetter = (event) => {
    const backgroundColor = event.status.cor;
    const textColor = isColorDark(backgroundColor) ? 'white' : 'black';

    return {
      style: {
        backgroundColor: backgroundColor,
        color: textColor,
      },
    };
  };

  function showInfoModal(event) {
    setValue('id', event.id);
    setValue('paciente', event.paciente.nome + calcIdade(event.paciente.data_nascimento));
    setValue('paciente_id', event.paciente.id);
    setValue('tecnico', event.tecnico.nome);
    setValue('tecnico_id', event.tecnico.id);
    setValue('tipo_atendimento', event.tipo_atendimento.descricao);
    setValue('tipo_atendimento_id', event.tipo_atendimento.id);
    setValue('prontuario', event.prontuario.titulo);
    setValue('prontuario_id', event.prontuario.id);
    setValue('status', event.status.id);
    setValue('telefone1', event.paciente.telefone);
    setValue('telefone2', event.paciente.telefone2);

    // const start = new Date(event.start);
    // const startTime = `${start.getFullYear()}-${(start.getMonth() + 1).toString().padStart(2, '0')}-${start.getDate().toString().padStart(2, '0')}`;
    setTimeout(() => {
      setValue('data_inicial', format(new Date(event.start), 'yyyy-MM-dd'));
      setValue('hora_inicial', event.hora_inicial);
      setValue('hora_final', event.hora_final);
    }, 100);

    handleShowModalAgendamento(false);
  }

  function onFilter() {
    setFilter(true)
    setShow(false)
    listAll(1)
  }

  const handleNavigate = (newDate) => {
    setCurrentDate(newDate);
    setValue('date', newDate)
    listAll(false, newDate)
  };

  const handleViewChange = (newView) => {
    setCurrentView(newView);
  };

  return (
    <>
      {loading === true ? <Loading /> : ''}
      <div className='mb-3' style={{ marginLeft: 20, marginRight: 20 }}>

        <Offcanvas show={show} onHide={handleClose} style={{ height: '100vh', backgroundColor: '#f1f1f1' }} placement='bottom'>
          <Container>
            <Form onSubmit={handleSubmit(onFilter)}>
              <Offcanvas.Header closeButton>
                <TopTitlePage title='Filtro' />
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Card className='cardShadow'>
                  <Card.Body className='p-3'>

                    <Row className='d-flex align-items-end'>
                      <InputPaciente
                        setValue={setValue}
                        loading={loading}
                        setLoading={setLoading}
                        getValues={getValues}
                        register={register}
                        registerNameId='paciente_id_filtro'
                        registerName='paciente_filtro'
                      />
                    </Row>

                    <Row className='d-flex align-items-end mt-2'>
                      <InputTecnico
                        setValue={setValue}
                        loading={loading}
                        setLoading={setLoading}
                        getValues={getValues}
                        register={register}
                        registerNameId='tecnico_id_filtro'
                        registerName='tecnico_filtro'
                      />

                    </Row>

                    <Row className='d-flex align-items-end'>
                      <InputTipoAtendimento
                        setValue={setValue}
                        loading={loading}
                        setLoading={setLoading}
                        getValues={getValues}
                        register={register}
                        registerNameId='tipo_atendimento_id_filtro'
                        registerName='tipo_atendimento_filtro'
                      />
                    </Row>

                    <Row className='d-flex align-items-end'>
                      <Form.Group className="col-12 col-lg-2">
                        <Form.Label>Status</Form.Label>
                        <Form.Select {...register('status_filtro')} size='sm'>
                          <option value="">TODOS</option>
                          {status?.map(item => {
                            return (
                              <option value={item.id}>{item.nome}</option>

                            )
                          })}
                        </Form.Select>
                      </Form.Group>
                    </Row>

                  </Card.Body>
                </Card>
                <div className='d-flex justify-content-end mt-3 gap-3'>
                  <ButtonLimparAndFiltrar
                    handleClose={handleClose}
                    reset={reset}
                    listAll={listAll}
                    setFilter={setFilter}
                  />
                </div>
              </Offcanvas.Body>
            </Form>
          </Container>
        </Offcanvas>

        <Form>

          <ModalNovoAgendamento
            show={showModalAgendamento}
            handleClose={handleCloseModalAgendamento}
            setAgendamentos={setAgendamentos}
            register={register}
            setValue={setValue}
            getValues={getValues}
            reset={reset}
            listAll={listAll}
            watch={watch}
            status={status}
          />

          <ModalSearchTecnicos
            show={showModalTecnico}
            handleClose={handleCloseModalTecnico}
            setValues={setValue}
            loading={loading}
            setLoading={setLoading}
            type={type}
          />

          <div>

            <div className='cardContainerHome'>

              {cards?.map(item => {
                return (
                  <div className="p-2 cardHome">
                    <div className="d-flex align-items-center gap-3 px-3">
                      <div
                        style={{
                          backgroundColor: `${item.cor}`,
                          height: 40,
                          width: 40,
                          borderRadius: 10,
                        }}
                        className="d-flex align-items-center justify-content-center"
                      >
                        {item.icon === null ? '' : <img
                          src={item.icon}
                          alt="icon"
                          style={{
                            maxHeight: "70%",
                            maxWidth: "70%",
                            objectFit: "contain",
                          }}
                        />}
                      </div>

                      <div className="d-flex flex-column">
                        <span className="caption">{item.status}</span>
                        <h3 className="cardText">{item.quantidade}</h3>
                      </div>
                    </div>
                  </div>
                )
              })}

            </div>

            <div className='d-flex gap-2 mb-3 flex-column flex-md-row'>
              <ButtonFilterAndSelect
                handleShow={handleShow}
                listAll={listAll}
                filter={filter}
              />
              <Button onClick={handleShowModalAgendamento}>Novo agendamento</Button>
            </div>
            {/* 
            <h2>Data Atual: {moment(currentDate).format('DD/MM/YYYY')}</h2>
            <h2>Visão Atual: {currentView}</h2> */}

            <div className="container-legenda">
              {status.map(item => {
                const textColor = isColorDark(item.cor) ? 'white' : 'black';
                return (
                  <div key={item.nome} className="item-legenda" style={{ backgroundColor: item.cor, color: textColor }}>
                    <p className="text-legenda">{item.nome}</p>
                  </div>
                );
              })}
            </div>

            <Card className='cardShadow mt-2'>


              <Card.Body className='p-3'>

                <Calendar
                  className="custom-calendar"
                  views={["day", "agenda", "work_week", "month"]}
                  selectable
                  localizer={localizer}
                  defaultDate={new Date()}
                  defaultView="month"
                  events={agendamentos}
                  style={{ height: "70vh" }}
                  onSelectEvent={(event) => showInfoModal(event)}
                  culture={"pt-BR"}
                  messages={messages}
                  eventPropGetter={eventStyleGetter}
                  step={7.5}
                  onNavigate={handleNavigate}
                  onView={handleViewChange}
                  min={new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 7)}
                  max={new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 20)}
                  resources={resources}
                  resourceIdAccessor="id"
                  resourceTitleAccessor="title"

                />

              </Card.Body>
            </Card>

          </div>

        </Form >
      </div >
    </>
  );
}
