import React, { useState, useEffect, createContext, useContext } from 'react';
import metadata from '../../package.json';
import Version from '../services/Version'

const VersionContext = createContext();

export const VersionProvider = ({ children }) => {
  const [version, setVersion] = useState(metadata.version);
  const [attVersion, setAttVersion] = useState(false);


  useEffect(() => {
    const checkVersion = async () => {
      try {
        let newVersion = await Version.getVersion();
        const savedVersion = version;
        newVersion = newVersion.data.version

        // Se não houver nada salvo no localStorage, apenas salva a versão atual
        if (!savedVersion) {
          localStorage.setItem('version', newVersion);
          return;
        }

        if (savedVersion !== newVersion) {
          localStorage.setItem('version', newVersion);
          setAttVersion(true);
        } else {
          setAttVersion(false);
        }

      } catch (error) {
        console.error('Failed to fetch version:', error);
      }
    };

    // Execute checkVersion immediately on mount
    checkVersion();

    // Set an interval to execute checkVersion every 1 minute
    const intervalId = setInterval(checkVersion, 60000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);

  }, []);



  return (
    <VersionContext.Provider
      value={{
        version,
        setVersion,
        attVersion,
        setAttVersion
      }}>
      {children}
    </VersionContext.Provider>
  );
};

export function useVersion() {
  return useContext(VersionContext);
}
