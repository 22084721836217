/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import TopTitlePage from '../../components/TopTitlePage'
import { Button, Card, Container, Form, Offcanvas, Row } from 'react-bootstrap'
import Loading from '../../components/Loading'
import { SweetAlert } from '../../components/sweetalert'

import ReactPaginate from 'react-paginate';
import './style.css'
import { useForm } from 'react-hook-form'
import { useAuth } from '../../context/auth'
import { URL as URL1 } from '../../config/url'
import { useNavigate } from 'react-router-dom'
import Financeiro from '../../services/Financeiro'
import ButtonFilterAndSelect from '../../components/ButtonFilterAndSelect'
import ButtonLimparAndFiltrar from '../../components/ButtonLimparAndFiltrar'
import { formatCPFOrCNPJ } from '../../utilities/formatCPForCNPJ'
import TableList from '../../components/TableComponents/TableList'
import InputPaciente from '../../components/Inputs/InputPaciente'
import InputTecnico from '../../components/Inputs/InputTecnico'
import TableData from './TableData'
import { isValidCPF } from '../../utilities/isValidCpf'
import { formatMoneySemR$ } from '../../utilities/formatMoneyValue'
import InputSearchName from '../../components/InputSearchName'

import { format, startOfMonth, endOfMonth } from 'date-fns'

const ListaFinanceiros = () => {

  const { register, getValues, handleSubmit, reset, setValue } = useForm()
  const { user } = useAuth()

  const navigate = useNavigate()

  const [financeiros, setFinanceiros] = useState([])
  const [loading, setLoading] = useState(false)

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [totalValor, setTotalValor] = useState('R$ 0.00');

  const [show, setShow] = useState(false);
  const [filter, setFilter] = useState(false);

  useEffect(() => {
    listAllFinanceiros(1, true);
  }, []);


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function listAllFinanceiros(pageNumber, filtroDefault = false) {
    setLoading(true);

    const values = getValues()
    const today = new Date()
    let newFiltro

    if (filtroDefault === true) {
      newFiltro = {
        data_inicial: format(startOfMonth(today), 'yyyy-MM-dd'),
        data_final: format(endOfMonth(today), 'yyyy-MM-dd'),
        tecnico_id: user.tecnico?.id,
      }
      setValue('data_inicial', format(startOfMonth(today), 'yyyy-MM-dd'));
      setValue('data_final', format(endOfMonth(today), 'yyyy-MM-dd'));
      setValue('tecnico_id', user.tecnico?.id);
      setValue('tecnico', user.tecnico?.nome);

    } else {
      newFiltro = {
        paciente_id: values.paciente_id ?? '',
        tecnico_id: values.tecnico_id ?? '',
        data_inicial: values.data_inicial ?? '',
        data_final: values.data_final ?? '',
      }
    }

    Financeiro.listAll(pageNumber, newFiltro)
      .then((data) => {
        setFinanceiros(data.data.data);
        setTotalPages(data.data.paging.total_pages)
        setTotalRegistros(data.data.paging.total_records)
        setTotalValor(formatMoneySemR$(data.data.analytics?.total_financeiro))
      })
      .catch((error) => {
        SweetAlert.error(error.response.data.message, 'Financeiros');
      })
      .finally(() => {
        // setFiltros(newFiltro)
        setLoading(false)
      });
  }

  function listFilterName(pageNumber) {
    setLoading(true);

    const values = getValues()

    let newFiltro = {
      paciente_nome: values.nome_input ?? '',
    }

    Financeiro.listAll(pageNumber, newFiltro)
      .then((data) => {
        setFinanceiros(data.data.data);
        setTotalPages(data.data.paging.total_pages)
        setTotalRegistros(data.data.paging.total_records)
        setTotalValor(formatMoneySemR$(data.data.analytics?.total_financeiro))
      })
      .catch((error) => {
        SweetAlert.error(error.response.data.message, 'Financeiros');
      })
      .finally(() => {
        // setFiltros(newFiltro)
        setLoading(false)
      });
  }


  const handlePageChange = (selected) => {
    setPage(selected.selected + 1)
    if (getValues('nome_input') === '') {

      listFilterName(selected.selected + 1);
    } else {

      listAllFinanceiros(selected.selected + 1);
    }
  };

  function onFilter() {

    setFilter(true)

    setShow(false)
    listAllFinanceiros(1)
  }

  function deleteFinanceiro(id) {
    setLoading(true)
    Financeiro.delete(id)
      .then((data) => {
        SweetAlert.success('Financeiro deletado com sucesso!', 'Financeiro');
        listAllFinanceiros()
      })
      .catch((error) => {
        console.log(error)
        SweetAlert.error(error?.response?.data?.message, 'Financeiro');
      })
      .finally(() => setLoading(false))
  }

  function deleteContratoAlert(id) {
    SweetAlert.question('Atenção', 'Deletar financeiro?', 'SIM!', () => deleteFinanceiro(id), '#FF0000')
  }

  function editContrato(id) {
    navigate(URL1.financeiro_cadastro + '/' + id)
  }

  function cleanFilter() {
    const today = new Date()
    setValue('data_inicial', format(startOfMonth(today), 'yyyy-MM-dd'));
    setValue('data_final', format(endOfMonth(today), 'yyyy-MM-dd'));
    setValue('codigo', '')
    setValue('paciente_id', '')
    setValue('paciente', '')
    setValue('tecnico', '')
    setValue('tecnico_id', '')
  }

  return (
    <>
      {loading === true ? <Loading /> : ''}

      <Offcanvas show={show} onHide={handleClose} style={{ height: '100vh', backgroundColor: '#f1f1f1' }} placement='bottom'>
        <Form onSubmit={handleSubmit(onFilter)}>
          <Container>
            <Offcanvas.Header closeButton>
              <TopTitlePage title='Filtro' />
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Card className='cardShadow'>
                <Card.Body className='p-3'>

                  <Row className='d-flex align-items-end'>
                    <Form.Group className="col-12 col-lg-3">
                      <Form.Label>Data inicial</Form.Label>
                      <Form.Control
                        size='sm'
                        type="date"
                        {...register('data_inicial')}
                      />
                    </Form.Group>

                    <Form.Group className="col-12 col-lg-3">
                      <Form.Label>Data final</Form.Label>
                      <Form.Control
                        size='sm'
                        type="date"
                        {...register('data_final')}
                      />
                    </Form.Group>
                  </Row>

                  <Row className='d-flex align-items-end mt-2'>
                    <InputPaciente
                      setValue={setValue}
                      loading={loading}
                      setLoading={setLoading}
                      // setPhoto=''
                      getValues={getValues}
                      register={register}
                    />

                  </Row>

                  <Row className='d-flex align-items-end mt-2'>
                    <InputTecnico
                      setValue={setValue}
                      loading={loading}
                      setLoading={setLoading}
                      getValues={getValues}
                      register={register}
                    />

                  </Row>

                </Card.Body>
              </Card>
              <div className='d-flex justify-content-end mt-3 gap-3'>
                <ButtonLimparAndFiltrar
                  handleClose={handleClose}
                  reset={cleanFilter}
                  listAll={listAllFinanceiros}
                  setFilter={setFilter}
                />
              </div>
            </Offcanvas.Body>
          </Container>
        </Form>
      </Offcanvas>

      <div>
        <TopTitlePage title='Financeiros' />

        <div className='py-3 d-flex justify-content-between px-3 align-items-end gap-2 flex-column flex-md-row'>

          <ButtonFilterAndSelect
            handleShow={handleShow}
            listAll={listAllFinanceiros}
            filter={filter}
          />


          <div className='d-flex align-items-end col-12 col-md-auto'>
            <Button
              variant='success'
              onClick={() => navigate(URL1.financeiro_cadastro)}
              className='col-12 col-md-auto'
            >+ Novo Financeiro</Button>
          </div>
        </div>

        <div className='mx-3 mb-2 lg-mb-0'>

          <InputSearchName
            register={register}
            listAll={listAllFinanceiros}
            listFilterName={listFilterName}

          />

        </div>

        <Card className='mx-3 cardShadow mb-2 lg-mb-0' >
          <Card.Body className='p-0'>

            {financeiros.length === 0 ?
              <div className='d-flex justify-content-center py-2'>
                <h4>Sem registros</h4>
              </div>
              :
              <>
                <TableList>
                  <TableData
                    array={financeiros}
                    editFunction={editContrato}
                    deleteFunction={deleteContratoAlert}
                  />
                </TableList>

                <div className='mt-4 d-flex justify-content-between px-3 flex-column flex-md-row gap-3'>
                  <div className='d-flex gap-3 flex-column flex-md-row'>
                    <span> Total: {totalRegistros}</span>
                  </div>
                  <span> Valor total: R$ {totalValor}</span>
                  <ReactPaginate
                    pageCount={totalPages}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={1}
                    onPageChange={handlePageChange}
                    containerClassName={'pagination_container'}
                    activeClassName={'active'}
                    previousLabel={'<'}
                    nextLabel={'>'}
                  />
                </div>
              </>
            }


          </Card.Body>
        </Card>



      </div>
    </>
  )
}

export default ListaFinanceiros