import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Table } from 'react-bootstrap'
import { IoSearch } from "react-icons/io5";
import ReactPaginate from 'react-paginate';
import Usuarios from '../../services/Usuarios';
import { SweetAlert } from '../sweetalert';
import { formatarDocumento } from '../../utilities/formatCpfCnpj';
import { parseISO, differenceInYears } from 'date-fns';

const ModalSearchUsuarios = ({
  show,
  handleClose,
  setValues,
  loading,
  setLoading,
  registerNameId = 'usuario_id',
  registerName = 'usuario'
}) => {

  const defaultFilter = 'nome'

  const [filterValue, setFilterValue] = useState('');
  const [filterType, setFilterType] = useState(defaultFilter);

  const [totalPagesUsuarios, setTotalPagesUsuarios] = useState(1);
  const [totalRegistrosUsuarios, setTotalRegistrosUsuarios] = useState(1);
  const [usuarios, setUsuarios] = useState([]);
  const [tableUsuarios, setTableUsuarios] = useState([]);

  useEffect(() => {

    if (show === true) {
      listAllUsuarios(1)
    }
  }, [show]);

  function listAllUsuarios(pageNumber, filter = '') {
    Usuarios.listAll(pageNumber, filter)
      .then((data) => {
        setUsuarios(data.data.data)
        setTableUsuarios(data.data.data)
        setTotalPagesUsuarios(data.data.paging.total_pages)
        setTotalRegistrosUsuarios(data.data.paging.total_records)
      })
      .catch((error) => {
        SweetAlert.error(error.response.data.message, 'Tecnicos');
      })

  }

  const handlePageChange = (selected) => {
    listAllUsuarios(selected.selected + 1);
  };

  const handleFilter = () => {
    const filter = {
      [filterType]: filterValue
    }
    const filtered = listAllUsuarios(1, filter)

    setTableUsuarios(filtered);
  };

  function closeAll() {
    setFilterType(defaultFilter)
    setFilterValue('')
    setTableUsuarios(usuarios)
    handleClose();
  }

  function enterClick(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      handleFilter();
    }
  }

  const handleClick = (categoryData) => {
    setLoading(true);

    Usuarios.listId(categoryData.id)
      .then((dados) => {

        setValues(registerName, categoryData.name);
        setValues(registerNameId, categoryData.id);

        setLoading(false);
      })
      .catch((error) => {
        SweetAlert.error(error.response.data.message, 'Usuários');
        setLoading(false);
      });
  };


  return (
    <Modal show={show} onHide={handleClose} centered size='xl' backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Pesquisa de usuários</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex flex-column'>
          <Form.Group className=" d-flex gap-2 align-items-center">
            <Form.Label style={{ minWidth: 100 }}>Campo</Form.Label>
            <Form.Select
              size="sm"
              value={filterType}
              onChange={(e) => setFilterType(e.target.value)}
            >
              <option value='nome'>NOME</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="mt-2 d-flex gap-2 align-items-center">
            <Form.Label style={{ minWidth: 100 }}>Pesquisar por</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
              placeholder="Filtrar"
              onKeyDown={(e) => enterClick(e)}
              autoFocus
            />
            <Button
              onClick={handleFilter}
              variant='outline-secondary d-flex align-items-center'>
              <IoSearch size={17} color='blue' />
            </Button>
          </Form.Group>

          <div className='mt-3' style={{ border: '1px solid black', height: 350, overflowY: 'scroll' }}>
            <Table striped bordered size='sm'>
              <thead>
                <tr>
                  <th style={{ whiteSpace: "nowrap", paddingLeft: 10, paddingRight: 10 }}>Nome</th>
                </tr>
              </thead>
              <tbody>
                {tableUsuarios?.map(cliente => {
                  return (
                    <tr key={cliente.codigo}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        handleClick(cliente)
                        closeAll()
                      }}>
                      <td style={{ whiteSpace: "nowrap", overflowX: "auto", paddingLeft: 10, paddingRight: 10 }}>{cliente.name}</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
          <p className='m-0 p-0' style={{ color: 'red', fontSize: 12 }}> * Somente registros ativos serão mostrados!</p>
        </div>

        <div className='mt-4 d-flex justify-content-between px-3 flex-wrap gap-4'>
          <span> Total: {totalRegistrosUsuarios}</span>
          <ReactPaginate
            pageCount={totalPagesUsuarios}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={handlePageChange}
            containerClassName={'pagination_container'}
            activeClassName={'active'}
            previousLabel={'<'}
            nextLabel={'>'}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-between'>
        <Button variant="secondary" onClick={handleClose}>
          Fechar
        </Button>
        {/* <Button variant="primary" onClick={handleClose}>
          Save Changes
        </Button> */}
      </Modal.Footer>
    </Modal>
  )
}

export default ModalSearchUsuarios