import React from 'react'
import TableHeadRowCenter from '../../components/TableComponents/TableHeadRowCenter'
import TableBodyRowCenter from '../../components/TableComponents/TableBodyRowCenter'
import { formatarDocumento } from '../../utilities/formatCpfCnpj'
import { Button } from 'react-bootstrap'
import { FaX } from 'react-icons/fa6'
import { IoMailOutline } from "react-icons/io5";
import { FaCheck, FaWhatsapp, FaPhoneAlt } from 'react-icons/fa'
import TableHeadRowStart from '../../components/TableComponents/TableHeadRowStart'
import TableBodyRowStart from '../../components/TableComponents/TableBodyRowStart'
import { formatarTelefone } from '../../utilities/formatarTelefoneInput'

const TableData = ({ array, editFunction, deleteFunction }) => {
  return (
    <>
      <thead>
        <tr>
          <TableHeadRowCenter text='Ativo' />
          <TableHeadRowStart text='Nome' />
          <TableHeadRowStart text='Tel. Fixo' />
          <TableHeadRowStart text='Celular' />
          <TableHeadRowStart text='E-mail' />
          <TableHeadRowCenter text='Ações' />
        </tr>
      </thead>

      <tbody>
        {array?.map((cliente, index) => {
          return (
            <tr key={cliente.codigo} className='trBody'>
              <TableBodyRowCenter text={cliente.ativo === true ? <FaCheck /> : <FaX />} alt='Ativo' />
              <TableBodyRowStart text={cliente.nome} />
              <TableBodyRowStart text={<a href={`https://wa.me/${cliente.telefone.replace(/\D/g, '')}`} target='_blank' rel='noopener noreferrer'>{formatarTelefone(cliente.telefone)}</a>} />
              <TableBodyRowStart text={<a href={`https://wa.me/${cliente.telefone2.replace(/\D/g, '')}`} target='_blank' rel='noopener noreferrer'>{formatarTelefone(cliente.telefone2)}</a>} />
              <TableBodyRowStart text={<a href={`mailto:${cliente.email}`} title='E-mail'>{cliente.email}</a>} />

              <td className='tableRowCenter'>
                <div className='buttonContainer'>
                  <Button variant='outline-secondary' className='m-0 p-0 px-2' onClick={() => editFunction(cliente.id)}>Editar</Button>
                  <Button variant='outline-danger' className='m-0 p-0 px-2' onClick={() => deleteFunction(cliente.id)}>Remover</Button>



                </div>
              </td>
            </tr>
          )
        })}
      </tbody>
    </>
  )
}

export default TableData
