export const formatTelefone = (e, setValue, input) => {
  // Remove non-digit characters
  const value = e.target.value;
  let v = ''

  v = value
    .replace(/\D/g, '')
    .replace(/^(\d{2})(\d)/g, '($1) $2')

  if (v.length < 14) v = v.replace(/(\d{4})(\d{1,2})/, '$1-$2')
  else v = v.replace(/(\d{5})(\d{1,2})/, '$1-$2')

  setValue(input, v.replace(/(-\d{4})\d+?$/, '$1'))

};