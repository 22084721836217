import { axios } from '../config/axios';
import security from './security';

const TipoAtendimento = {};

TipoAtendimento.create = (body) => {
  return security.validation(axios.post('/formas-pagamentos', body));
};

TipoAtendimento.listAll = (page = '', filtros = '') => {

  let filtro = ''

  if (filtros !== '') {
    filtro = filtros.ativo !== undefined ? filtro + '&ativo=' + filtros.ativo : ''
  }

  if (filtro === undefined) {
    filtro = ''
  }

  return security.validation(axios.get('/formas-pagamentos?orderby=codigo&page_size=10&page=' + page + filtro));
};

TipoAtendimento.listAllAtivos = (page = '', filtros = '') => {

  let filtro = ''

  if (filtros !== '') {
    filtro = filtros.ativo !== undefined ? filtro + '&ativo=' + filtros.ativo : ''
  }

  if (filtro === undefined) {
    filtro = ''
  }

  return security.validation(axios.get('/formas-pagamentos?ativo=true&orderby=codigo&page_size=10&page=' + page + filtro));
};

TipoAtendimento.listId = (id) => {
  return security.validation(axios.get('/formas-pagamentos/' + id));
};

TipoAtendimento.update = (id, body) => {
  return security.validation(axios.patch('/formas-pagamentos/' + id, body));
};

TipoAtendimento.delete = (id) => {
  return security.validation(axios.delete('/formas-pagamentos/' + id));
};

export default TipoAtendimento;
