// AsideBar.js
import React from 'react';
import { Accordion, Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { URL } from '../config/url';
import './AsideBarStyle.css';

import { FaBoxes, FaUsers, FaHome, FaRegCalendar, FaDev } from "react-icons/fa";
import { MdOutlineBusiness, MdAccountBalance } from "react-icons/md";
import { LuCode, LuDollarSign } from "react-icons/lu";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { GiPayMoney, GiReceiveMoney, GiMoneyStack } from "react-icons/gi";
import { CiMoneyCheck1 } from "react-icons/ci";
import { BiSelectMultiple } from "react-icons/bi";
import { FaGreaterThan } from "react-icons/fa";
import { TbMathGreater } from "react-icons/tb";
import { IoNewspaperOutline } from "react-icons/io5";
import { RiContractLine } from "react-icons/ri";
import { GoGear } from "react-icons/go";

import logo from '../assets/logo-branco.png'

import { useAuth } from '../context/auth';
import metadata from '../../package.json';


const AsideBar = ({ children, open, setOpen }) => {
  const navigate = useNavigate();
  const { parcelasSelecionadas, loadingParcelas, sessionCurrent, user } = useAuth()

  function closeMenu() {
    setOpen(false)
  }

  function navigateRoute(route) {
    closeMenu()
    navigate(route)
  }

  return (
    // <div className='d-flex div-geral' >
    <div className='d-flex '>
      <Nav
        className={`flex-column justify-content-between ${open ? 'asideBarOpen' : 'asideBar'}`}
        // style={{ backgroundColor: '#2d3635', color: 'white' }}
        style={{
          backgroundColor: process.env.REACT_APP_PRIMARY_COLOR,
          color: 'white'
        }}
      >
        <div >

          <Accordion data-bs-theme="dark" className='d-flex flex-column gap-2' style={{ backgroundColor: process.env.REACT_APP_PRIMARY_COLOR }}>

            {/* <p onClick={() => { navigate(URL.home); closeMenu() }} className='m-0 p-0 ps-2' style={{ cursor: 'pointer' }}>
              <span className='me-2 pb-1'><FaHome /></span>
              <span className='sidebar_title '>Home</span>
            </p> */}
            <p onClick={() => { navigate(URL.calendario); closeMenu() }} className='m-0 p-0 ps-2' style={{ cursor: 'pointer' }}>
              <span className='me-2 pb-1'><FaRegCalendar /></span>
              <span className='sidebar_title '>Agenda</span>
            </p>
            <p onClick={() => { navigate(URL.pacientes_lista); closeMenu() }} className='m-0 p-0 ps-2' style={{ cursor: 'pointer' }}>
              <span className='me-2 pb-1'><FaUsers /></span>
              <span className='sidebar_title '>Pacientes</span>
            </p>
            <p onClick={() => { navigate(URL.prontuario_lista); closeMenu() }} className='m-0 p-0 ps-2' style={{ cursor: 'pointer' }}>
              <span className='me-2 pb-1'><IoNewspaperOutline /></span>
              <span className='sidebar_title '>Prontuários</span>
            </p>
            <p onClick={() => { navigate(URL.contrato_lista); closeMenu() }} className='m-0 p-0 ps-2' style={{ cursor: 'pointer' }}>
              <span className='me-2 pb-1'><RiContractLine /></span>
              <span className='sidebar_title '>Contratos</span>
            </p>
            <p onClick={() => { navigate(URL.financeiro_lista); closeMenu() }} className='m-0 p-0 ps-2' style={{ cursor: 'pointer' }}>
              <span className='me-2 pb-1'><RiContractLine /></span>
              <span className='sidebar_title '>Financeiros</span>
            </p>

            <Accordion.Item eventKey="1" className='m-0 p-0' style={{ backgroundColor: '#184347' }}>
              <Accordion.Header
                className='m-0 p-0 ps-2'
                style={{ backgroundColor: '#184347' }}
              >
                <span className='me-2 pb-1'><GoGear /></span>
                <span className='sidebar_title pb-1'>Configurações</span>
              </Accordion.Header>
              <Accordion.Body className='p-0 ps-4 d-flex flex-column gap-1' style={{ backgroundColor: '#184347' }}>
                <p onClick={() => navigateRoute(URL.status_lista)} className='m-0 sidebar_subtitle pt-2 pb-2' style={{ cursor: 'pointer' }}>Status</p>
                <p onClick={() => navigateRoute(URL.perguntas_lista)} className='m-0 sidebar_subtitle  pb-2' style={{ cursor: 'pointer' }}>Perguntas</p>
                <p onClick={() => navigateRoute(URL.tecnico_lista)} className='m-0 sidebar_subtitle  pb-2' style={{ cursor: 'pointer' }}>Doutores</p>
                <p onClick={() => navigateRoute(URL.tipo_atendimento_lista)} className='m-0 sidebar_subtitle  pb-2' style={{ cursor: 'pointer' }}>Tipos de atendimento</p>
                <p onClick={() => navigateRoute(URL.usuarios_lista)} className='m-0 sidebar_subtitle  pb-2' style={{ cursor: 'pointer' }}>Usuários</p>
              </Accordion.Body>
            </Accordion.Item>

            {user.id === 'b05468fb-a5d8-4b2a-a3d0-58029098dbfa' ?
              <Accordion.Item eventKey="2" className='m-0 p-0' style={{ backgroundColor: '#184347' }}>
                <Accordion.Header
                  className='m-0 p-0 ps-2'
                  style={{ backgroundColor: '#184347' }}
                >
                  <span className='me-2 pb-1'><FaDev /></span>
                  <span className='sidebar_title pb-1'>Testes</span>
                </Accordion.Header>
                <Accordion.Body className='p-0 ps-4 d-flex flex-column gap-1' style={{ backgroundColor: '#184347' }}>
                  <p onClick={() => navigateRoute(URL.imagem)} className='m-0 sidebar_subtitle pt-2 pb-2' style={{ cursor: 'pointer' }}>Imagem</p>
                  <p onClick={() => navigateRoute(URL.camera)} className='m-0 sidebar_subtitle pt-2 pb-2' style={{ cursor: 'pointer' }}>Camera</p>
                  <p onClick={() => navigateRoute(URL.versao_cadastro)} className='m-0 sidebar_subtitle pt-2 pb-2' style={{ cursor: 'pointer' }}>Versão</p>
                </Accordion.Body>
              </Accordion.Item> : ''}

          </Accordion>
        </div>

        <div className='d-flex justify-content-center flex-column align-items-center gap-2 teste' style={{ position: 'absolute', bottom: 0, width: '100%' }}>
          <img src={logo} alt='logo' style={{ width: '50%' }} />
          <small className='pb-2 '>Versão {metadata.version}</small>
        </div>
      </Nav >


      <div className='page-content'>
        {children}
      </div>

    </div >
  );
};

export default AsideBar;
