import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
// import { FiCheck } from 'react-icons/fi';
import SessionsServices from '../../services/sessions'
import { useParams, useNavigate } from 'react-router-dom';
import { SweetAlert } from '../../components/sweetalert';
import { URL } from '../../config/url';

function RecuperarSenhaConfirmar() {
  const [loadingConfirmar, setLoadingRecuperar] = useState(false)
  const { hash } = useParams();
  const navigate = useNavigate();
  const { register, getValues, formState: { errors }, } = useForm();

  async function handlerConfirmar() {
    try {
      const password = getValues("password")

      setLoadingRecuperar(true)
      await SessionsServices.login_recuperar_senha_confirmar(hash, password)
      SweetAlert.success('Senha alterado com sucesso!', 'Nova Senha')
      setLoadingRecuperar(false)
      navigate(URL.login)
    } catch (error) {
      setLoadingRecuperar(false)
      SweetAlert.error(error, 'Nova Senha')
    }
  }

  return (
    <>
      <Container className="mt-2">
        <Row className="align-items-center justify-content-center mt-5">
          <Col xs={12} sm={12} md={7} lg={4}>
            {/* <Cards title="Nova senha"> */}
            <Form.Group as={Col} xs={12} sm={12} className="mb-3">
              <Form.Label>Digite uma nova senha</Form.Label>
              <Form.Control type="password" placeholder=""
                {...register("password", {
                  required: "Senha não pode ficar em branco.",
                  minLength: { value: 8, message: 'Informe no mínimo 8 caracteres.' },
                  maxLength: { value: 255, message: 'Informe no máximo 255 caracteres.' },
                })}
              />
              {errors.password && <Form.Text className="text-danger">{errors.password.message}</Form.Text>}
            </Form.Group>

            <Col xs={12} sm={12} md="auto" className="mt-4">
              <Button className='w-100' variant="success" onClick={handlerConfirmar} disabled={loadingConfirmar}>
                {loadingConfirmar === true ?
                  <span >
                    <Spinner animation="border" size="sm" /> Aguarde...{' '}
                  </span>
                  :
                  <span >
                    Alterar Senha
                  </span>
                }
              </Button>
            </Col>
            {/* </Cards> */}
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default React.memo(RecuperarSenhaConfirmar)