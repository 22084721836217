import React from 'react'
import TableHeadRowCenter from '../../components/TableComponents/TableHeadRowCenter'
import TableBodyRowCenter from '../../components/TableComponents/TableBodyRowCenter'
import { Button } from 'react-bootstrap'
import { FaX } from 'react-icons/fa6'
import { FaCheck } from 'react-icons/fa'
import TableHeadRowStart from '../../components/TableComponents/TableHeadRowStart'
import TableBodyRowStart from '../../components/TableComponents/TableBodyRowStart'

const TableData = ({ array, editFunction, deleteFunction }) => {
  return (
    <>
      <thead>
        <tr>
          {/* <TableHeadRowCenter text='Ativo' /> */}
          <TableHeadRowStart text='Nome' />
          <TableHeadRowCenter text='Ações' />
        </tr>
      </thead>

      <tbody >
        {array?.map((cliente, index) => {
          return (
            <tr key={cliente.codigo} className='trBody'>
              {/* <TableBodyRowCenter text={cliente.ativo === true ? <FaCheck /> : <FaX />} alt='Ativo' /> */}
              <TableBodyRowStart text={cliente.nome} />

              <td className='tableRowCenter'>
                <div className='buttonContainer'>
                  <Button variant='outline-secondary' className='m-0 p-0 px-2' onClick={() => editFunction(cliente.id)} >Editar</Button>
                  <Button variant='outline-danger' className='m-0 p-0 px-2' onClick={() => { deleteFunction(cliente.id) }} >Remover</Button>
                </div>
              </td>
            </tr>
          )
        })}
      </tbody >
    </>
  )
}

export default TableData