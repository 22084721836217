import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import "./style.css";
import { useAuth } from '../../context/auth';
import { useForm } from 'react-hook-form';
import { URL } from '../../config/url';
import { useNavigate } from 'react-router-dom';
import { SweetAlert } from '../../components/sweetalert';

const Login = () => {

  const { Login } = useAuth();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const { register, getValues, setValue, handleSubmit } = useForm();

  const onSubmit = (data, e) => {
    e.preventDefault();

    if (data.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
      return SweetAlert.error('Informe um email válido', 'Login');
    }
    if (data.email === '' || data.email === undefined || data.email === null) {
      return SweetAlert.error('Email é um campo obrigatório', 'Login');
    }
    if (data.senha === '' || data.senha === undefined || data.senha === null) {
      return SweetAlert.error('Senha é um campo obrigatório', 'Login');
    }
    if (data.senha.length < 8) {
      return SweetAlert.error('Senha deve ter no mínimo 8 caracteres', 'Login');
    }

    async function fazerLogin() {
      const email = getValues('email');
      const senha = getValues('senha');

      try {
        await Login(email, senha)
      } catch (error) {
        setLoading(false)
        console.log(error)
        SweetAlert.error(error?.response?.data?.message, 'Erro')
        // SweetAlert.error('Erro ao fazer login', 'Erro')
        setValue('token', '');
      }
    }

    setLoading(true);
    fazerLogin();
  };

  return (
    <div className="sign-in__wrapper" >
      {/* <div style={{ position: 'absolute' }} className='logo_lm_fundo'>
        <img src={logo} alt="" style={{ maxWidth: '100vw', minWidth: '100%', height: '100vh', opacity: 0.2 }} />
      </div> */}
      {/* Overlay */}
      <div class="custom-shape-divider-top-1713470869">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
          <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
          <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
        </svg>
      </div>
      {/* <div className="sign-in__backdrop0"></div> */}
      <div className="sign-in__backdrop1"></div>
      {/* <div className="sign-in__backdrop2"></div> */}
      {/* <div className="sign-in__backdrop3"></div> */}
      {/* Form */}
      <Form className="cardShadow p-4 bg-white rounded" onSubmit={handleSubmit(onSubmit)}>
        {/* Header */}
        <div className="h4 mb-2 text-center">Login</div>
        <Form.Group className="mb-2" controlId="username">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="text"
            placeholder="Email"
            {...register('email')}
            required
          />
        </Form.Group>
        <Form.Group className="mb-2" controlId="password">
          <Form.Label>Senha</Form.Label>
          <Form.Control
            type="password"
            placeholder="Senha"
            {...register('senha')}
            required
          />
        </Form.Group>
        {/* <Form.Group className="mb-2" controlId="checkbox">
          <Form.Check type="checkbox" label="Remember me" />
        </Form.Group> */}
        {!loading ? (
          <Button className="w-100" variant="primary" type="submit">
            Acessar
          </Button>
        ) : (
          <Button className="w-100" variant="primary" type="submit" disabled>
            Acessando...
          </Button>
        )}
        <div className="d-flex justify-content-between">
          {/* <Button
            className="text-muted px-0"
            variant="link"
            onClick={() => navigate(URL.login_criar_usuario)}
          >
            Criar conta
          </Button> */}
          <Button
            className="text-muted px-0"
            variant="link"
            onClick={() => navigate(URL.login_recuperar_senha)}
          >
            Esqueceu a senha?
          </Button>
        </div>
      </Form>
      {/* Footer */}
      {/* <div className="w-100 mb-2 position-absolute bottom-0 start-50 translate-middle-x text-white text-center">
        Desenvolvido por 
      </div> */}
    </div>

  );
};

export default Login;
