import { axios } from '../config/axios';
import security from './security';

const TipoAtendimento = {};

TipoAtendimento.create = (body) => {
  return security.validation(axios.post('/tipos-atendimentos', body));
};

TipoAtendimento.listAll = (page = '', filtros = '') => {

  let filtro = ''

  if (filtros !== '') {
    filtro = filtros.ativo !== undefined ? filtro + '&ativo=' + filtros.ativo : ''
  }

  if (filtro === undefined) {
    filtro = ''
  }

  return security.validation(axios.get('/tipos-atendimentos?orderby=codigo&page_size=10&page=' + page + filtro));
};

TipoAtendimento.listAllAtivos = (page = '', filtros = '') => {

  let filtro = ''

  if (filtros !== '') {
    filtro = filtros.nome !== undefined ? filtro + '&descricao=' + filtros.nome.toUpperCase() : ''
  }

  if (filtro === undefined) {
    filtro = ''
  }

  return security.validation(axios.get('/tipos-atendimentos?ativo=true&orderby=codigo&page_size=10&page=' + page + filtro));
};

TipoAtendimento.listId = (id) => {
  return security.validation(axios.get('/tipos-atendimentos/' + id));
};

TipoAtendimento.update = (id, body) => {
  return security.validation(axios.patch('/tipos-atendimentos/' + id, body));
};

TipoAtendimento.delete = (id) => {
  return security.validation(axios.delete('/tipos-atendimentos/' + id));
};

export default TipoAtendimento;
