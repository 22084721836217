import React, { useRef } from 'react';
import { Button } from 'react-bootstrap';

function ImagemIcone({ photo, setPhoto }) {
  const fileInputRef = useRef(null);

  const handleUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setPhoto(file);
    }
  };

  return (
    <>
      <div className='mb-3 d-flex gap-3 flex-column flex-md-row'>
        <Button variant='outline-dark' onClick={() => { fileInputRef.current.click() }}>Buscar imagem</Button>
        {photo === null ? '' : <Button variant='outline-danger' onClick={() => { setPhoto(null) }}>Remover imagem</Button>}
        <input type="file" accept=".jpg, .jpeg, .png" ref={fileInputRef} style={{ display: 'none' }} onChange={handleUpload} />
      </div>

      <div className='d-flex justify-content-center gap-4 flex-wrap'>

        {photo === null ? '' :
          <div className='div-foto' style={{ width: 200 }}>
            <p>Imagem selecionada</p>
            {
              typeof photo === 'string' ?
                <img src={photo} alt={`Foto`} className='foto' /> :
                <img src={URL.createObjectURL(photo)} alt={`Foto`} className='foto' />
            }
          </div>
        }
      </div>
    </>
  );
}

export default ImagemIcone;
