import React from 'react'
import TableHeadRowCenter from '../../components/TableComponents/TableHeadRowCenter'
import TableBodyRowCenter from '../../components/TableComponents/TableBodyRowCenter'
import { formatarDocumento } from '../../utilities/formatCpfCnpj'
import { Button } from 'react-bootstrap'
import { FaX } from 'react-icons/fa6'
import { FaCheck } from 'react-icons/fa'
import TableHeadRowStart from '../../components/TableComponents/TableHeadRowStart'
import TableBodyRowStart from '../../components/TableComponents/TableBodyRowStart'
import { formatMoney } from '../../utilities/formatMoneyValue'
import { format } from 'date-fns'

const TableData = ({ array, editFunction, deleteFunction, viewFunction }) => {
  return (
    <>
      <thead>
        <tr>
          <TableHeadRowCenter text='Código' />
          <TableHeadRowCenter text='Data de adesão' />
          <TableHeadRowStart text='Nome' />
          <TableHeadRowStart text='Prontuário' />
          <TableHeadRowCenter text='Valor' />
          <TableHeadRowCenter text='Ações' />
        </tr>
      </thead>

      <tbody >
        {array?.map((cliente, index) => {
          return (
            <tr key={cliente.codigo} className='trBody'>
              <TableBodyRowCenter text={cliente.codigo} />
              <TableBodyRowCenter text={format(new Date(cliente.adesao_data), 'dd/MM/yyyy')} />
              <TableBodyRowStart text={cliente.paciente.nome} />
              <TableBodyRowStart text={cliente.prontuario.titulo} />
              <TableBodyRowCenter text={formatMoney(cliente.valor_total)} />

              <td className='tableRowCenter'>
                <div className='buttonContainer'>
                  <Button variant='outline-secondary' className='m-0 p-0 px-2' onClick={() => editFunction(cliente.id)} >Editar</Button>
                  <Button variant='outline-danger' className='m-0 p-0 px-2' onClick={() => { deleteFunction(cliente.id) }} >Remover</Button>
                  <Button variant='outline-danger' className='m-0 p-0 px-2' onClick={() => { viewFunction(cliente.id) }} >Visualizar</Button>
                </div>
              </td>
            </tr>
          )
        })}
      </tbody >
    </>
  )
}

export default TableData