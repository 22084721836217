import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Form, Row, Col, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { SweetAlert } from '../../components/sweetalert';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../context/auth';
import HeaderLabel from '../../components/HeaderLabel';
import Contratos from '../../services/Contratos';
import { addHours, format } from 'date-fns';
import Loading from '../../routers/loading';
import { isValidCPF } from '../../utilities/isValidCpf';
import { formatarTelefone } from '../../utilities/formatarTelefoneInput';
import ButtonSaveContent from '../../components/ButtonSaveContent';
import { FaX } from "react-icons/fa6";
import ModalSearchPacientes from '../../components/ModalSearch/ModalSearchPacientes';
import CadastroPaciente from '../cadastro_paciente';
import CadastroProntuarios from '../cadastro_prontuarios';
import ModalSearchProntuarios from '../../components/ModalSearch/ModalSearchProntuarios';
import { IoSearch } from "react-icons/io5";
import { FaRegEye } from "react-icons/fa";
import { permitirApenasValoresDecimaisPositivos } from '../../utilities/permitirApenasValoresDecimaisPositivos';
import { validaInteiroELimitaCampo } from '../../utilities/validaInteiroELimitaCampo';
import { calcIdade } from '../../utilities/calcIdade';


const CadastroContratos = ({ }) => {
  const { handleSubmit, register, getValues, setValue, reset, watch, setFocus } = useForm();
  const { sessionCurrent } = useAuth();
  const [loading, setLoading] = useState(false);

  const [files, setFiles] = useState([]);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [photo, setPhoto] = useState(null);

  const [showCliente, setShowCliente] = useState(false);
  const [showProntuarios, setShowProntuarios] = useState(false);
  const [showModalCadastroPessoa, setShowModalCadastroPessoa] = useState(false);
  const [showModalCadastroProntuario, setShowModalCadastroProntuario] = useState(false);

  const params = useParams()
  const navigate = useNavigate()
  // const semNumero = watch('semNumero');

  useEffect(() => {

    if (params.id) {
      setLoading(true)
      Contratos.listId(params.id)
        .then((data2) => {

          setValue('paciente_id', data2.data.paciente.id)
          setValue('paciente', data2.data.paciente.nome + calcIdade(data2.data.paciente.data_nascimento))
          setValue('prontuario_id', data2.data.prontuario.id)
          setValue('prontuario', data2.data.prontuario.titulo)
          setValue('adesao_data', data2.data.adesao_data)
          setValue('adesao_descricao', data2.data.adesao_descricao)
          setValue('adesao_frequencia', data2.data.adesao_frequencia)
          setValue('adesao_prazo', data2.data.adesao_prazo)
          setValue('valor_total', Number(data2.data.valor_total).toFixed(2))
          setValue('forma_pagamento', data2.data.forma_pagamento)
          setValue('forma_pagamento_numero_parcelas', data2.data.forma_pagamento_numero_parcelas)
          setValue('forma_pagamento_valor_parcela', Number(data2.data.forma_pagamento_valor_parcela).toFixed(2))
          setValue('quantidade_sessoes', data2.data.quantidade_sessoes)

          let newFiles = []

          data2.data.anexos.map(file => {
            return (
              newFiles.push({
                "id": file.id,
                "descricao": file.descricao,
                "nome_arquivo": file.nome_arquivo,
                "name": file.nome_arquivo,
                "url": file.url
              })
            )
          })

          setFiles(newFiles)

        })
        .catch((error) => {
          console.log(error)
          SweetAlert.error(error?.response?.data?.message, 'Contratos');
        })
        .finally(() => {
          setLoading(false)
        })

    } else {
      setValue('adesao_data', format(new Date(), 'yyyy-MM-dd'))
    }

  }, []);

  const handleCloseCliente = () => setShowCliente(false);
  const handleShowCliente = () => setShowCliente(true);
  const handleShowModalCadastroPessoa = () => setShowModalCadastroPessoa(true);
  const handleCloseModalCadastroPessoa = () => setShowModalCadastroPessoa(false);
  const handleCloseProntuarios = () => setShowProntuarios(false);
  const handleShowProntuarios = () => setShowProntuarios(true);
  const handleShowModalCadastroProntuario = () => setShowModalCadastroProntuario(true);
  const handleCloseModalCadastroProntuario = () => setShowModalCadastroProntuario(false);

  function onSubmit(data) {


    if (data.paciente_id === '' || data.paciente_id === undefined || data.paciente_id === null) {
      return SweetAlert.error('Paciente é um campo obrigatório', 'Contrato');
    }
    if (data.prontuario_id === '' || data.prontuario_id === undefined || data.prontuario_id === null) {
      return SweetAlert.error('Prontuário é um campo obrigatório', 'Contrato');
    }
    if (data.adesao_data === '' || data.adesao_data === undefined || data.adesao_data === null) {
      return SweetAlert.error('Data adesão é um campo obrigatório', 'Contrato');
    }
    if (data.adesao_frequencia === '' || data.adesao_frequencia === undefined || data.adesao_frequencia === null) {
      return SweetAlert.error('Frequência é um campo obrigatório', 'Contrato');
    }
    if (data.adesao_prazo === '' || data.adesao_prazo === undefined || data.adesao_prazo === null) {
      return SweetAlert.error('Prazo estimado é um campo obrigatório', 'Contrato');
    }
    if (data.quantidade_sessoes === '' || data.quantidade_sessoes === undefined || data.quantidade_sessoes === null) {
      return SweetAlert.error('Quantidade de sessões é um campo obrigatório', 'Contrato');
    }
    if (data.adesao_descricao === '' || data.adesao_descricao === undefined || data.adesao_descricao === null) {
      return SweetAlert.error('Descrição é um campo obrigatório', 'Contrato');
    }
    if (data.valor_total === '' || data.valor_total === undefined || data.valor_total === null) {
      return SweetAlert.error('Valor total é um campo obrigatório', 'Contrato');
    }
    if (data.forma_pagamento_numero_parcelas === '' || data.forma_pagamento_numero_parcelas === undefined || data.forma_pagamento_numero_parcelas === null) {
      return SweetAlert.error('Número de parcelas é um campo obrigatório', 'Contrato');
    }
    if (data.forma_pagamento_valor_parcela === '' || data.forma_pagamento_valor_parcela === undefined || data.forma_pagamento_valor_parcela === null) {
      return SweetAlert.error('Valor da parcela é um campo obrigatório', 'Contrato');
    }


    setLoading(true)

    const paciente = {
      "paciente_id": data.paciente_id,
      "prontuario_id": data.prontuario_id,
      "adesao_data": format(new Date(data.adesao_data), 'yyyy-MM-dd'),
      "adesao_frequencia": data.adesao_frequencia,
      "adesao_descricao": data.adesao_descricao,
      "adesao_prazo": data.adesao_prazo,
      "valor_total": data.valor_total,
      "forma_pagamento": data.forma_pagamento,
      "forma_pagamento_numero_parcelas": data.forma_pagamento_numero_parcelas,
      "forma_pagamento_valor_parcela": data.forma_pagamento_valor_parcela,
      'quantidade_sessoes': data.quantidade_sessoes
    }


    if (params.id) {
      Contratos.update(params.id, paciente)
        .then((data) => {

          const newFiles = [];

          files.forEach(file => {
            if (file.id === undefined) {
              newFiles.push(file);
            }
          })

          if (newFiles.length > 0) {

            let formData = new FormData();
            newFiles.forEach((file, index) => {
              formData.append(`file${index}`, file);
            });

            Contratos.createPhoto(data.data.id, formData)
              .then(() => {

              })
              .catch((error) => {
                SweetAlert.error(error?.response?.data?.message, 'Contratos');
              });
          }

          if (deletedFiles.length > 0) {

            deletedFiles.forEach((deletedFile) => {
              Contratos.deletePhoto(data.data.id, deletedFile)
                .then(() => {
                  // SweetAlert.success('Registro atualizado com sucesso', 'Prontuários')
                  // navigate(-1)
                })
                .catch((error) => {
                  SweetAlert.error(error?.response?.data?.message, 'Contratos');
                });
            })


          }

          SweetAlert.success('Registro atualizado com sucesso', 'Contratos')
          navigate(-1)

        })
        .catch((error) => {
          SweetAlert.error(error?.response?.data?.message, 'Contratos');
        })
        .finally(() => { setLoading(false) })
    } else {
      Contratos.create(paciente)
        .then((data) => {
          if (files && files.length > 0) {

            let formData = new FormData();
            files.forEach((file, index) => {
              formData.append(`file${index}`, file); // Use uma chave única para cada arquivo
            });

            Contratos.createPhoto(data.data.id, formData)
              .then(() => {
                SweetAlert.success('Contrato cadastrado com sucesso', 'Contratos');
                reset();
                setValue('adesao_data', format(new Date(), 'yyyy-MM-dd'))
                setFiles(null)
                setTimeout(() => {
                  setFocus('cpf');
                }, 1);
              })
              .catch((error) => {
                SweetAlert.error(error?.response?.data?.message, 'Contratos');
              });
          } else {
            SweetAlert.success('Contrato cadastrado com sucesso', 'Contratos');
            reset();
          }
          setValue('adesao_data', format(new Date(), 'yyyy-MM-dd'))

        })
        .catch((error) => {
          console.log(error);
          SweetAlert.error(error?.response?.data?.message, 'Contratos');
        })
        .finally(() => {
          setLoading(false);
        });

    }

  }


  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const removeFile = (index, file) => {

    if (file.id !== undefined) {
      deletedFiles.push(file.id);
    }

    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const openFileInNewTab = (fileUrl) => {
    window.open(fileUrl.url, '_blank');
  };

  function showSelectedPaciente() {
    const paciente_id = getValues('paciente_id')

    if (paciente_id === undefined) {
      return SweetAlert.error('Selecione um paciente antes de visualizar seus dados', 'Paciente')
    }

    const url = `/pacientes/cadastro/${paciente_id}`
    window.open(url, '_blank')
  }

  function showSelectedProntuario() {
    const prontuario_id = getValues('prontuario_id')

    if (prontuario_id === undefined) {
      return SweetAlert.error('Selecione um prontuário antes de visualizar seus dados', 'Prontuário')
    }

    const url = `/prontuario/cadastro/${prontuario_id}`
    window.open(url, '_blank')
  }


  return (
    <>
      {loading === true ? <Loading /> : ''}

      <ModalSearchPacientes
        show={showCliente}
        handleClose={handleCloseCliente}
        setValues={setValue}
        handleShowModalCadastroPessoa={handleShowModalCadastroPessoa}
        handleCloseModalCadastroPessoa={handleCloseModalCadastroPessoa}
        loading={loading}
        setLoading={setLoading}
        setPhoto={() => { }}
      />

      <ModalSearchProntuarios
        show={showProntuarios}
        handleClose={handleCloseProntuarios}
        setValues={setValue}
        loading={loading}
        setLoading={setLoading}
        handleShowModalCadastroProntuario={handleShowModalCadastroProntuario}
        handleCloseModalCadastroProntuario={handleCloseModalCadastroProntuario}
      />

      <Modal show={showModalCadastroPessoa} fullscreen centered >
        <Modal.Header closeButton onHide={handleCloseModalCadastroPessoa} />
        <Modal.Body>
          <CadastroPaciente
            origem='modal'
            handleCloseModalCadastroPessoa={handleCloseModalCadastroPessoa}
            setValueModal={setValue}
            handleCloseCliente={handleCloseCliente}
          />
        </Modal.Body>
      </Modal>

      <Modal show={showModalCadastroProntuario} fullscreen centered >
        <Modal.Header closeButton onHide={handleCloseModalCadastroProntuario} />
        <Modal.Body>
          <CadastroProntuarios
            origem='modal'
            handleCloseModalCadastroProntuario={handleCloseModalCadastroProntuario}
            setValueModal={setValue}
            handleCloseProntuarios={handleCloseProntuarios}
            values={getValues()}
          />
        </Modal.Body>
      </Modal>

      <Container>
        <Form onSubmit={handleSubmit(onSubmit)} className='pt-3'>

          <HeaderLabel
            label='Contratos'
          />

          <Card className='cardShadow'>
            <Card.Body className='p-3'>

              <Row className='d-flex align-items-end'>
                <Form.Group className="col-12 col-lg-auto mb-2">
                  <h4 className='textPrimary'>Geral</h4>
                </Form.Group>
              </Row>

              <Form.Group className="col-12 col-lg-10">
                <Form.Label>Paciente <span style={{ color: 'red' }}>*</span></Form.Label>
                <div className='d-flex'>
                  <Form.Control size='sm' type="text" {...register('paciente')} disabled />
                  <Button
                    variant='outline-secondary'
                    className='p-0 px-2 d-flex align-items-center ms-1'
                    onClick={handleShowCliente}
                  >
                    <IoSearch size={20} />
                  </Button>
                  <Button
                    variant='outline-secondary'
                    className='p-0 px-2 d-flex align-items-center ms-1'
                    onClick={showSelectedPaciente}
                  >
                    <FaRegEye size={20} />
                  </Button>

                </div>
              </Form.Group>


              <Form.Group className="col-12 col-lg-5 mt-2">
                <Form.Label>Prontuário <span style={{ color: 'red' }}>*</span></Form.Label>
                <div className='d-flex'>
                  <Form.Control size='sm' type="text" {...register('prontuario')} disabled />
                  <Button
                    variant='outline-secondary'
                    className='p-0 px-2 d-flex align-items-center ms-1'
                    onClick={handleShowProntuarios}
                  >
                    <IoSearch size={20} />
                  </Button>
                  <Button
                    variant='outline-secondary'
                    className='p-0 px-2 d-flex align-items-center ms-1'
                    onClick={showSelectedProntuario}
                  >
                    <FaRegEye size={20} />
                  </Button>

                </div>

              </Form.Group>

              <Row>
                <Form.Group className="col-12 col-lg-2 mt-2">
                  <Form.Label>Data adesão <span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control size='sm' type="date" {...register('adesao_data')} />
                </Form.Group>

                <Form.Group className="col-12 col-lg-2 mt-2">
                  <Form.Label>Frequência <span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control size='sm' type="text" {...register('adesao_frequencia')} placeholder='1 VEZ/SEMANA' />
                </Form.Group>

                <Form.Group className="col-12 col-lg-2 mt-2">
                  <Form.Label>Prazo estimado <span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control size='sm' type="text" {...register('adesao_prazo')} placeholder='3/5 MESES' />
                </Form.Group>

                <Form.Group className="col-12 col-lg-2 mt-2">
                  <Form.Label>Quantidade de sessões <span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control size='sm' type="text" {...register('quantidade_sessoes')}
                    onChange={(e) => validaInteiroELimitaCampo(e.target.value, 3, 'quantidade_sessoes', setValue)}
                  />
                </Form.Group>
              </Row>

              <Form.Group className="col-12 mt-2">
                <Form.Label>Descrição <span style={{ color: 'red' }}>*</span></Form.Label>
                <Form.Control size='sm' as="textarea" rows={3} {...register('adesao_descricao')} placeholder='Pacote de 10 sessões' />
              </Form.Group>




            </Card.Body>
          </Card>

          <Card className='cardShadow mt-3'>
            <Card.Body className='p-3'>

              <Row className='d-flex align-items-end'>
                <Form.Group className="col-12 col-lg-auto mb-2">
                  <h4 className='textPrimary'>Pagamento</h4>
                </Form.Group>
              </Row>


              <Row>
                <Form.Group className="col-12 col-lg-2 mt-2">
                  <Form.Label>Valor total <span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control
                    size='sm'
                    type="text"
                    {...register('valor_total')}
                    onChange={(e) => permitirApenasValoresDecimaisPositivos(e, setValue, 'valor_total')}
                  />
                </Form.Group>

                <Form.Group className="col-12 col-lg-2 mt-2">
                  <Form.Label>Forma pagamento <span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Select size='sm' {...register('forma_pagamento')}>
                    <option value="DINHEIRO">DINHEIRO</option>
                    <option value="PIX">PIX</option>
                    <option value="CHEQUE">CHEQUE</option>
                    <option value="BOLETO">BOLETO</option>
                    <option value="TRANSFERENCIA_BANCARIA">TRANSFERÊNCIA BANCÁRIA</option>
                    <option value="CARTAO_DEBITO">CARTÃO DE DÉBITO</option>
                    <option value="CARTAO_CREDITO">CARTÃO DE CRÉDITO</option>
                  </Form.Select>
                </Form.Group>


                <Form.Group className="col-12 col-lg-2 mt-2">
                  <Form.Label>Número de parcelas <span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control
                    size='sm'
                    type="text"
                    {...register('forma_pagamento_numero_parcelas')}
                    onChange={(e) => validaInteiroELimitaCampo(e.target.value, 2, 'forma_pagamento_numero_parcelas', setValue)}
                  />
                </Form.Group>

                <Form.Group className="col-12 col-lg-2  mt-2">
                  <Form.Label>Valor parcela <span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control
                    size='sm'
                    type="text"
                    {...register('forma_pagamento_valor_parcela')}
                    onChange={(e) => permitirApenasValoresDecimaisPositivos(e, setValue, 'forma_pagamento_valor_parcela')}
                  />
                </Form.Group>
              </Row>

            </Card.Body>
          </Card>

          <Card className='cardShadow mt-3'>
            <Card.Body className='p-3'>
              <Row className='d-flex align-items-end'>
                <Form.Group className="col-12 col-lg-auto mb-2">
                  <h4 className='textPrimary'>Anexos</h4>
                </Form.Group>
              </Row>
              <Form.Group>
                <div className="file-upload-container">
                  <span className="file-upload-text">Clique para selecionar arquivos</span>
                  <Form.Control
                    id="fileUpload"
                    type="file"
                    multiple
                    accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                    onChange={handleFileChange}
                  />
                </div>
              </Form.Group>
              <Row className='mt-3'>
                {files?.length > 0 && files.map((file, index) => (
                  <Col key={index} xs={12} sm={6} md={4} lg={3} className='mb-3'>
                    <div className={file.url !== undefined ? 'file-preview-save' : 'file-preview-not-save'}>
                      <p onClick={() => file.url !== undefined ? openFileInNewTab(file) : ''}>{file.name}</p>
                      <Button variant="danger" className='m-0 p-0 p-1' onClick={() => removeFile(index, file)}><FaX /></Button>
                    </div>
                  </Col>
                ))}
              </Row>
            </Card.Body>
          </Card>

          <div className='d-flex justify-content-end mt-3 mb-3'>
            <Button variant="success" type="submit" className="col-12 col-lg-auto d-flex align-items-center gap-1" disabled={loading}>
              <ButtonSaveContent />
            </Button>
          </div>

        </Form>
      </Container>
    </>
  );
}

export default CadastroContratos;


