import React from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { IoMdArrowRoundBack } from 'react-icons/io'

const HeaderLabel = ({ label, button = true }) => {

  const navigate = useNavigate()

  return (
    <div className='mb-3 d-flex justify-content-between'>
      {/* <h2 className='m-0 p-0' style={{ color: '#627183' }}>{label}</h2> */}
      <h2 className='m-0 p-0' style={{ color: '#2d3635' }}>{label}</h2>
      {button && <Button
        variant='outline-secondary'
        onClick={() => navigate(-1)}
        className='d-flex align-items-center gap-1'
      > <IoMdArrowRoundBack />Voltar</Button>}
    </div >
  )
}

export default HeaderLabel