import React, { useState } from 'react'
import { Form, Button, Modal } from 'react-bootstrap'
import ModalSearchPacientes from '../ModalSearch/ModalSearchPacientes'
import { IoSearch } from "react-icons/io5";
import { FaRegEye } from "react-icons/fa";
import { SweetAlert } from '../sweetalert';
import CadastroPaciente from '../../pages/cadastro_paciente'

const InputPaciente = ({ setValue, loading, setLoading, getValues, register, registerName = 'paciente', registerNameId = 'paciente_id' }) => {

  const [showCliente, setShowCliente] = useState(false);
  const [showModalCadastroPessoa, setShowModalCadastroPessoa] = useState(false);

  const handleCloseCliente = () => setShowCliente(false);
  const handleShowCliente = () => setShowCliente(true);
  const handleShowModalCadastroPessoa = () => setShowModalCadastroPessoa(true);
  const handleCloseModalCadastroPessoa = () => setShowModalCadastroPessoa(false);


  function showSelectedPaciente() {
    let paciente_id = getValues(registerNameId)

    if (paciente_id === undefined || paciente_id === '' || paciente_id === null) {
      return SweetAlert.error('Selecione um paciente antes de visualizar seus dados', 'Paciente')
    }

    const url = `/pacientes/cadastro/${paciente_id}`
    window.open(url, '_blank')
  }

  return (
    <>
      <ModalSearchPacientes
        show={showCliente}
        handleClose={handleCloseCliente}
        setValues={setValue}
        handleShowModalCadastroPessoa={handleShowModalCadastroPessoa}
        handleCloseModalCadastroPessoa={handleCloseModalCadastroPessoa}
        loading={loading}
        setLoading={setLoading}
        registerName={registerName}
        registerNameId={registerNameId}
      // setPhoto={setPhoto}
      />

      <Modal show={showModalCadastroPessoa} fullscreen centered >
        <Modal.Header closeButton onHide={handleCloseModalCadastroPessoa} />
        <Modal.Body>
          <CadastroPaciente
            origem='modal'
            handleCloseModalCadastroPessoa={handleCloseModalCadastroPessoa}
            setValueModal={setValue}
            handleCloseCliente={handleCloseCliente}
          />
        </Modal.Body>
      </Modal>

      <Form.Group className="col-12 col-lg-10">
        <Form.Label>Paciente</Form.Label>
        <div className='d-flex'>
          <Form.Control size='sm' type="text" {...register(registerName)} disabled />
          <Button
            variant='outline-secondary'
            className='p-0 px-2 d-flex align-items-center ms-1'
            onClick={handleShowCliente}
          >
            <IoSearch size={20} />
          </Button>
          <Button
            variant='outline-secondary'
            className='p-0 px-2 d-flex align-items-center ms-1'
            onClick={showSelectedPaciente}
          >
            <FaRegEye size={20} />
          </Button>

        </div>
      </Form.Group>
    </>

  )
}

export default InputPaciente