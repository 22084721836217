import React from 'react'
import { Modal, Row, Form, Button } from 'react-bootstrap'
import { IoSearch } from "react-icons/io5";
import InputTecnico from '../Inputs/InputTecnico'

const ModalAtestadosProntuario = ({ showModal, handleCloseModal, register, handleShowTecnico, setValue, showDeleteButton, handleDelete, currentIndex, salvar, loading, setLoading, getValues }) => {
  return (

    <Modal show={showModal} centered size='xl'>
      <Modal.Header closeButton onHide={handleCloseModal} />
      <Modal.Body>

        <Row className='pb-2'>
          <InputTecnico
            setValue={setValue}
            loading={loading}
            setLoading={setLoading}
            getValues={getValues}
            register={register}
            registerNameId='tecnico_atestado_id'
            registerName='tecnico_atestado'
            obrigatorio={true}
          />

        </Row>

        <Row className='mt-2'>

          <Form.Group className="col-12 col-lg-3">
            <Form.Label>Data do atendimento <span style={{ color: 'red' }}>*</span></Form.Label>
            <Form.Control size='sm' type="date" {...register('data_atestado')} />
          </Form.Group>

          <Form.Group className="col-12 col-lg-3">
            <Form.Label>Inicio <span style={{ color: 'red' }}>*</span></Form.Label>
            <Form.Control size='sm' type="time" {...register('hora_inicio_atestado')} />
          </Form.Group>

          <Form.Group className="col-12 col-lg-3">
            <Form.Label>Fim <span style={{ color: 'red' }}>*</span></Form.Label>
            <Form.Control size='sm' type="time" {...register('hora_final_atestado')} />
          </Form.Group>

          <Form.Group className="col-12 col-lg-3">
            <Form.Label>Tipo de atendimento <span style={{ color: 'red' }}>*</span></Form.Label>
            <Form.Control size='sm' type="text" placeholder='Quiroprático' {...register('tipo_atendimento')} />
          </Form.Group>
        </Row>

        <Row className='mt-2'>

          <Form.Group className="col-12 ">
            <Form.Label>Observação</Form.Label>
            <Form.Control size='sm' as="textarea" rows={3} {...register('observacao_atestado')} />
          </Form.Group>
        </Row>



      </Modal.Body>
      <Modal.Footer className={`d-flex ${showDeleteButton === true ? 'justify-content-between' : 'justify-content-end'}`}>
        {showDeleteButton === true ? <Button
          variant="outline-danger"
          // size='sm'
          onClick={() => handleDelete(currentIndex)}
        >
          Excluir
        </Button> : ''}
        <Button onClick={() => salvar()}>Salvar</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalAtestadosProntuario