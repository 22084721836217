import React from 'react'
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap'

const TabUsuario = ({ params, navigate, register, errors, criarUsuario, loadingRecuperar }) => {
  return (
    <Row className="align-items-center justify-content-center">
      {/* <Col xs={12} sm={12} md={7} lg={4}> */}
      {/* <Cards title="Recuperar senha"> */}


      <Form.Group as={Col} xs={12} sm={12} className="mb-3">
        <Form.Label>Nome</Form.Label>
        <Form.Control type="text" autocomplete="off" autoFocus
          {...register("nome", {
            required: "Nome não pode ficar em branco.",
            minLength: { value: 6, message: 'Informe no mínimo 6 caracteres.' },
            maxLength: { value: 255, message: 'Informe no máximo 255 caracteres.' },
          })}
        />
        {errors.nome && <Form.Text className="text-danger">{errors.nome.message}</Form.Text>}
      </Form.Group>

      <Form.Group as={Col} xs={12} sm={12} className="mb-3">
        <Form.Label>Usuário</Form.Label>
        <Form.Control type="text" autocomplete="off"
          {...register("username", {
            required: "Usuário não pode ficar em branco.",
            minLength: { value: 6, message: 'Informe no mínimo 6 caracteres.' },
            maxLength: { value: 255, message: 'Informe no máximo 255 caracteres.' },
          })}
        />
        {errors.username && <Form.Text className="text-danger">{errors.username.message}</Form.Text>}
      </Form.Group>

      {params.id ? '' : <Form.Group as={Col} xs={12} sm={12} className="mb-3">
        <Form.Label>Senha</Form.Label>
        <Form.Control type="password" autocomplete="off"
          {...register("password", {
            required: "Senha não pode ficar em branco.",
            minLength: { value: 6, message: 'Informe no mínimo 6 caracteres.' },
            maxLength: { value: 255, message: 'Informe no máximo 255 caracteres.' },
          })}
        />
        {errors.username && <Form.Text className="text-danger">{errors.username.message}</Form.Text>}
      </Form.Group>}

      <Form.Group as={Col} xs={12} sm={12} className="mb-3">
        <Form.Label>Email</Form.Label>
        <Form.Control type="email" autocomplete="off"
          {...register("email", {
            required: "E-mail não pode ficar em branco.",
            minLength: { value: 6, message: 'Informe no mínimo 6 caracteres.' },
            maxLength: { value: 255, message: 'Informe no máximo 255 caracteres.' },
          })}
        />
        {errors.email && <Form.Text className="text-danger">{errors.email.message}</Form.Text>}
        {params.id ? <small style={{ color: 'red' }}>* Será necessário fazer a confirmação do novo e-mail caso seja alterado!</small> : ''}
      </Form.Group>

      <Form.Group as={Col} xs={12} sm={12} className="mb-3">
        <Form.Label>Status</Form.Label>
        <Form.Select {...register('status')} size='sm'>
          <option value={true}>ATIVO</option>
          <option value={false}>INATIVO</option>
        </Form.Select>
        {errors.email && <Form.Text className="text-danger">{errors.email.message}</Form.Text>}
      </Form.Group>


      <Col xs={12} sm={12} md="auto" className="mt-4">
        <Button className='w-100' variant="success" onClick={criarUsuario} disabled={loadingRecuperar}>
          {loadingRecuperar === true ?
            <span>
              <Spinner animation="border" size="sm" /> Aguarde...{' '}
            </span>
            :
            <span>
              {params.id ? 'Editar' : 'Criar'} usuário
            </span>}
        </Button>
      </Col>
      {/* </Cards> */}
      {/* </Col> */}
    </Row>
  )
}

export default TabUsuario