import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Form, Row, Col, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { SweetAlert } from '../../components/sweetalert';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../context/auth';
import HeaderLabel from '../../components/HeaderLabel';
import Financeiro from '../../services/Financeiro';
import { addHours, format } from 'date-fns';
import Loading from '../../routers/loading';
import { isValidCPF } from '../../utilities/isValidCpf';
import { formatarTelefone } from '../../utilities/formatarTelefoneInput';
import ButtonSaveContent from '../../components/ButtonSaveContent';
import { FaX } from "react-icons/fa6";
import ModalSearchPacientes from '../../components/ModalSearch/ModalSearchPacientes';
import CadastroPaciente from '../cadastro_paciente';
import CadastroProntuarios from '../cadastro_prontuarios';
import ModalSearchProntuarios from '../../components/ModalSearch/ModalSearchProntuarios';
import { IoSearch } from "react-icons/io5";
import { FaRegEye } from "react-icons/fa";
import { permitirApenasValoresDecimaisPositivos } from '../../utilities/permitirApenasValoresDecimaisPositivos';
import { validaInteiroELimitaCampo } from '../../utilities/validaInteiroELimitaCampo';
import { calcIdade } from '../../utilities/calcIdade';
import InputPaciente from '../../components/Inputs/InputPaciente'
import InputTecnico from '../../components/Inputs/InputTecnico'


const CadastroFinanceiro = () => {
  const { handleSubmit, register, getValues, setValue, reset, watch, setFocus } = useForm();
  const { sessionCurrent } = useAuth();
  const [loading, setLoading] = useState(false);

  const [files, setFiles] = useState([]);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [photo, setPhoto] = useState(null);

  const [showCliente, setShowCliente] = useState(false);
  const [showProntuarios, setShowProntuarios] = useState(false);
  const [showModalCadastroPessoa, setShowModalCadastroPessoa] = useState(false);
  const [showModalCadastroProntuario, setShowModalCadastroProntuario] = useState(false);

  const params = useParams()
  const navigate = useNavigate()
  // const semNumero = watch('semNumero');

  useEffect(() => {

    if (params.id) {
      setLoading(true)
      Financeiro.listId(params.id)
        .then((data2) => {

          setValue('paciente_id', data2.data.paciente.id)
          setValue('paciente', data2.data.paciente.nome + calcIdade(data2.data.paciente.data_nascimento))
          setValue('tecnico_id', data2.data.tecnico.id)
          setValue('tecnico', data2.data.tecnico.nome)
          setValue('data_pagamento', data2.data.data_pagamento)
          setValue('valor_total', Number(data2.data.valor_total).toFixed(2))
          setValue('forma_pagamento', data2.data.forma_pagamento)
          setValue('observacao', data2.data.observacao)

        })
        .catch((error) => {
          console.log(error)
          SweetAlert.error(error?.response?.data?.message, 'Financeiro');
        })
        .finally(() => {
          setLoading(false)
        })

    } else {
      setValue('data_pagamento', format(new Date(), 'yyyy-MM-dd'))
    }

  }, []);


  function onSubmit(data) {


    if (data.paciente_id === '' || data.paciente_id === undefined || data.paciente_id === null) {
      return SweetAlert.error('Paciente é um campo obrigatório', 'Financeiro');
    }
    if (data.tecnico_id === '' || data.tecnico_id === undefined || data.tecnico_id === null) {
      return SweetAlert.error('Doutor é um campo obrigatório', 'Financeiro');
    }
    if (data.data_pagamento === '' || data.data_pagamento === undefined || data.data_pagamento === null) {
      return SweetAlert.error('Data de pagamento é um campo obrigatório', 'Financeiro');
    }
    if (data.valor_total === '' || data.valor_total === undefined || data.valor_total === null) {
      return SweetAlert.error('Valor total é um campo obrigatório', 'Financeiro');
    }


    setLoading(true)

    const paciente = {
      "paciente_id": data.paciente_id,
      "tecnico_id": data.tecnico_id,
      "data_pagamento": format(addHours(new Date(data.data_pagamento), 3), 'yyyy-MM-dd'),
      "valor_total": data.valor_total,
      "forma_pagamento": data.forma_pagamento,
      "observacao": data.observacao,

    }


    if (params.id) {
      Financeiro.update(params.id, paciente)
        .then((data) => {
          SweetAlert.success('Registro atualizado com sucesso', 'Financeiro')
          navigate(-1)

        })
        .catch((error) => {
          SweetAlert.error(error?.response?.data?.message, 'Financeiro');
        })
        .finally(() => { setLoading(false) })
    } else {
      Financeiro.create(paciente)
        .then((data) => {

          SweetAlert.success('Financeiro cadastrado com sucesso', 'Financeiro');
          reset();

          setValue('data_pagamento', format(new Date(), 'yyyy-MM-dd'))

          // setValue('adesao_data', format(new Date(), 'yyyy-MM-dd'))

        })
        .catch((error) => {
          console.log(error);
          SweetAlert.error(error?.response?.data?.message, 'Financeiro');
        })
        .finally(() => {
          setLoading(false);
        });

    }

  }


  return (
    <>
      {loading === true ? <Loading /> : ''}


      <Container>
        <Form onSubmit={handleSubmit(onSubmit)} className='pt-3'>

          <HeaderLabel
            label='Financeiro'
          />

          <Card className='cardShadow'>
            <Card.Body className='p-3'>

              {/* <Row className='d-flex align-items-end'>
                <Form.Group className="col-12 col-lg-auto mb-2">
                  <h4 className='textPrimary'>Geral</h4>
                </Form.Group>
              </Row> */}

              <Row className='d-flex align-items-end'>
                <InputPaciente
                  setValue={setValue}
                  loading={loading}
                  setLoading={setLoading}
                  getValues={getValues}
                  register={register}
                />
              </Row>

              <Row className='d-flex align-items-end mt-2'>
                <InputTecnico
                  setValue={setValue}
                  loading={loading}
                  setLoading={setLoading}
                  getValues={getValues}
                  register={register}
                />

              </Row>

              <Row>
                <Form.Group className="col-12 col-lg-2 mt-2">
                  <Form.Label>Data de pagamento  <span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control size='sm' type="date" {...register('data_pagamento')} />
                </Form.Group>

                <Form.Group className="col-12 col-lg-2 mt-2">
                  <Form.Label>Forma pagamento</Form.Label>
                  <Form.Select size='sm' {...register('forma_pagamento')}>
                    <option value="DINHEIRO">DINHEIRO</option>
                    <option value="PIX">PIX</option>
                    <option value="CHEQUE">CHEQUE</option>
                    <option value="BOLETO">BOLETO</option>
                    <option value="TRANSFERENCIA_BANCARIA">TRANSFERÊNCIA BANCÁRIA</option>
                    <option value="CARTAO_DEBITO">CARTÃO DE DÉBITO</option>
                    <option value="CARTAO_CREDITO">CARTÃO DE CRÉDITO</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="col-12 col-lg-2 mt-2">
                  <Form.Label>Valor total <span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control
                    size='sm'
                    type="text"
                    {...register('valor_total')}
                    onChange={(e) => permitirApenasValoresDecimaisPositivos(e, setValue, 'valor_total')}
                  />
                </Form.Group>

              </Row>

              <Form.Group className="col-12 mt-2">
                <Form.Label>Observação </Form.Label>
                <Form.Control
                  size='sm'
                  as='textarea'
                  {...register('observacao')}
                  rows={10}
                />
              </Form.Group>

            </Card.Body>
          </Card>

          <div className='d-flex justify-content-end mt-3 mb-3'>
            <Button variant="success" type="submit" className="col-12 col-lg-auto d-flex align-items-center gap-1" disabled={loading}>
              <ButtonSaveContent />
            </Button>
          </div>

        </Form>
      </Container>
    </>
  );
}

export default CadastroFinanceiro;


