import { axios } from '../config/axios';
import security from './security';

const Version = {};

Version.getVersion = () => {
  return security.validation(axios.get('/frontend'));
};
Version.updateVersion = (body) => {
  return security.validation(axios.post('/frontend', body));
};

export default Version;
