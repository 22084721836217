import React from 'react'
import { Modal, Row, Form, Button } from 'react-bootstrap'
import { IoSearch } from "react-icons/io5";
import InputTecnico from '../Inputs/InputTecnico'

const ModalAtendimentosProntuario = ({ showModal, handleCloseModal, register, handleShowTecnico, setValue, showDeleteButton, handleDelete, currentIndex, salvar, loading, setLoading, getValues }) => {
  return (

    <Modal show={showModal} centered size='xl'>
      <Modal.Header closeButton onHide={handleCloseModal} />
      <Modal.Body>


        <Row className='pb-2'>
          <InputTecnico
            setValue={setValue}
            loading={loading}
            setLoading={setLoading}
            getValues={getValues}
            register={register}
            registerNameId='tecnico_atendimento_id'
            registerName='tecnico_atendimento'
          />

          <Form.Group className="col-12 col-lg-3 mt-2">
            <Form.Label>Data do atendimento</Form.Label>
            <Form.Control size='sm' type="date" {...register('data_atendimento')} />
          </Form.Group>
        </Row>

        <Row className='pb-2'>
          <Form.Group className="col-12">
            <Form.Label>Título</Form.Label>
            <Form.Control size='sm' type="text" {...register('titulo_atendimento')} />
          </Form.Group>
        </Row>


        <Row className='pb-2'>
          <Form.Group className="col-12">
            <Form.Label>Descrição</Form.Label>
            <Form.Control size='sm' as="textarea" rows={10} {...register('descricao_atendimento')} />
          </Form.Group>
        </Row>

      </Modal.Body>
      <Modal.Footer className={`d-flex ${showDeleteButton === true ? 'justify-content-between' : 'justify-content-end'}`}>
        {showDeleteButton === true ? <Button
          variant="outline-danger"
          // size='sm'
          onClick={() => handleDelete(currentIndex)}
        >
          Excluir
        </Button> : ''}
        <Button onClick={() => salvar()}>Salvar</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalAtendimentosProntuario