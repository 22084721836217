import React, { useState } from 'react'
import { Form, Button, Modal } from 'react-bootstrap'
import ModalSearchTecnicos from '../ModalSearch/ModalSearchTecnicos'
import { IoSearch } from "react-icons/io5";
import { FaRegEye } from "react-icons/fa";
import { SweetAlert } from '../sweetalert';

const InputTecnico = ({ setValue, loading, setLoading, getValues, register, registerNameId = 'tecnico_id', registerName = 'tecnico', obrigatorio = false }) => {

  const [showTecnico, setShowTecnico] = useState(false);

  const handleCloseTecnico = () => setShowTecnico(false);
  const handleShowTecnico = () => setShowTecnico(true);


  return (
    <>
      <ModalSearchTecnicos
        show={showTecnico}
        handleClose={handleCloseTecnico}
        setValues={setValue}
        loading={loading}
        setLoading={setLoading}
        registerNameId={registerNameId}
        registerName={registerName}
      />


      <Form.Group className="col-12 col-lg-10">
        <Form.Label>Doutor {obrigatorio === true ? <span style={{ color: 'red' }}>*</span> : ''}</Form.Label>
        <div className='d-flex'>
          <Form.Control size='sm' type="text" {...register(registerName)} disabled />
          <Button
            variant='outline-secondary'
            className='p-0 px-2 d-flex align-items-center ms-1'
            onClick={handleShowTecnico}
          >
            <IoSearch size={20} />
          </Button>
          {/* <Button
            variant='outline-secondary'
            className='p-0 px-2 d-flex align-items-center ms-1'
            onClick={showSelectedTecnico}
          >
            <FaRegEye size={20} />
          </Button> */}

        </div>
      </Form.Group>
    </>

  )
}

export default InputTecnico