/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import TopTitlePage from '../../components/TopTitlePage'
import { Button, Card, Container, Form, Offcanvas, Row } from 'react-bootstrap'
import Loading from '../../components/Loading'
import { SweetAlert } from '../../components/sweetalert'

import ReactPaginate from 'react-paginate';
import './style.css'
import { useForm } from 'react-hook-form'
import { URL } from '../../config/url'
import { useNavigate } from 'react-router-dom'
import TipoPagamento from '../../services/Tipo_pagamento'
import ButtonFilterAndSelect from '../../components/ButtonFilterAndSelect'
import ButtonLimparAndFiltrar from '../../components/ButtonLimparAndFiltrar'

import TableList from '../../components/TableComponents/TableList'
import TableData from './TableData'

const ListaTipoPagamento = () => {

  const { register, getValues, handleSubmit, reset } = useForm()
  const navigate = useNavigate()

  const [tipoPagamento, setTipoPagamento] = useState([])
  const [loading, setLoading] = useState(false)

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [filter, setFilter] = useState(false);

  const [show, setShow] = useState(false);
  // const [filtros, setFiltros] = useState({
  //   razao_social: '',
  //   fantasia: '',
  //   cpf_cnpj: '',
  //   ativo: true,
  // });


  useEffect(() => {
    listAllTipoPagamento(1);
  }, []);


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function listAllTipoPagamento(pageNumber) {
    setLoading(true);

    const values = getValues()

    let newFiltro = {
      ativo: values.ativo ?? true,
    }

    TipoPagamento.listAll(pageNumber, newFiltro)
      .then((data) => {
        setTipoPagamento(data.data.data);
        setTotalPages(data.data.paging.total_pages)
        setTotalRegistros(data.data.paging.total_records)
      })
      .catch((error) => {
        SweetAlert.error(error.response.data.message, 'Tipo de pagamento');
      })
      .finally(() => {
        // setFiltros(newFiltro)
        setLoading(false)
      });
  }

  const handlePageChange = (selected) => {
    setPage(selected.selected + 1)
    listAllTipoPagamento(selected.selected + 1);
  };

  function onFilter() {
    setFilter(true)
    setShow(false)
    listAllTipoPagamento(1)
  }

  function deleteTipoPagamento(id) {
    setLoading(true)
    TipoPagamento.delete(id)
      .then((data) => {
        // SweetAlert.success('Categoria deletada com sucesso!', 'Categorias');
        listAllTipoPagamento()
      })
      .catch((error) => {
        SweetAlert.error(error.response.data.message, 'Tipo de pagamento');
      })
      .finally(() => setLoading(false))
  }

  function deleteCategoryAlert(id) {
    SweetAlert.question('Atenção', 'Deletar tipo de pagamento?', 'SIM!', () => deleteTipoPagamento(id), '#FF0000')
  }

  function editCategory(id) {
    navigate(URL.tipo_pagamento_cadastro + '/' + id)
  }

  return (
    <>
      {loading === true ? <Loading /> : ''}

      <Offcanvas show={show} onHide={handleClose} style={{ height: '100vh', backgroundColor: '#f1f1f1' }} placement='bottom'>
        <Form onSubmit={handleSubmit(onFilter)}>
          <Container>
            <Offcanvas.Header closeButton>
              <TopTitlePage title='Filtro' />
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Card className='cardShadow'>
                <Card.Body className='p-3'>
                  <Row className='d-flex align-items-end'>
                    <Form.Group className="col-12 col-md-2">
                      <Form.Label>Situação</Form.Label>
                      <Form.Select {...register('ativo')}>
                        <option value={true}>ATIVO</option>
                        <option value={false}>INATIVO</option>
                        <option value=''>TODOS</option>
                      </Form.Select>
                    </Form.Group>
                  </Row>

                </Card.Body>
              </Card>
              <div className='d-flex justify-content-end mt-3 gap-3'>
                <ButtonLimparAndFiltrar
                  handleClose={handleClose}
                  reset={reset}
                  listAll={listAllTipoPagamento}
                  setFilter={setFilter}
                />
              </div>
            </Offcanvas.Body>
          </Container>
        </Form>
      </Offcanvas>

      <div>
        <TopTitlePage title='Tipos de pagamento' />

        <div className='py-3 d-flex justify-content-between px-3 align-items-end gap-2 flex-column flex-md-row'>

          <ButtonFilterAndSelect
            handleShow={handleShow}
            listAll={listAllTipoPagamento}
            filter={filter}
          />

          <div className='d-flex align-items-end gap-3 col-12 col-md-auto'>
            <Button
              variant='success'
              onClick={() => navigate(URL.tipo_pagamento_cadastro)}
              className='col-12 col-md-auto'
            >+ Novo tipo de pagamento</Button>
          </div>
        </div>

        <Card className='mx-3 cardShadow mb-2 lg-mb-0'>
          <Card.Body className='p-0'>

            {tipoPagamento.length === 0 ?
              <div className='d-flex justify-content-center py-2'>
                <h4>Sem registros</h4>
              </div>
              :
              <>
                <TableList>
                  <TableData
                    array={tipoPagamento}
                    editFunction={editCategory}
                    deleteFunction={deleteCategoryAlert}
                  />
                </TableList>

                <div className='mt-4 d-flex justify-content-between px-3 flex-column flex-md-row gap-3'>
                  <span> Total: {totalRegistros}</span>
                  <ReactPaginate
                    pageCount={totalPages}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={1}
                    onPageChange={handlePageChange}
                    containerClassName={'pagination_container'}
                    activeClassName={'active'}
                    previousLabel={'<'}
                    nextLabel={'>'}
                  />
                </div>
              </>
            }

          </Card.Body>
        </Card>


      </div>
    </>
  )
}

export default ListaTipoPagamento