import React from 'react';
import { Form, Row } from 'react-bootstrap';

const InputSearchName = ({ register, listAll, listFilterName }) => {

  function handleBlur(e) {
    if (e.target.value === '') {
      listAll();
    }
  }

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (e.target.value === '') {
        listAll();
      } else {
        listFilterName();
      }

    }
  }

  return (
    <Row className='d-flex align-items-end' onBlur={(e) => handleBlur(e)}>
      <Form.Group className="col-12">
        <Form.Label>Nome do paciente</Form.Label>
        <Form.Control
          size='sm'
          type="text"
          {...register('nome_input')}
          onKeyDown={handleKeyDown} // Adiciona o evento onKeyDown
        />
      </Form.Group>
    </Row>
  );
}

export default InputSearchName;
