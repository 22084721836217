export function formatarTelefone(telefone) {
  // Remover todos os caracteres não numéricos
  const telefoneNumerico = telefone.replace(/\D/g, '');

  // Verificar se o telefone possui apenas 10 dígitos
  if (telefoneNumerico.length === 10) {
    // Formatar como (XX) XXXX-XXXX
    return telefoneNumerico.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
  } else if (telefoneNumerico.length === 11) {
    // Formatar como (XX) XXXXX-XXXX
    return telefoneNumerico.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  } else {
    // Retornar o telefone sem formatação
    return telefone;
  }
}