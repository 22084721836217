export const isValidCPF = (cpf) => {
  // Remove characters that are not digits
  const cleanedCPF = cpf.replace(/\D/g, '');

  // // Check if the CPF has 11 digits
  // if (cleanedCPF.length !== 11) {
  //   return false;
  // }

  // Check if all digits are the same
  if (/^(\d)\1{10}$/.test(cleanedCPF)) {
    return false;
  }

  // Calculate the first and second verification digits
  let sum = 0;
  for (let i = 1; i <= 9; i++) {
    sum += parseInt(cleanedCPF.charAt(i - 1)) * (11 - i);
  }
  let verificationDigit1 = (sum % 11) < 2 ? 0 : 11 - (sum % 11);

  sum = 0;
  for (let i = 1; i <= 10; i++) {
    sum += parseInt(cleanedCPF.charAt(i - 1)) * (12 - i);
  }
  let verificationDigit2 = (sum % 11) < 2 ? 0 : 11 - (sum % 11);

  // Check if verification digits match
  if (
    parseInt(cleanedCPF.charAt(9)) !== verificationDigit1 ||
    parseInt(cleanedCPF.charAt(10)) !== verificationDigit2
  ) {
    return false;
  }

  // If all checks pass, the CPF is valid
  return true;
};