// export function permitirApenasValoresDecimaisPositivos(event) {
//   let inputValue = event.target.value;
//   inputValue = inputValue.replace(',', '.'); // Remove todos os caracteres, exceto números, ponto (.) e vírgula (,)
//   inputValue = inputValue.replace(/[^0-9.,]/g, ''); // Remove todos os caracteres, exceto números, ponto (.) e vírgula (,)

//   const decimalParts = inputValue.split('.');
//   if (decimalParts.length > 1) {
//     inputValue = decimalParts[0] + '.' + decimalParts[1].slice(0, 2); // Limita a duas casas decimais
//   }

//   event.target.value = inputValue;
// }

export function permitirApenasValoresDecimaisPositivos(event, setValue = '', value = '') {
  let inputValue = event.target.value;
  inputValue = inputValue.replace(',', '.'); // Remove todos os caracteres, exceto números, ponto (.) e vírgula (,)
  inputValue = inputValue.replace(/[^0-9.]/g, ''); // Remove todos os caracteres, exceto números e ponto (.)

  // Limita a quantidade de caracteres para 10
  inputValue = inputValue.slice(0, 10);

  const decimalParts = inputValue.split('.');
  if (decimalParts.length > 1) {
    inputValue = decimalParts[0] + '.' + decimalParts[1].slice(0, 2); // Limita a duas casas decimais
  }

  event.target.value = inputValue;
  if (setValue !== '' && value !== '') {
    setValue(value, inputValue)
  }
}

