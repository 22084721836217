import { axios } from '../config/axios';
import security from './security'

const Tecnicos = {};

Tecnicos.create = (body) => {
  return security.validation(axios.post('/tecnicos', body));
};

Tecnicos.listAllTecnicos = (page = '', filtros = '') => {

  let filtro = ''

  if (filtros !== '') {
    filtro = '&nome=' + (filtros.nome === undefined ? '' : filtros.nome?.toUpperCase())
  }

  return security.validation(axios.get('/tecnicos?orderby=codigo&page_size=10&page=' + page + filtro));
};

Tecnicos.listAllTecnicosAtivos = (page = '', filtros = '') => {

  let filtro = ''

  if (filtros !== '') {
    filtro = '&nome=' + (filtros.nome === undefined ? '' : filtros.nome?.toUpperCase())
    filtro = filtro + '&email=' + (filtros.email === undefined ? '' : filtros.email.toUpperCase())
    filtro = filtro + '&cpf=' + (filtros.cpf === undefined ? '' : filtros.cpf)
    filtro = filtro + '&ativo=' + (filtros.ativo === undefined ? '' : filtros.ativo)
  }

  return security.validation(axios.get('/tecnicos?ativo=true&orderby=codigo&page_size=10&page=' + page + filtro));
};

Tecnicos.listPessoaById = (id) => {
  return security.validation(axios.get('/tecnicos/' + id));
};

Tecnicos.createPhoto = (id, newPhoto) => {
  return security.validation(axios.patch('/tecnicos/' + id + '/foto', newPhoto,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }));
};

Tecnicos.deletePhoto = (id) => {
  return security.validation(axios.delete('/tecnicos/' + id + '/foto'));
};

Tecnicos.listId = (id) => {
  return security.validation(axios.get('/tecnicos/' + id));
};

Tecnicos.update = (id, body) => {
  return security.validation(axios.patch('/tecnicos/' + id, body));
};

Tecnicos.delete = (id) => {
  return security.validation(axios.delete('/tecnicos/' + id));
};

Tecnicos.createPhoto = (id, newPhoto) => {
  return security.validation(axios.patch('/tecnicos/' + id + '/assinatura', newPhoto,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }));
};

Tecnicos.deletePhoto = (id) => {
  return security.validation(axios.delete('/tecnicos/' + id + '/assinatura'));
};


export default Tecnicos;
