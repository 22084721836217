import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Form, Row, Tab, Nav, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { SweetAlert } from '../../components/sweetalert';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../context/auth';
import { URL } from '../../config/url';
import { LoadingSkeleton } from '../../components/LoadingSkeleton';
import HeaderLabel from '../../components/HeaderLabel';
import Status from '../../services/Status';
import ButtonSaveContent from '../../components/ButtonSaveContent';
import InputUsuario from '../../components/Inputs/InputUsuario';
import ImagemIcone from '../imagemIcone';
import { validaInteiroELimitaCampo } from '../../utilities/validaInteiroELimitaCampo';

const CadastroStatus = () => {
  const { handleSubmit, register, setFocus, setValue, reset, getValues } = useForm();
  const { user, sessionCurrent } = useAuth();
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState('geral');
  const [photo, setPhoto] = useState(null);

  const params = useParams()
  const navigate = useNavigate()

  useEffect(() => {

    setFocus('nomes')

    if (params.id) {
      setLoading(true)
      Status.listId(params.id)
        .then((data) => {
          setValue('nome', data.data.nome);
          setValue('cor', data.data.cor);
          setValue('ativo', data.data.mostrar_totalizador);
          setValue('sequencia', data.data.sequencia);


          if (data.data.icon_url !== '') {
            setPhoto(data.data.icon_url)
          }

        })
        .catch((error) => {
          SweetAlert.error(error.response.data.message, 'Doutores');
        })
        .finally(() => { setLoading(false) })

    }
  }, []);


  function onSubmit(data) {

    if (data.nome === '' || data.nome === undefined || data.nome === null) {
      return SweetAlert.error('Nome é um campo obrigatório', 'Status');
    }
    if (data.sequencia === '' || data.sequencia === undefined || data.sequencia === null) {
      return SweetAlert.error('Sequência é um campo obrigatório', 'Status');
    }

    setLoading(true)

    const status = {
      "nome": data.nome,
      "mostrar_totalizador": data.ativo,
      "cor": data.cor,
      "sequencia": data.sequencia,
    }

    if (params.id) {
      Status.update(params.id, status)
        .then((data) => {

          if (photo) {

            if (typeof photo === 'string') {
              SweetAlert.success('Registro atualizado com sucesso', 'Status')
              navigate(URL.status_lista)
              return
            }

            let formData = new FormData();
            formData.append('photo', photo); // Use uma chave descritiva para a imagem

            Status.createPhoto(data.data.id, formData)
              .then(() => {
                SweetAlert.success('Registro atualizado com sucesso', 'Status')
                navigate(URL.status_lista)
              })
              .catch((error) => {
                SweetAlert.error(error?.response?.data?.message, 'Status');
              });
          } else {
            Status.deletePhoto(data.data.id)
              .then(() => {

                SweetAlert.success('Registro atualizado com sucesso', 'Status')
                navigate(URL.status_lista)
              })
              .catch((error) => {
                SweetAlert.error(error?.response?.data?.message, 'Status');
              });
          }

        })
        .catch((error) => {
          SweetAlert.error(error.response.data.message, 'Status');
        })
        .finally(() => { setLoading(false) })
    } else {
      Status.create(status)
        .then((data) => {

          if (photo) {
            let formData = new FormData();
            formData.append('photo', photo); // Use uma chave descritiva para a imagem

            Status.createPhoto(data.data.id, formData)
              .then(() => {
                SweetAlert.success('Status cadastrado com sucesso', 'Status');
                reset();
                setPhoto(null)
              })
              .catch((error) => {
                SweetAlert.error(error?.response?.data?.message, 'Status');
              });
          } else {
            SweetAlert.success('Status cadastrado com sucesso', 'Status');
            reset();

          }

        })
        .catch((error) => {
          SweetAlert.error(error.response.data.message, 'Status');
        })
        .finally(() => {
          setLoading(false)
          setTimeout(() => {
            setFocus('nome')
            setKey('geral')
          }, 10);
        })
    }

  }

  return (
    <>
      {/* {loading === true ? <Loading /> : ''} */}
      <Container>
        <Form onSubmit={handleSubmit(onSubmit)} className='pt-3'>
          <HeaderLabel
            label='Status'
          />

          <Tab.Container id="left-tabs-example" activeKey={key} onSelect={(k) => setKey(k)}>
            <Nav justify variant="tabs" defaultActiveKey="geral">
              <Nav.Item>
                <Nav.Link eventKey="geral">Geral</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="assinatura">Ícone</Nav.Link>
              </Nav.Item>

            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="geral">
                <Card className='cardShadow'>
                  <Card.Body className='p-3'>
                    <Row className='d-flex align-items-end'>
                      <Form.Group className="col-12">
                        <Form.Label>Nome <span style={{ color: 'red' }}>*</span></Form.Label>
                        {loading ? <LoadingSkeleton /> : <Form.Control size='sm' type='text' {...register('nome')} />}
                      </Form.Group>
                      <Form.Group className="col-12 col-lg-3 mt-2">
                        <Form.Label>Mostrar totalizador</Form.Label>
                        {loading ? <LoadingSkeleton /> : <Form.Select {...register('ativo')} size='sm'>
                          <option value={true}>ATIVO</option>
                          <option value={false}>INATIVO</option>
                        </Form.Select>}
                      </Form.Group>
                    </Row>
                    <Form.Group className="col-12 col-lg-3 mt-2">
                      <Form.Label>Sequência <span style={{ color: 'red' }}>*</span></Form.Label>

                      {loading ? <LoadingSkeleton /> :
                        <Form.Control
                          size='sm'
                          type="text"
                          {...register('sequencia')}
                          onChange={(e) => validaInteiroELimitaCampo(e.target.value, 3, 'sequencia', setValue)}
                        />}

                    </Form.Group>
                    <Row className='d-flex align-items-end mt-2'>
                      <Form.Group className="col-12 col-lg-3">
                        <Form.Label>Cor </Form.Label>
                        {loading ? <LoadingSkeleton /> : <Form.Control size='sm' type='color' {...register('cor')} />}
                      </Form.Group>

                    </Row>

                  </Card.Body>
                </Card>
              </Tab.Pane>

              <Tab.Pane eventKey="assinatura">
                <Card className='cardShadow'>
                  <Card.Body className='p-3'>
                    <ImagemIcone
                      photo={photo}
                      setPhoto={setPhoto}
                    />
                  </Card.Body>
                </Card>
              </Tab.Pane>

            </Tab.Content>
          </Tab.Container>



          <div className='d-flex justify-content-end mt-3 mb-3'>
            <Button variant="success" type="submit" className="col-12 col-lg-auto d-flex align-items-center gap-1" disabled={loading}>
              <ButtonSaveContent />
            </Button>
          </div>

        </Form>
      </Container>
    </>
  );
}

export default CadastroStatus;
