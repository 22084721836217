import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { SweetAlert } from '../../components/sweetalert';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../context/auth';
import { LoadingSkeleton } from '../../components/LoadingSkeleton';
import HeaderLabel from '../../components/HeaderLabel';
import { validaInteiroELimitaCampo } from '../../utilities/validaInteiroELimitaCampo';
import Version from '../../services/Version';
import ButtonSaveContent from '../../components/ButtonSaveContent';
import metadata from '../../../package.json'

const ControleVersao = () => {
  const { handleSubmit, register, setFocus, setValue, reset } = useForm();
  const { sessionCurrent } = useAuth();
  const [loading, setLoading] = useState(false);
  const [newVersion, setNewVersion] = useState(null);

  const params = useParams()
  const navigate = useNavigate()

  useEffect(() => {

    Version.getVersion()
      .then((data) => {
        setValue('version_database', data.data.version)
      })
      .catch((error) => {
        SweetAlert.error(error?.response?.data?.message, 'Versão')
      })

    setValue('version', metadata.version)

  }, []);

  function onSubmit(data) {

    setLoading(true)

    const version = {
      "version": data.version,
    }

    Version.updateVersion(version)
      .then((data) => {

        reset()
        SweetAlert.success('Versão atualizada com sucesso', 'Versão')

      })
      .catch((error) => {
        console.log(error)
        SweetAlert.error(error.response.data.message, 'Banco');
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      {/* {loading === true ? <Loading /> : ''} */}
      <Container>
        <Form onSubmit={handleSubmit(onSubmit)} className='pt-3'>
          <HeaderLabel
            label='Controle de versão'
            button={false}
          />

          <Card className='cardShadow'>
            <Card.Body className='p-3'>
              <Row className='d-flex align-items-end'>
                <Form.Group className="col-12 col-lg-3">
                  <Form.Label>Versão Banco <span style={{ color: 'red' }}>*</span></Form.Label>
                  {loading ? <LoadingSkeleton /> : <Form.Control size='sm' type="text" disabled {...register('version_database')} />}
                </Form.Group>
              </Row>

              <Row className='d-flex align-items-end mt-3'>
                <Form.Group className="col-12 col-lg-3">
                  <Form.Label>Nova versão <span style={{ color: 'red' }}>*</span></Form.Label>
                  {loading ? <LoadingSkeleton /> : <Form.Control size='sm' type="text" disabled {...register('version')} />}
                </Form.Group>
              </Row>

            </Card.Body>
          </Card>

          <div className='d-flex justify-content-end mt-3 mb-3'>
            <Button variant="success" type="submit" className="col-12 col-lg-auto d-flex align-items-center gap-1" disabled={loading}>
              <ButtonSaveContent />
            </Button>
          </div>

        </Form>
      </Container>
    </>
  );
}

export default ControleVersao;
