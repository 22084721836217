import { axios } from '../config/axios';
import security from './security'

const Prontuarios = {};

Prontuarios.create = (body) => {
  return security.validation(axios.post('/prontuarios', body));
};

Prontuarios.listAll = (page = '', filtros = '', origem = '') => {

  let filtro = ''

  if (filtros !== '') {
    filtro = filtro + '&data_cadastro_inicial=' + (filtros.data_inicial === undefined ? '' : filtros.data_inicial)
    filtro = filtro + '&data_cadastro_final=' + (filtros.data_final === undefined ? '' : filtros.data_final)
    filtro = filtro + '&titulo=' + (filtros.titulo === undefined ? '' : filtros.titulo)
    filtro = filtro + '&paciente_id=' + (filtros.paciente === undefined ? '' : filtros.paciente)
    filtro = filtro + '&tecnico_id=' + (filtros.tecnico === undefined ? '' : filtros.tecnico)
    filtro = filtro + '&codigo=' + (filtros.codigo === undefined ? '' : filtros.codigo)
    filtro = filtro + '&tecnico_responsavel_nome=' + (filtros['tecnico_responsavel.nome'] === undefined ? '' : filtros['tecnico_responsavel.nome'])
    filtro = filtro + '&tecnico_responsavel_id=' + (filtros.tecnico === undefined ? '' : filtros.tecnico)
    if (origem === 'modal') {
      filtro = filtro + '&paciente_nome=' + (filtros['paciente.nome'] === undefined ? '' : filtros['paciente.nome'])
    } else {
      filtro = filtro + '&paciente_nome=' + (filtros.paciente_nome === undefined ? '' : filtros.paciente_nome)
    }
  }

  return security.validation(axios.get('/prontuarios?page_size=300&page=' + page + filtro));
};

Prontuarios.listAllAtivos = (page = '', filtros = '') => {

  // let filtro = ''

  // if (filtros !== '') {
  //   filtro = '&nome=' + (filtros.nome === undefined ? '' : filtros.nome?.toUpperCase())
  //   filtro = filtro + '&email=' + (filtros.email === undefined ? '' : filtros.email.toUpperCase())
  //   filtro = filtro + '&cpf=' + (filtros.cpf === undefined ? '' : filtros.cpf)
  //   filtro = filtro + '&ativo=' + (filtros.ativo === undefined ? '' : filtros.ativo)
  // }

  return security.validation(axios.get('/prontuarios?ativo=true&orderby=codigo&page_size=10&page=' + page));
};

Prontuarios.listPessoaById = (id) => {
  return security.validation(axios.get('/clientes/' + id));
};

Prontuarios.createPhoto = (id, newPhoto) => {
  return security.validation(axios.patch('/prontuarios/' + id + '/anexos', newPhoto,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }));
};

Prontuarios.deletePhoto = (id, anexoId) => {
  return security.validation(axios.delete('/prontuarios/' + id + '/anexos/' + anexoId));
};

Prontuarios.listId = (id) => {
  return security.validation(axios.get('/prontuarios/' + id));
};

Prontuarios.update = (id, body) => {
  return security.validation(axios.patch('/prontuarios/' + id, body));
};

Prontuarios.delete = (id) => {
  return security.validation(axios.delete('/prontuarios/' + id));
};

Prontuarios.imagem = (id, newPhoto) => {
  return security.validation(axios.patch('/prontuarios/' + id + '/imagem', newPhoto,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }));
};
Prontuarios.imagemAtendimento = (id, newPhoto) => {
  return security.validation(axios.patch('/prontuarios/' + id + '/imagem-atendimento', newPhoto,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }));
};

Prontuarios.pdfExame = (id, idExame) => {
  return security.validation(axios.get('/prontuarios/' + id + '/exame/' + idExame + '/report', { responseType: 'blob' }));
};
Prontuarios.pdfAtestado = (id, idAtestado) => {
  return security.validation(axios.get('/prontuarios/' + id + '/atestado/' + idAtestado + '/report', { responseType: 'blob' }));
};

export default Prontuarios;
