import { Routes, Route, useLocation, Navigate } from 'react-router-dom';

import { URL, SUBHOST } from '../config/url';
import { useAuth } from '../context/auth';

import NotFound from './notfound';

import Login from '../pages/login';
import Home from '../pages/home';

import Perfil from '../pages/perfil';
import RecuperarSenha from '../pages/recuperar_senha';
import RecuperarSenhaConfirmar from '../pages/recuperar_senha_confirmar';

import ListaPacientes from '../pages/lista_pacientes';

import CriarUsuario from '../pages/criar_usuario';
import CriarUsuarioConfirmar from '../pages/criar_usuario_confirmar';

import ListaUsuarios from '../pages/lista_usuarios';
import UsuariosCadastro from '../pages/cadastro_usuarios';
import AtualizarUsuarioConfirmar from '../pages/confirmar_alterar_email';

import Agenda from '../pages/agenda';
import CadastroPaciente from '../pages/cadastro_paciente';
import ListaPerguntas from '../pages/lista_perguntas';
import CadastroPergunta from '../pages/cadastro_perguntas';
import Imagem from '../pages/teste_imagem';

import ListaTipoPagamento from '../pages/lista_tipo_pagamento';
import CadastroTipoPagamento from '../pages/cadastro_tipo_pagamentos';
import ListaTipoAtendimento from '../pages/lista_tipo_atendimento';
import CadastroTipoAtendimento from '../pages/cadastro_tipo_atendimento';
import ListaProntuarios from '../pages/lista_prontuarios';
import CadastroProntuarios from '../pages/cadastro_prontuarios';
import CameraComponent from '../pages/teste_camera';
import ListaTecnicos from '../pages/lista_tecnicos';
import CadastroTecnicos from '../pages/cadastro_tecnicos';
import ListaContratos from '../pages/lista_contratos';
import CadastroContratos from '../pages/cadastro_contratos';

import ControleVersao from '../pages/controle_versao';
import ListaFinanceiros from '../pages/lista_financeiro';
import CadastroFinanceiro from '../pages/cadastro_financeiros';
import ListaStatus from '../pages/lista_status';
import CadastroStatus from '../pages/cadastro_status';

function Router() {

  function RequireAuth({ children }) {
    const location = useLocation();
    const { isConnected } = useAuth();

    if (!isConnected) return <Navigate to={URL.login} state={{ from: location }} replace />;

    return children;
  }

  function LoginAuth({ children }) {
    const location = useLocation();
    const { isConnected } = useAuth();

    if (isConnected) return <Navigate to={URL.index} state={{ from: location }} replace />;

    return children;
  }

  return (
    <Routes>
      {SUBHOST && <Route path={'/'} element={<Navigate to={URL.index} state={{ from: '/' }} replace />} />}
      <Route path={URL.login} element={<LoginAuth><Login /></LoginAuth>} />
      {/* <Route path={URL.login_recuperar_senha} element={<LoginAuth><RecuperarSenha /></LoginAuth>} /> */}
      <Route path={URL.login_recuperar_senha} element={<RecuperarSenha />} />
      {/* <Route path={URL.login_recuperar_senha_confirmar + '/:hash'} element={<LoginAuth><RecuperarSenhaConfirmar /></LoginAuth>} /> */}
      <Route path={URL.login_recuperar_senha_confirmar + '/:hash'} element={<RecuperarSenhaConfirmar />} />
      <Route path={URL.login_criar_usuario} element={<LoginAuth><CriarUsuario /></LoginAuth>} />
      <Route path={URL.login_confirmar_usuario + '/:hash'} element={<LoginAuth><CriarUsuarioConfirmar /></LoginAuth>} />

      {/* <Route path={URL.index} element={<RequireAuth><Home /></RequireAuth>} /> */}
      {/* <Route path={URL.home} element={<RequireAuth><Home /></RequireAuth>} /> */}
      <Route path={URL.perfil} element={<RequireAuth><Perfil /></RequireAuth>} />
      <Route path={URL.perfil_confirmar_email + '/:hash'} element={<RequireAuth><AtualizarUsuarioConfirmar /></RequireAuth>} />

      <Route path={URL.pacientes_lista} element={<RequireAuth><ListaPacientes /></RequireAuth>} />
      <Route path={URL.pacientes_cadastro} element={<RequireAuth><CadastroPaciente /></RequireAuth>} />
      <Route path={URL.pacientes_cadastro + '/:id'} element={<RequireAuth><CadastroPaciente /></RequireAuth>} />

      <Route path={URL.perguntas_lista} element={<RequireAuth><ListaPerguntas /></RequireAuth>} />
      <Route path={URL.perguntas_cadastro} element={<RequireAuth><CadastroPergunta /></RequireAuth>} />
      <Route path={URL.perguntas_cadastro + '/:id'} element={<RequireAuth><CadastroPergunta /></RequireAuth>} />

      <Route path={URL.usuarios_lista} element={<RequireAuth><ListaUsuarios /></RequireAuth>} />
      <Route path={URL.usuarios_cadastro} element={<RequireAuth><UsuariosCadastro /></RequireAuth>} />
      <Route path={URL.usuarios_cadastro + '/:id'} element={<RequireAuth><UsuariosCadastro /></RequireAuth>} />

      <Route path={URL.calendario} element={<RequireAuth><Agenda /></RequireAuth>} />
      <Route path={URL.home} element={<RequireAuth><Agenda /></RequireAuth>} />
      <Route path={URL.index} element={<RequireAuth><Agenda /></RequireAuth>} />

      <Route path={URL.imagem} element={<RequireAuth><Imagem /></RequireAuth>} />

      <Route path={URL.camera} element={<RequireAuth><CameraComponent /></RequireAuth>} />

      <Route path={URL.tipo_pagamento_lista} element={<RequireAuth><ListaTipoPagamento /></RequireAuth>} />
      <Route path={URL.tipo_pagamento_cadastro} element={<RequireAuth><CadastroTipoPagamento /></RequireAuth>} />
      <Route path={URL.tipo_pagamento_cadastro + '/:id'} element={<RequireAuth><CadastroTipoPagamento /></RequireAuth>} />

      <Route path={URL.tipo_atendimento_lista} element={<RequireAuth><ListaTipoAtendimento /></RequireAuth>} />
      <Route path={URL.tipo_atendimento_cadastro} element={<RequireAuth><CadastroTipoAtendimento /></RequireAuth>} />
      <Route path={URL.tipo_atendimento_cadastro + '/:id'} element={<RequireAuth><CadastroTipoAtendimento /></RequireAuth>} />

      <Route path={URL.prontuario_lista} element={<RequireAuth><ListaProntuarios /></RequireAuth>} />
      <Route path={URL.prontuario_cadastro} element={<RequireAuth><CadastroProntuarios /></RequireAuth>} />
      <Route path={URL.prontuario_cadastro + '/:id'} element={<RequireAuth><CadastroProntuarios /></RequireAuth>} />

      <Route path={URL.tecnico_lista} element={<RequireAuth><ListaTecnicos /></RequireAuth>} />
      <Route path={URL.tecnico_cadastro} element={<RequireAuth><CadastroTecnicos /></RequireAuth>} />
      <Route path={URL.tecnico_cadastro + '/:id'} element={<RequireAuth><CadastroTecnicos /></RequireAuth>} />

      <Route path={URL.contrato_lista} element={<RequireAuth><ListaContratos /></RequireAuth>} />
      <Route path={URL.contrato_cadastro} element={<RequireAuth><CadastroContratos /></RequireAuth>} />
      <Route path={URL.contrato_cadastro + '/:id'} element={<RequireAuth><CadastroContratos /></RequireAuth>} />

      <Route path={URL.financeiro_lista} element={<RequireAuth><ListaFinanceiros /></RequireAuth>} />
      <Route path={URL.financeiro_cadastro} element={<RequireAuth><CadastroFinanceiro /></RequireAuth>} />
      <Route path={URL.financeiro_cadastro + '/:id'} element={<RequireAuth><CadastroFinanceiro /></RequireAuth>} />

      <Route path={URL.status_lista} element={<RequireAuth><ListaStatus /></RequireAuth>} />
      <Route path={URL.status_cadastro} element={<RequireAuth><CadastroStatus /></RequireAuth>} />
      <Route path={URL.status_cadastro + '/:id'} element={<RequireAuth><CadastroStatus /></RequireAuth>} />

      <Route path={URL.versao_cadastro} element={<RequireAuth><ControleVersao /></RequireAuth>} />


      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default Router;
