import React, { useState } from 'react'
import { Form, Button, Modal } from 'react-bootstrap'
import ModalSearchTecnicos from '../ModalSearch/ModalSearchTecnicos'
import { IoSearch } from "react-icons/io5";
import { FaRegEye, FaTrashAlt } from "react-icons/fa";
import { SweetAlert } from '../sweetalert';
import ModalSearchUsuarios from '../ModalSearch/ModalSearchUsuarios';

const InputUsuario = ({ setValue, loading, setLoading, getValues, register, registerNameId = 'usuario_id', registerName = 'usuario', origem = '' }) => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  function clearInput() {
    setValue(registerNameId, '')
    setValue(registerName, '')

  }

  return (
    <>
      <ModalSearchUsuarios
        show={show}
        handleClose={handleClose}
        setValues={setValue}
        loading={loading}
        setLoading={setLoading}
        registerNameId={registerNameId}
        registerName={registerName}
      />


      <Form.Group className="col-12 col-lg-10">
        <Form.Label>Usuários</Form.Label>
        <div className='d-flex'>
          <Form.Control size='sm' type="text" {...register(registerName)} disabled />
          <Button
            variant='outline-secondary'
            className='p-0 px-2 d-flex align-items-center ms-1'
            onClick={handleShow}
          >
            <IoSearch size={20} />
          </Button>
          {origem === 'cadastro' ?
            <Button
              variant='outline-secondary'
              className='p-0 px-2 d-flex align-items-center ms-1'
              onClick={clearInput}
            >
              <FaTrashAlt size={20} />
            </Button> : ''}


        </div>
      </Form.Group>
    </>

  )
}

export default InputUsuario