import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../context/auth';
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
// import { FiCheck } from 'react-icons/fi';
import SessionsServices from '../../services/sessions'
import { SweetAlert } from '../../components/sweetalert';
import HeaderLabel from '../../components/HeaderLabel';
import { useNavigate } from 'react-router-dom'
import { IoMdArrowRoundBack } from 'react-icons/io';
import './style.css'

function RecuperarSenha() {
  const [loadingRecuperar, setLoadingRecuperar] = useState(false)
  const [inputEmailDisabled, setInputEmailDisabled] = useState(false)
  const { user } = useAuth()
  const navigate = useNavigate()
  const { register, getValues, setValue, formState: { errors }, } = useForm();

  useEffect(() => {
    if (user?.email) {
      setValue('email', user.email)
      setInputEmailDisabled(true)
    }

    // eslint-disable-next-line
  }, [])

  async function handlerRecuperarSenha() {
    try {
      const email = getValues('email')

      if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        return SweetAlert.error('Informe um email válido', 'Login');
      }

      const body = { ...(email.includes('@') ? { email } : { username: email }) }

      setLoadingRecuperar(true)
      await SessionsServices.login_recuperar_senha(body)
      SweetAlert.success('Verifique o seu e-mail!', 'Recuperar Senha')
      setLoadingRecuperar(false)
    } catch (error) {
      setLoadingRecuperar(false)
      SweetAlert.error(error.response.data.message, 'Recuperar Senha');
    }
  }

  return (
    <>
      <div className='recuperar-senha-background'>
        <div class="custom-shape-divider-top-1713470869">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
            <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
            <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
          </svg>
        </div>
        <div className='recuperar_senha_card'>
          <Row className="align-items-center justify-content-center pt-2">
            <Col xs={12} sm={12} md={12} lg={12}>
              <div className='d-flex justify-content-between align-items-center mb-4'>
                <h4>Recuperar senha</h4>
                <Button variant='outline-secondary' onClick={() => navigate(-1)} className='d-flex align-items-center gap-1'> <IoMdArrowRoundBack />Voltar</Button>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center pt-2">
            <Col xs={12} sm={12} md={7} lg={12}>
              <Form.Group as={Col} xs={12} sm={12} className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="" disabled={inputEmailDisabled} autoFocus
                  {...register("email", {
                    required: "E-mail não pode ficar em branco.",
                    minLength: { value: 6, message: 'Informe no mínimo 6 caracteres.' },
                    maxLength: { value: 255, message: 'Informe no máximo 255 caracteres.' },
                  })}
                />
                {errors.email && <Form.Text className="text-danger">{errors.email.message}</Form.Text>}
              </Form.Group>

              <Col xs={12} sm={12} md="auto" className="mt-2">
                <Button className='w-100' variant="success" onClick={handlerRecuperarSenha} disabled={loadingRecuperar}>
                  {loadingRecuperar === true ?
                    <span>
                      <Spinner animation="border" size="sm" /> Aguarde...{' '}
                    </span>
                    :
                    <span>
                      Solicitar recuperação
                    </span>}
                </Button>
              </Col>
            </Col>
          </Row>
        </div>
      </div >
      {/* </Container> */}
    </>
  )
}

export default React.memo(RecuperarSenha)