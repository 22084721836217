import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Form, Row, Tab, Nav, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { SweetAlert } from '../../components/sweetalert';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../context/auth';
import { URL } from '../../config/url';
import { LoadingSkeleton } from '../../components/LoadingSkeleton';
import HeaderLabel from '../../components/HeaderLabel';
import Tecnicos from '../../services/Tecnicos';
import ButtonSaveContent from '../../components/ButtonSaveContent';
import InputUsuario from '../../components/Inputs/InputUsuario';
import ImagemAssinatura from '../imagemAssinatura';

const CadastroTecnicos = () => {
  const { handleSubmit, register, setFocus, setValue, reset, getValues } = useForm();
  const { user, sessionCurrent } = useAuth();
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState('geral');
  const [photo, setPhoto] = useState(null);

  const params = useParams()
  const navigate = useNavigate()

  useEffect(() => {

    setFocus('nomes')

    if (params.id) {
      setLoading(true)
      Tecnicos.listId(params.id)
        .then((data) => {
          setValue('nome', data.data.nome);
          setValue('ativo', data.data.ativo);
          setValue('especialidade', data.data.especialidade);
          setValue('documento', data.data.documento);
          setValue('usuario_id', data.data.user?.id);
          setValue('usuario', data.data.user?.name);

          if (data.data.assinatura_url !== '') {
            setPhoto(data.data.assinatura_url)
          }

        })
        .catch((error) => {
          SweetAlert.error(error.response.data.message, 'Doutores');
        })
        .finally(() => { setLoading(false) })

    }
  }, []);


  function onSubmit(data) {

    if (data.nome === '' || data.nome === undefined || data.nome === null) {
      return SweetAlert.error('Nome é um campo obrigatório', 'Doutores');
    }

    setLoading(true)

    const tecnico = {
      "nome": data.nome,
      "ativo": data.ativo,
      "especialidade": data.especialidade,
      "documento": data.documento,
      "user_id": data.usuario_id === '' ? null : data.usuario_id,

    }

    if (params.id) {
      Tecnicos.update(params.id, tecnico)
        .then((data) => {

          if (photo) {

            if (typeof photo === 'string') {
              SweetAlert.success('Registro atualizado com sucesso', 'Doutores')
              navigate(URL.tecnico_lista)
              return
            }

            let formData = new FormData();
            formData.append('photo', photo); // Use uma chave descritiva para a imagem

            Tecnicos.createPhoto(data.data.id, formData)
              .then(() => {
                SweetAlert.success('Registro atualizado com sucesso', 'Doutores')
                navigate(URL.tecnico_lista)
              })
              .catch((error) => {
                SweetAlert.error(error?.response?.data?.message, 'Doutores');
              });
          } else {
            Tecnicos.deletePhoto(data.data.id)
              .then(() => {

                SweetAlert.success('Registro atualizado com sucesso', 'Doutores')
                navigate(URL.tecnico_lista)
              })
              .catch((error) => {
                SweetAlert.error(error?.response?.data?.message, 'Doutores');
              });
          }

        })
        .catch((error) => {
          SweetAlert.error(error.response.data.message, 'Doutores');
        })
        .finally(() => { setLoading(false) })
    } else {
      Tecnicos.create(tecnico)
        .then((data) => {

          if (photo) {
            let formData = new FormData();
            formData.append('photo', photo); // Use uma chave descritiva para a imagem

            Tecnicos.createPhoto(data.data.id, formData)
              .then(() => {
                SweetAlert.success('Doutor cadastrado com sucesso', 'Doutores');
                reset();
                setPhoto(null)
              })
              .catch((error) => {
                SweetAlert.error(error?.response?.data?.message, 'Doutores');
              });
          } else {
            SweetAlert.success('Doutor cadastrado com sucesso', 'Doutores');
            reset();

          }

        })
        .catch((error) => {
          SweetAlert.error(error.response.data.message, 'Doutores');
        })
        .finally(() => {
          setLoading(false)
          setTimeout(() => {
            setFocus('descricao')
            setKey('geral')
          }, 10);
        })
    }

  }

  return (
    <>
      {/* {loading === true ? <Loading /> : ''} */}
      <Container>
        <Form onSubmit={handleSubmit(onSubmit)} className='pt-3'>
          <HeaderLabel
            label='Doutor'
          />

          <Tab.Container id="left-tabs-example" activeKey={key} onSelect={(k) => setKey(k)}>
            <Nav justify variant="tabs" defaultActiveKey="geral">
              <Nav.Item>
                <Nav.Link eventKey="geral">Geral</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="assinatura">Assinatura</Nav.Link>
              </Nav.Item>

            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="geral">
                <Card className='cardShadow'>
                  <Card.Body className='p-3'>
                    <Row className='d-flex align-items-end'>
                      <Form.Group className="col-12">
                        <Form.Label>Nome <span style={{ color: 'red' }}>*</span></Form.Label>
                        {loading ? <LoadingSkeleton /> : <Form.Control size='sm' type='text' {...register('nome')} />}
                      </Form.Group>
                      {params.id ? <Form.Group className="col-12 col-lg-3 mt-2">
                        <Form.Label>Situação</Form.Label>
                        {loading ? <LoadingSkeleton /> : <Form.Select {...register('ativo')} size='sm'>
                          <option value={true}>ATIVO</option>
                          <option value={false}>INATIVO</option>
                        </Form.Select>}
                      </Form.Group> : ''}
                    </Row>
                    <Row className='d-flex align-items-end mt-2'>
                      <Form.Group className="col-12 col-lg-3">
                        <Form.Label>Especialidade </Form.Label>
                        {loading ? <LoadingSkeleton /> : <Form.Control size='sm' type='text' {...register('especialidade')} />}
                      </Form.Group>
                      <Form.Group className="col-12 col-lg-3">
                        <Form.Label>Documento </Form.Label>
                        {loading ? <LoadingSkeleton /> : <Form.Control size='sm' type='text' {...register('documento')} />}
                      </Form.Group>
                    </Row>
                    <Row className='mt-2'>
                      <InputUsuario
                        setValue={setValue}
                        loading={loading}
                        setLoading={setLoading}
                        getValues={getValues}
                        register={register}
                        origem='cadastro'
                      />
                    </Row>
                  </Card.Body>
                </Card>
              </Tab.Pane>

              <Tab.Pane eventKey="assinatura">
                <Card className='cardShadow'>
                  <Card.Body className='p-3'>
                    <ImagemAssinatura
                      photo={photo}
                      setPhoto={setPhoto}
                    />
                  </Card.Body>
                </Card>
              </Tab.Pane>

            </Tab.Content>
          </Tab.Container>



          <div className='d-flex justify-content-end mt-3 mb-3'>
            <Button variant="success" type="submit" className="col-12 col-lg-auto d-flex align-items-center gap-1" disabled={loading}>
              <ButtonSaveContent />
            </Button>
          </div>

        </Form>
      </Container>
    </>
  );
}

export default CadastroTecnicos;
