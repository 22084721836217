import React, { useRef, useEffect, useState } from 'react';
import esqueleto from './teste.jpg';

const Imagem = () => {
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    // Carregar a imagem de fundo
    const backgroundImage = new Image();
    backgroundImage.src = esqueleto;

    backgroundImage.onload = () => {
      // Definir as dimensões do canvas para serem as mesmas da imagem
      canvas.width = backgroundImage.width;
      canvas.height = backgroundImage.height;

      // Desenhar a imagem de fundo no canvas
      context.drawImage(backgroundImage, 0, 0);
    };
  }, []);

  const startDrawing = () => {
    setIsDrawing(true);
  };

  const stopDrawing = () => {
    setIsDrawing(false);
  };

  const draw = (event) => {
    if (!isDrawing) return;

    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    // Configurar propriedades de desenho
    context.strokeStyle = 'red'; // Cor da linha
    context.lineWidth = 3; // Espessura da linha
    context.lineCap = 'round'; // Estilo das extremidades das linhas
    context.lineJoin = 'round'; // Estilo de junção entre linhas

    // Obter as coordenadas do mouse relativas ao canvas
    const x = event.nativeEvent.offsetX;
    const y = event.nativeEvent.offsetY;

    // Iniciar um novo caminho para desenhar uma linha independente
    context.beginPath();

    // Mover para a posição atual do mouse
    context.moveTo(x, y);

    // Desenhar um pequeno círculo na posição atual do mouse
    context.arc(x, y, 1.5, 0, Math.PI * 2);

    // Desenhar a linha no canvas
    context.stroke();
  };

  const handleDownload = () => {
    const canvas = canvasRef.current;
    const link = document.createElement('a');
    link.download = 'imagem_com_riscos.png';
    link.href = canvas.toDataURL('image/png');
    link.click();
  };
  const clearDrawing = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    // Limpar apenas os riscos desenhados
    context.clearRect(0, 0, canvas.width, canvas.height);

    // Carregar a imagem de fundo novamente após limpar
    const backgroundImage = new Image();
    backgroundImage.src = esqueleto;
    backgroundImage.onload = () => {
      context.drawImage(backgroundImage, 0, 0);
    };
  };

  return (
    <>
      <canvas
        ref={canvasRef}
        onMouseDown={startDrawing}
        onMouseUp={stopDrawing}
        onMouseMove={draw}
        style={{ border: '1px solid black' }}
      ></canvas>
      <button onClick={handleDownload}>Baixar Imagem</button>
      <button onClick={clearDrawing}>Limpar Riscos</button>
    </>
  );
};

export default Imagem;
