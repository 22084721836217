import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap'
import esqueleto from './teste.png';
import './style.css'

const Imagem = ({ canvasRef, setImagemServidor, imagemServidor }) => {
  const [isDrawing, setIsDrawing] = useState(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    // Carregar a imagem de fundo
    const backgroundImage = new Image();
    backgroundImage.crossOrigin = "anonymous";
    backgroundImage.src = imagemServidor === '' || imagemServidor === null ? esqueleto : imagemServidor;

    backgroundImage.onload = () => {
      // Definir as dimensões do canvas para serem as mesmas da imagem
      canvas.width = backgroundImage.width;
      canvas.height = backgroundImage.height;

      // Desenhar a imagem de fundo no canvas
      context.drawImage(backgroundImage, 0, 0);
    };
  }, []);

  const startDrawing = (event) => {
    event.preventDefault();
    setIsDrawing(true);
  };

  const stopDrawing = (event) => {
    event.preventDefault();
    setIsDrawing(false);
  };

  const draw = (event) => {
    try {
      if (!isDrawing) return;

      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');

      // Configurar propriedades de desenho
      context.strokeStyle = 'red'; // Cor da linha
      context.lineWidth = 3; // Espessura da linha
      context.lineCap = 'round'; // Estilo das extremidades das linhas
      context.lineJoin = 'round'; // Estilo de junção entre linhas

      // Obter as coordenadas do mouse ou toque relativas ao canvas
      const rect = canvas.getBoundingClientRect();
      const x = event?.nativeEvent?.offsetX || event?.touches[0]?.clientX - rect?.left;
      const y = event?.nativeEvent?.offsetY || event?.touches[0]?.clientY - rect?.top;

      // Iniciar um novo caminho para desenhar uma linha independente
      context.beginPath();

      // Mover para a posição atual do mouse ou toque
      context.moveTo(x, y);

      // Desenhar um pequeno círculo na posição atual do mouse ou toque
      context.arc(x, y, 1.5, 0, Math.PI * 2);

      // Desenhar a linha no canvas
      context.stroke();
    } catch {

    }
  };

  const handleDownload = () => {
    const canvas = canvasRef.current;
    const link = document.createElement('a');
    link.download = 'imagem_com_riscos.png';
    link.href = canvas.toDataURL('image/png');
    link.click();
  };

  const clearDrawing = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    // Limpar apenas os riscos desenhados
    context.clearRect(0, 0, canvas.width, canvas.height);

    // Carregar a imagem de fundo novamente após limpar
    const backgroundImage = new Image();
    backgroundImage.crossOrigin = "anonymous";
    backgroundImage.src = esqueleto;
    backgroundImage.onload = () => {
      canvas.width = backgroundImage.width;
      canvas.height = backgroundImage.height;
      context.drawImage(backgroundImage, 0, 0);
    };
  };

  const desfazer = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    // Limpar apenas os riscos desenhados
    context.clearRect(0, 0, canvas.width, canvas.height);

    // Carregar a imagem de fundo novamente após limpar
    const backgroundImage = new Image();
    backgroundImage.crossOrigin = "anonymous";
    backgroundImage.src = imagemServidor === null ? esqueleto : imagemServidor;
    backgroundImage.onload = () => {
      context.drawImage(backgroundImage, 0, 0);
    };
  };

  const preventScroll = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    const canvas = canvasRef.current;

    canvas.addEventListener('touchstart', preventScroll, { passive: false });
    canvas.addEventListener('touchmove', preventScroll, { passive: false });
    canvas.addEventListener('touchend', preventScroll, { passive: false });

    return () => {
      canvas.removeEventListener('touchstart', preventScroll);
      canvas.removeEventListener('touchmove', preventScroll);
      canvas.removeEventListener('touchend', preventScroll);
    };
  }, []);

  return (
    <>
      {/* <Row className=''> */}
      <Form.Group className="col-12 col-lg-5 mt-2 d-flex flex-column justify-content-center align-items-center">
        <div className='mt-2 d-flex gap-3'>
          <Form.Label>Locais dos sintomas</Form.Label>
        </div>
        <canvas
          ref={canvasRef}
          onMouseDown={startDrawing}
          onMouseUp={stopDrawing}
          onMouseMove={draw}
          onTouchStart={startDrawing}
          onTouchEnd={stopDrawing}
          onTouchMove={draw}
          style={{ border: '1px solid black' }}
        ></canvas>
        <div className='mt-2 d-flex gap-3'>
          {/* <Button onClick={handleDownload}>Baixar Imagem</Button> */}
          <Button onClick={clearDrawing}>Limpar marcações</Button>
          <Button onClick={desfazer}>Desfazer</Button>
        </div>

      </Form.Group>
      {/* </Row> */}
    </>
  );
};

export default Imagem;
