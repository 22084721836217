/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import TopTitlePage from '../../components/TopTitlePage'
import { Button, Card, Container, Form, Offcanvas, Row } from 'react-bootstrap'
import Loading from '../../components/Loading'
import { SweetAlert } from '../../components/sweetalert'
import { useAuth } from '../../context/auth';

import ReactPaginate from 'react-paginate';
import './style.css'
import { useForm } from 'react-hook-form'
import { URL } from '../../config/url'
import { useNavigate, useLocation } from 'react-router-dom'
import Prontuarios from '../../services/Prontuarios'
import ButtonFilterAndSelect from '../../components/ButtonFilterAndSelect'
import ButtonLimparAndFiltrar from '../../components/ButtonLimparAndFiltrar'

import TableList from '../../components/TableComponents/TableList'
import TableData from './TableData'

import { format, startOfMonth, endOfMonth, endOfYear, startOfYear, subDays } from 'date-fns'
import InputPaciente from '../../components/Inputs/InputPaciente'
import InputTecnico from '../../components/Inputs/InputTecnico'
import InputSearchName from '../../components/InputSearchName'

const ListaProntuarios = () => {

  const { register, getValues, handleSubmit, reset, setValue } = useForm()
  const { user } = useAuth();
  const navigate = useNavigate()
  const location = useLocation();

  const [tipoPagamento, setTipoPagamento] = useState([])
  const [loading, setLoading] = useState(false)

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [filter, setFilter] = useState(false);

  const [show, setShow] = useState(false);

  useEffect(() => {

    const params = new URLSearchParams(location.search);

    let primeiroFiltro = true

    if (params.get('data_inicial')) {
      primeiroFiltro = false
      setValue('data_inicial', params.get('data_inicial'));
    }
    if (params.get('data_final')) {
      primeiroFiltro = false
      setValue('data_final', params.get('data_final'));
    }
    if (params.get('titulo')) {
      primeiroFiltro = false
      setValue('titulo', params.get('titulo'));
    }
    if (params.get('paciente_nome')) {
      primeiroFiltro = false
      setValue('paciente', params.get('paciente_nome'));
      setValue('paciente_id', params.get('paciente'));
    }
    if (params.get('tecnico')) {
      primeiroFiltro = false
      setValue('tecnico', params.get('tecnico'));
    }
    if (params.get('nome_input')) {
      primeiroFiltro = false
      // setTimeout(() => {
      setValue('nome_input', params.get('nome_input'));
      // }, 1000);
    }

    if (user.tecnico?.id) {
      // setValue('tecnico_id', user.tecnico.id)
      setValue('tecnico_id', user.tecnico.id)
      setValue('tecnico', user.tecnico.nome)
    }

    if (primeiroFiltro === false) {
      setFilter(true)
      if (params.get('nome_input') === undefined || params.get('nome_input') === null || params.get('nome_input') === '') {
        listAllProntuarios(1, primeiroFiltro, false);
      } else {
        listFilterName(1, false)
      }
    } else {
      listAllProntuarios(1, primeiroFiltro, true);
    }


    // if (getValues('nome_input') === undefined || getValues('nome_input') === null || getValues('nome_input') === '') {


  }, []);


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function listAllProntuarios(pageNumber, filtroDefault = false, mudaURL = true) {
    setLoading(true);

    const values = getValues()
    let newFiltro

    const today = new Date()

    if (filtroDefault === true) {
      newFiltro = {
        data_inicial: format(subDays(today, 365), 'yyyy-MM-dd'), // 365 dias atrás
        data_final: format(today, 'yyyy-MM-dd'), // data atual
        tecnico: values.tecnico_id ?? '',
      }
      setValue('data_inicial', format(subDays(today, 365), 'yyyy-MM-dd')); // 365 dias atrás
      setValue('data_final', format(today, 'yyyy-MM-dd')); // data atual
    }
    else {

      newFiltro = {
        data_inicial: values.data_inicial ?? '',
        data_final: values.data_final ?? '',
        titulo: values.titulo ?? '',
        paciente: values.paciente_id ?? '',
        paciente_nome: values.paciente ?? '',
        tecnico: values.tecnico_id ?? '',
      }

    }

    if (mudaURL === true) {
      const params = new URLSearchParams(newFiltro);
      window.history.replaceState(null, '', `${location.pathname}?${params.toString()}`);
    }

    Prontuarios.listAll(pageNumber, newFiltro)
      .then((data) => {
        setTipoPagamento(data.data.data);
        setTotalPages(data.data.paging.total_pages)
        setTotalRegistros(data.data.paging.total_records)
      })
      .catch((error) => {
        SweetAlert.error(error.response.data.message, 'Prontuários');
      })
      .finally(() => {
        // setFiltros(newFiltro)
        setLoading(false)
      });
  }

  function listFilterName(pageNumber, mudaURL = true) {
    setLoading(true);

    const values = getValues()

    let newFiltro = {
      paciente_nome: values.nome_input ?? '',
    }
    let newFiltroParam = {
      nome_input: values.nome_input ?? '',
    }

    if (mudaURL === true) {
      let params = new URLSearchParams(location.search);
      let params2 = new URLSearchParams(newFiltroParam);
      window.history.replaceState(null, '', `${location.pathname}?${params.toString()}&${params2.toString()}`);
    }

    Prontuarios.listAll(pageNumber, newFiltro)
      .then((data) => {
        setTipoPagamento(data.data.data);
        setTotalPages(data.data.paging.total_pages)
        setTotalRegistros(data.data.paging.total_records)
      })
      .catch((error) => {
        SweetAlert.error(error.response.data.message, 'Pacientes');
      })
      .finally(() => {
        // setFiltros(newFiltro)
        setLoading(false)
      });
  }


  const handlePageChange = (selected) => {
    setPage(selected.selected + 1)
    if (getValues('nome_input') === '') {

      listFilterName(selected.selected + 1);
    } else {

      listAllProntuarios(selected.selected + 1);
    }
  };

  function onFilter() {
    setFilter(true)
    setShow(false)
    listAllProntuarios(1)
  }

  function cleanFilter() {
    const today = new Date()
    setValue('data_inicial', format(startOfMonth(today), 'yyyy-MM-dd'));
    setValue('data_final', format(endOfMonth(today), 'yyyy-MM-dd'));
    setValue('titulo', '')
    setValue('paciente_id', '')
    setValue('paciente', '')
    setValue('tecnico_id', '')
    setValue('tecnico', '')
  }

  function deleteProntuario(id) {
    setLoading(true)
    Prontuarios.delete(id)
      .then((data) => {
        // SweetAlert.success('Categoria deletada com sucesso!', 'Categorias');
        listAllProntuarios()
      })
      .catch((error) => {
        SweetAlert.error(error.response.data.message, 'Prontuários');
      })
      .finally(() => setLoading(false))
  }

  function deletePerguntaAlert(id) {
    SweetAlert.question('Atenção', 'Deletar prontuário?', 'SIM!', () => deleteProntuario(id), '#FF0000')
  }

  function editPergunta(id) {
    navigate(URL.prontuario_cadastro + '/' + id)
  }

  return (
    <>
      {loading === true ? <Loading /> : ''}

      <Offcanvas show={show} onHide={handleClose} style={{ height: '100vh', backgroundColor: '#f1f1f1' }} placement='bottom'>
        <Form onSubmit={handleSubmit(onFilter)}>
          <Container>
            <Offcanvas.Header closeButton>
              <TopTitlePage title='Filtro' />
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Card className='cardShadow'>
                <Card.Body className='p-3'>
                  <Row className='d-flex align-items-end'>
                    <Form.Group className="col-12 col-lg-3">
                      <Form.Label>Data inicial</Form.Label>
                      <Form.Control
                        size='sm'
                        type="date"
                        {...register('data_inicial')}
                      />
                    </Form.Group>

                    <Form.Group className="col-12 col-lg-3">
                      <Form.Label>Data final</Form.Label>
                      <Form.Control
                        size='sm'
                        type="date"
                        {...register('data_final')}
                      />
                    </Form.Group>
                  </Row>

                  <Row className='d-flex align-items-end mt-2'>
                    <Form.Group className="col-12 col-lg-13">
                      <Form.Label>Título</Form.Label>
                      <Form.Control
                        size='sm'
                        type="text"
                        {...register('titulo')}
                      />
                    </Form.Group>

                  </Row>

                  <Row className='d-flex align-items-end mt-2'>
                    <InputPaciente
                      setValue={setValue}
                      loading={loading}
                      setLoading={setLoading}
                      // setPhoto=''
                      getValues={getValues}
                      register={register}
                    />

                  </Row>

                  <Row className='d-flex align-items-end mt-2'>
                    <InputTecnico
                      setValue={setValue}
                      loading={loading}
                      setLoading={setLoading}
                      getValues={getValues}
                      register={register}
                    />

                  </Row>

                </Card.Body>
              </Card>
              <div className='d-flex justify-content-end mt-3 gap-3'>
                <ButtonLimparAndFiltrar
                  handleClose={handleClose}
                  reset={cleanFilter}
                  listAll={listAllProntuarios}
                  setFilter={setFilter}
                />
              </div>
            </Offcanvas.Body>
          </Container>
        </Form>
      </Offcanvas>

      <div>
        <TopTitlePage title='Prontuários' />

        <div className='py-3 d-flex justify-content-between px-3 align-items-end gap-2 flex-column flex-md-row'>

          <ButtonFilterAndSelect
            handleShow={handleShow}
            listAll={listAllProntuarios}
            filter={filter}
          />

          <div className='d-flex align-items-end gap-3 col-12 col-md-auto'>
            <Button
              variant='success'
              onClick={() => navigate(URL.prontuario_cadastro)}
              className='col-12 col-md-auto'
            >+ Novo prontuário</Button>
          </div>
        </div>

        <div className='mx-3 mb-2 lg-mb-0'>

          <InputSearchName
            register={register}
            listAll={listAllProntuarios}
            listFilterName={listFilterName}

          />

        </div>

        <Card className='mx-3 cardShadow mb-2 lg-mb-0'>
          <Card.Body className='p-0'>

            {tipoPagamento.length === 0 ?
              <div className='d-flex justify-content-center py-2'>
                <h4>Sem registros</h4>
              </div>
              :
              <>
                <TableList>
                  <TableData
                    array={tipoPagamento}
                    editFunction={editPergunta}
                    deleteFunction={deletePerguntaAlert}
                  />
                </TableList>

                <div className='mt-4 d-flex justify-content-between px-3 flex-column flex-md-row gap-3'>
                  <span> Total: {totalRegistros}</span>
                  <ReactPaginate
                    pageCount={totalPages}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={1}
                    onPageChange={handlePageChange}
                    containerClassName={'pagination_container'}
                    activeClassName={'active'}
                    previousLabel={'<'}
                    nextLabel={'>'}
                  />
                </div>
              </>
            }

          </Card.Body>
        </Card>


      </div>
    </>
  )
}

export default ListaProntuarios