import { axios } from '../config/axios';
import security from './security'

const Financeiro = {};

Financeiro.create = (body) => {
  return security.validation(axios.post('/financeiros', body));
};

Financeiro.listAll = (page = '', filtros = '') => {

  let filtro = ''

  if (filtros !== '') {
    filtro = filtro + '&paciente_id=' + (filtros.paciente_id === undefined ? '' : filtros.paciente_id)
    filtro = filtro + '&tecnico_id=' + (filtros.tecnico_id === undefined ? '' : filtros.tecnico_id)
    filtro = filtro + '&data_pagamento_inicial=' + (filtros.data_inicial === undefined ? '' : filtros.data_inicial)
    filtro = filtro + '&data_pagamento_final=' + (filtros.data_final === undefined ? '' : filtros.data_final)
    filtro = filtro + '&paciente_nome=' + (filtros.paciente_nome === undefined ? '' : filtros.paciente_nome)
  }

  return security.validation(axios.get('/financeiros?orderby=-data_pagamento&page_size=100&page=' + page + filtro));
};

Financeiro.listId = (id) => {
  return security.validation(axios.get('/financeiros/' + id));
};

Financeiro.update = (id, body) => {
  return security.validation(axios.patch('/financeiros/' + id, body));
};

Financeiro.delete = (id) => {
  return security.validation(axios.delete('/financeiros/' + id));
};

// Agendamentos.listAllClientesAtivos = (page = '', filtros = '') => {

//   let filtro = ''

//   if (filtros !== '') {
//     filtro = '&nome=' + (filtros.nome === undefined ? '' : filtros.nome?.toUpperCase())
//     filtro = filtro + '&email=' + (filtros.email === undefined ? '' : filtros.email.toUpperCase())
//     filtro = filtro + '&cpf=' + (filtros.cpf === undefined ? '' : filtros.cpf)
//     filtro = filtro + '&ativo=' + (filtros.ativo === undefined ? '' : filtros.ativo)
//   }

//   return security.validation(axios.get('/pacientes?ativo=true&orderby=codigo&page_size=10&page=' + page + filtro));
// };

// Agendamentos.listPessoaById = (id) => {
//   return security.validation(axios.get('/pacientes/' + id));
// };

// Agendamentos.createPhoto = (id, newPhoto) => {
//   return security.validation(axios.patch('/pacientes/' + id + '/foto', newPhoto,
//     {
//       headers: {
//         'Content-Type': 'multipart/form-data'
//       }
//     }));
// };

// Agendamentos.deletePhoto = (id) => {
//   return security.validation(axios.delete('/pacientes/' + id + '/foto'));
// };



export default Financeiro;
