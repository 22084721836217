import React, { useState, useEffect } from 'react'
import { Modal, Button, Form, Row, Container } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { IoSearch } from "react-icons/io5";
import { FaRegEye, FaWhatsapp } from "react-icons/fa";
import ModalSearchPacientes from '../ModalSearch/ModalSearchPacientes';
import CadastroPaciente from '../../pages/cadastro_paciente';
import ModalSearchTecnicos from '../ModalSearch/ModalSearchTecnicos';
import ModalSearchTipoAtendimento from '../ModalSearch/ModalSearchTipoAtendimento';
import CadastroProntuarios from '../../pages/cadastro_prontuarios';
import ModalSearchProntuarios from '../ModalSearch/ModalSearchProntuarios';
import Agendamentos from '../../services/Agendamentos';
import { SweetAlert } from '../sweetalert';
import { addMinutes, subMinutes, format } from 'date-fns';

const ModalNovoAgendamento = ({ show, handleClose, setAgendamentos, register, setValue, getValues,
  //  reset,
  listAll, watch, status }) => {

  const [showCliente, setShowCliente] = useState(false);
  const [showTecnico, setShowTecnico] = useState(false);
  const [showTipoAtendimento, setShowTipoAtendimento] = useState(false);
  const [showProntuarios, setShowProntuarios] = useState(false);
  const [showModalCadastroPessoa, setShowModalCadastroPessoa] = useState(false);
  const [showModalCadastroProntuario, setShowModalCadastroProntuario] = useState(false);

  const [showNext, setShowNext] = useState(false);
  const [nextTitle, setNextTitle] = useState(false);

  const [loading, setLoading] = useState(false);

  const [id, setId] = useState(null);
  const [values, setValues] = useState(null);

  const horaInicial = watch('hora_inicial', '');
  const horaFinal = watch('hora_final', '');

  useEffect(() => {

    if (show === true) {


      const values = getValues()
      setValues(values)

      if (getValues('id') !== undefined && getValues('id') !== null && getValues('id') !== '') {
        setId(getValues('id'));
        setShowNext(true)
      }
    }

  }, [show]);

  const handleCloseCliente = () => setShowCliente(false);
  const handleShowCliente = () => setShowCliente(true);
  const handleCloseTecnico = () => setShowTecnico(false);
  const handleShowTecnico = () => setShowTecnico(true);
  const handleShowModalCadastroPessoa = () => setShowModalCadastroPessoa(true);
  const handleCloseModalCadastroPessoa = () => setShowModalCadastroPessoa(false);
  const handleCloseProntuarios = () => setShowProntuarios(false);
  const handleShowProntuarios = () => setShowProntuarios(true);
  const handleShowModalCadastroProntuario = () => setShowModalCadastroProntuario(true);
  const handleCloseModalCadastroProntuario = () => setShowModalCadastroProntuario(false);
  const handleShowTipoAtendimento = () => setShowTipoAtendimento(true);
  const handleCloseTipoAtendimento = () => setShowTipoAtendimento(false);


  function closeAll() {
    handleClose()
    setId(null)
    setShowNext(false)
    setNextTitle(false)
    // reset()
    setValue('id', '');
    setValue("paciente_id", '')
    setValue("paciente", '')
    setValue("prontuario_id", null)
    setValue("prontuario", '')
    setValue("tecnico_id", '')
    setValue("tecnico", '')
    setValue("tipo_atendimento_id", '')
    setValue("tipo_atendimento", '')
    setValue("data_inicial", '')
    setValue("hora_inicial", '')
    setValue("hora_final", '')
    setValue("status", 'PENDENTE')
    setValue("telefone1", '')
    setValue("telefone2", '')
  }


  function onSubmit(origem = 'salvar') {

    const data = getValues();

    const dataInicialObj = new Date(data.data_inicial + 'T' + data.hora_inicial);
    const dataFinalObj = new Date(data.data_inicial + 'T' + data.hora_final);

    if (dataFinalObj <= dataInicialObj) {
      return SweetAlert.error("A hora final não pode ser menor ou igual à hora inicial.");
    }

    if (data.paciente === '' || data.paciente === undefined || data.paciente === null) {
      return SweetAlert.error('Paciente é um campo obrigatório', 'Agendamento');
    }

    if (data.tecnico === '' || data.tecnico === undefined || data.tecnico === null) {
      return SweetAlert.error('Doutor é um campo obrigatório', 'Agendamento');
    }

    if (data.tipo_atendimento_id === '' || data.tipo_atendimento_id === undefined || data.tipo_atendimento_id === null) {
      return SweetAlert.error('Tipo de atendimento é um campo obrigatório', 'Agendamento');
    }

    if (data.data_inicial === '' || data.data_inicial === undefined || data.data_inicial === null) {
      return SweetAlert.error('Data inicial é um campo obrigatório', 'Agendamento');
    }

    const newAgendamento = {
      "paciente_id": data.paciente_id,
      "prontuario_id": data.prontuario_id,
      "tecnico_id": data.tecnico_id,
      "tipo_atendimento_id": data.tipo_atendimento_id,
      "data_agendamento_inicial": data.data_inicial + 'T' + data.hora_inicial,
      "data_agendamento_final": data.data_inicial + 'T' + data.hora_final,
      "status_id": data.status
    }

    if (id !== null) {
      Agendamentos.update(id, newAgendamento)
        .then((data) => {
          SweetAlert.success('Registro atualizado com sucesso', 'Agendamento');
          if (origem === 'salvar') {
            closeAll()
          }
          listAll(false, getValues('date'))
        })
        .catch((error) => {
          SweetAlert.error(error.response.data.message, 'Agendamento');
        })

    } else {
      Agendamentos.create(newAgendamento)
        .then((data) => {
          SweetAlert.success('Agendamento cadastrado com sucesso', 'Agendamento');
          if (origem === 'salvar') {
            closeAll()
          }
          listAll(false, getValues('date'))
        })
        .catch((error) => {
          SweetAlert.error(error.response.data.message, 'Agendamento');
        })
    }
  }

  function marcarPróximo() {
    onSubmit('proximo')
    setId(null)
    // setValue('data_inicial', '')
    setValue('status', 'PENDENTE')
    setNextTitle(true)
  }

  function showSelectedPaciente() {
    const paciente_id = getValues('paciente_id')

    if (paciente_id === undefined) {
      return SweetAlert.error('Selecione um paciente antes de visualizar seus dados', 'Paciente')
    }

    const url = `/pacientes/cadastro/${paciente_id}`
    window.open(url, '_blank')
  }

  function showSelectedProntuario() {
    const prontuario_id = getValues('prontuario_id')

    if (prontuario_id === undefined) {
      return SweetAlert.error('Selecione um prontuário antes de visualizar seus dados', 'Prontuário')
    }

    const url = `/prontuario/cadastro/${prontuario_id}`
    window.open(url, '_blank')
  }

  function add15min() {

    if (horaFinal) {
      const [hours, minutes] = horaFinal.split(':').map(Number);
      const date = new Date();
      date.setHours(hours);
      date.setMinutes(minutes);

      const newDate = addMinutes(date, 15);
      const newTime = format(newDate, 'HH:mm');

      setValue('hora_final', newTime);
    }

  }

  function remove15min() {
    if (horaFinal) {
      const [hours, minutes] = horaFinal.split(':').map(Number);
      const date = new Date();
      date.setHours(hours);
      date.setMinutes(minutes);

      const newDate = subMinutes(date, 15);
      const newTime = format(newDate, 'HH:mm');

      setValue('hora_final', newTime);
    }

  }

  function deleteAgendamento() {
    Agendamentos.delete(id)
      .then(() => {
        SweetAlert.success('Agendamento removido com sucesso', 'Agendamento')
        setId(null)
        closeAll()
        listAll(false, getValues('date'))
      })
      .catch((error) => {
        SweetAlert.error(error.response.data.message, 'Agendamento');
      })
  }


  return (
    <Container>

      <ModalSearchPacientes
        show={showCliente}
        handleClose={handleCloseCliente}
        setValues={setValue}
        handleShowModalCadastroPessoa={handleShowModalCadastroPessoa}
        handleCloseModalCadastroPessoa={handleCloseModalCadastroPessoa}
        loading={loading}
        setLoading={setLoading}
        setPhoto={() => { }}
      />

      <ModalSearchTecnicos
        show={showTecnico}
        handleClose={handleCloseTecnico}
        setValues={setValue}
        loading={loading}
        setLoading={setLoading}
      />

      <ModalSearchTipoAtendimento
        show={showTipoAtendimento}
        handleClose={handleCloseTipoAtendimento}
        setValues={setValue}
        loading={loading}
        setLoading={setLoading}
      />

      <ModalSearchProntuarios
        show={showProntuarios}
        handleClose={handleCloseProntuarios}
        setValues={setValue}
        loading={loading}
        setLoading={setLoading}
        handleShowModalCadastroProntuario={handleShowModalCadastroProntuario}
        handleCloseModalCadastroProntuario={handleCloseModalCadastroProntuario}
      />

      <Modal show={showModalCadastroPessoa} fullscreen centered >
        <Modal.Header closeButton onHide={handleCloseModalCadastroPessoa} />
        <Modal.Body>
          <CadastroPaciente
            origem='modal'
            handleCloseModalCadastroPessoa={handleCloseModalCadastroPessoa}
            setValueModal={setValue}
            handleCloseCliente={handleCloseCliente}
          />
        </Modal.Body>
      </Modal>

      <Modal show={showModalCadastroProntuario} fullscreen centered >
        <Modal.Header closeButton onHide={handleCloseModalCadastroProntuario} />
        <Modal.Body>
          <CadastroProntuarios
            origem='modal'
            handleCloseModalCadastroProntuario={handleCloseModalCadastroProntuario}
            setValueModal={setValue}
            handleCloseProntuarios={handleCloseProntuarios}
            values={getValues()}
          />
        </Modal.Body>
      </Modal>


      <Modal show={show} onHide={closeAll} size='xl' centered backdrop='static'>
        <Modal.Header closeButton>
          <Modal.Title>Detalhes do agendamento {nextTitle === true ? '- Marcar próximo' : ''}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Form onSubmit={handleFormSubmit(onSubmit)}> */}

          <Form.Group className="col-12 col-lg-10">
            <Form.Label>Paciente</Form.Label>
            <div className='d-flex'>
              <Form.Control size='sm' type="text" {...register('paciente')} disabled />
              <Button
                variant='outline-secondary'
                className='p-0 px-2 d-flex align-items-center ms-1'
                onClick={handleShowCliente}
              >
                <IoSearch size={20} />
              </Button>
              <Button
                variant='outline-secondary'
                className='p-0 px-2 d-flex align-items-center ms-1'
                onClick={showSelectedPaciente}
              >
                <FaRegEye size={20} />
              </Button>

              {getValues('telefone1') === '' || getValues('telefone1') === null || getValues('telefone1') === undefined ? '' :
                <Button
                  as="a"
                  href={`https://wa.me/${getValues('telefone1')}`}
                  target='_blank'
                  rel='noopener noreferrer'
                  variant='outline-secondary'
                  className='p-0 px-2 d-flex align-items-center ms-1'
                >
                  <FaWhatsapp size={20} />
                </Button>
              }
              {getValues('telefone2') === '' || getValues('telefone2') === null || getValues('telefone2') === undefined ? '' :
                <Button
                  as="a"
                  href={`https://wa.me/${getValues('telefone2')}`}
                  target='_blank'
                  rel='noopener noreferrer'
                  variant='outline-secondary'
                  className='p-0 px-2 d-flex align-items-center ms-1'
                >
                  <FaWhatsapp size={20} />
                </Button>
              }

            </div>
          </Form.Group>

          <Form.Group className="col-12 col-lg-10 mt-2">
            <Form.Label>Doutor</Form.Label>
            <div className='d-flex'>
              <Form.Control size='sm' type="text" {...register('tecnico')} disabled />
              <Button
                variant='outline-secondary'
                className='p-0 px-2 d-flex align-items-center ms-1'
                onClick={handleShowTecnico}
              >
                <IoSearch size={20} />
              </Button>

            </div>

          </Form.Group>

          <Form.Group className="col-12 col-lg-10 mt-2">
            <Form.Label>Tipo de atendimento</Form.Label>
            <div className='d-flex'>
              <Form.Control size='sm' type="text" {...register('tipo_atendimento')} disabled />
              <Button
                variant='outline-secondary'
                className='p-0 px-2 d-flex align-items-center ms-1'
                onClick={handleShowTipoAtendimento}
              >
                <IoSearch size={20} />
              </Button>

            </div>

          </Form.Group>


          <Form.Group className="col-12 col-lg-10 mt-2">
            <Form.Label>Prontuário</Form.Label>
            <div className='d-flex'>
              <Form.Control size='sm' type="text" {...register('prontuario')} disabled />
              <Button
                variant='outline-secondary'
                className='p-0 px-2 d-flex align-items-center ms-1'
                onClick={handleShowProntuarios}
              >
                <IoSearch size={20} />
              </Button>
              <Button
                variant='outline-secondary'
                className='p-0 px-2 d-flex align-items-center ms-1'
                onClick={showSelectedProntuario}
              >
                <FaRegEye size={20} />
              </Button>

            </div>

          </Form.Group>

          <Form.Group className="col-12 col-lg-3 mt-2">
            <Form.Label>Status</Form.Label>
            <Form.Select size='sm' {...register('status')}>
              {status?.map(item => {
                return (
                  <option value={item.id}>{item.nome}</option>

                )
              })}
            </Form.Select>
          </Form.Group>


          <div className='d-flex gap-3 my-2 flex-wrap align-items-end'>
            <Form.Group className="col-12 col-lg-2">
              <Form.Label>Data</Form.Label>
              <Form.Control size='sm' type="date" {...register('data_inicial')} />
            </Form.Group>
            <Form.Group className="col-12 col-lg-2">
              <Form.Label>Início</Form.Label>
              <Form.Control
                size='sm'
                type="time"
                {...register('hora_inicial')}
                onBlur={(e) => {
                  setValue('hora_final', e.target.value);
                  const [hours, minutes] = e.target.value.split(':').map(Number);
                  const date = new Date();
                  date.setHours(hours);
                  date.setMinutes(minutes);

                  const newDate = addMinutes(date, 15);
                  const newTime = format(newDate, 'HH:mm');

                  setValue('hora_final', newTime);

                }} />
            </Form.Group>
            <Form.Group className="col-12 col-lg-2">
              <Form.Label>Fim</Form.Label>
              <Form.Control size='sm' type="time" {...register('hora_final')} disabled />
            </Form.Group>
            <Button onClick={add15min}>+15 min</Button>
            <Button onClick={remove15min}>-15 min</Button>
          </div>

          <Modal.Footer className={`d-flex ${id ? 'justify-content-between' : 'justify-content-end'}`}>
            {id ? <Button variant="outline-danger" type="button" onClick={deleteAgendamento}>
              Excluir
            </Button> : ''}

            <div className='d-flex gap-2'>

              <Button variant="outline-success" type="button" onClick={marcarPróximo}>
                Salvar e próximo
              </Button>
              <Button variant="success" type="button" onClick={() => onSubmit()}>
                Salvar
              </Button>
            </div>
          </Modal.Footer>

          {/* </Form> */}
        </Modal.Body>
      </Modal>
    </Container>
  )
}

export default ModalNovoAgendamento