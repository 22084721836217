import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Card, Col, Container, Form, Nav, Row, Spinner, Tab } from 'react-bootstrap';
// import { FiCheck } from 'react-icons/fi';
import SessionsServices from '../../services/sessions'
import { SweetAlert } from '../../components/sweetalert';
import { useNavigate, useParams } from 'react-router-dom';
import { URL } from '../../config/url';
import Usuarios from '../../services/Usuarios';
import { useAuth } from '../../context/auth';
import TabUsuario from './TabUsuario';
import { IoMdArrowRoundBack } from 'react-icons/io';
import TabEmpresas from './TabEmpresas';
import Loading from '../../routers/loading';

function CriarUsuarioInterno() {
  const params = useParams()

  const [loadingRecuperar, setLoadingRecuperar] = useState(false)
  const [empresas, setEmpresas] = useState([])

  const { register, getValues, formState: { errors }, setValue } = useForm();
  const { sessionCurrent } = useAuth();

  useEffect(() => {
    if (params.id) {
      setLoadingRecuperar(true)
      Usuarios.listId(params.id)
        .then((data) => {
          setEmpresas(data.data.empresas_usuarios)
          setValue('email', data.data.email);
          setValue('nome', data.data.name);
          setValue('username', data.data.username);
          setValue('password', '');
          setValue('status', data.data.activated);
        })
        .catch((error) => {
          SweetAlert.error(error.response.data.message, 'Conta');
        })
        .finally(() => { setLoadingRecuperar(false) })

    }
  }, []);

  const navigate = useNavigate()

  async function criarUsuario() {
    const user = {
      "email": getValues('email'),
      "name": getValues('nome'),
      "username": getValues('username'),
      "password": getValues('password'),
      "activated": getValues('status') === 'true' ? true : false,
    }

    if (params.id) {
      try {
        setLoadingRecuperar(true)
        await SessionsServices.updateUser(user, params.id)
        SweetAlert.success('Usuário atualizado com sucesso!', 'Usuário')
        navigate(URL.usuarios_lista)
        setLoadingRecuperar(false)
      } catch (error) {
        setLoadingRecuperar(false)
        SweetAlert.error(error.response.data.message, 'Usuário');
      }

    } else {
      try {
        setLoadingRecuperar(true)
        await SessionsServices.crateUser(user)
        SweetAlert.success('Usuário criado com sucesso! Verifique seu email e ative sua conta', 'Usuário')
        navigate(URL.login)
        setLoadingRecuperar(false)
      } catch (error) {
        setLoadingRecuperar(false)
        SweetAlert.error(error.response.data.message, 'Usuário');
      }
    }
  }

  return (
    <>
      {loadingRecuperar === true ? <Loading /> : ''}
      <Container className=" pt-2">

        <div className='d-flex justify-content-between align-items-center mb-4'>
          <h2>Usuário</h2>
          <Button variant='outline-secondary' onClick={() => navigate(-1)} className='d-flex align-items-center gap-1'><IoMdArrowRoundBack /> Voltar</Button>
        </div>

        <Card className='col-11 col-md-12 cardShadow d-flex p-3'>
          <Container fluid>


            <Tab.Container id="tabs-example" defaultActiveKey="/home">
              <Nav justify variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="/home">Principal</Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link eventKey="link-1">Empresas</Nav.Link>
                </Nav.Item> */}
                {/* <Nav.Item>
                  <Nav.Link eventKey="link-2">Permissões</Nav.Link>
                </Nav.Item> */}
              </Nav>

              <Tab.Content>
                <Tab.Pane eventKey="/home" className='p-5'>
                  <TabUsuario
                    params={params}
                    navigate={navigate}
                    register={register}
                    errors={errors}
                    criarUsuario={criarUsuario}
                    loadingRecuperar={loadingRecuperar}
                  />
                </Tab.Pane>
                {/* <Tab.Pane eventKey="link-1">
                  <TabEmpresas
                    empresas={empresas}
                  />
                </Tab.Pane> */}
                {/* <Tab.Pane eventKey="link-2">
                  Conteúdo da aba Permissões
                </Tab.Pane> */}
              </Tab.Content>
            </Tab.Container>
          </Container>

        </Card>






      </Container >
    </>
  )
}

export default React.memo(CriarUsuarioInterno)