export const formatCEP = (e, setValue, input) => {
  // Remove non-digit characters
  let value = e.target.value;
  let newData = value.replace(/\D/g, '');

  // Limita o campo ao máximo de 9 dígitos
  newData = newData.slice(0, 8);

  newData = newData.replace(/^(\d{5})(\d)/, '$1-$2');

  setValue(input, newData);
};
