export const events = [
  {
    id: 1,
    title: 'Paciente 1',
    start: new Date(2024, 4, 6, 10, 0), // Ano, Mês (zero-based), Dia, Hora, Minuto
    end: new Date(2024, 4, 6, 10, 30),   // Ano, Mês (zero-based), Dia, Hora, Minuto
    status: 'confirmed',
  },
  {
    id: 2,
    title: 'Paciente 2',
    start: new Date(2024, 4, 6, 11, 0), // Ano, Mês (zero-based), Dia, Hora, Minuto
    end: new Date(2024, 4, 6, 11, 30),   // Ano, Mês (zero-based), Dia, Hora, Minuto
    status: 'confirmed',
  },
  {
    id: 3,
    title: 'Paciente 3',
    start: new Date(2024, 4, 6, 14, 0), // Ano, Mês (zero-based), Dia, Hora, Minuto
    end: new Date(2024, 4, 6, 14, 30),   // Ano, Mês (zero-based), Dia, Hora, Minuto
    status: 'pending',
  },
];
