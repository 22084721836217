import { fromZonedTime, toZonedTime } from 'date-fns-tz'
import { addHours } from 'date-fns'

const UTCToTimeZone = (UTCDateTime) => {

  const newDate = new Date(UTCDateTime)

  const forceToUTC = fromZonedTime(newDate.toISOString(), 'Etc/UTC')
  return toZonedTime(addHours(forceToUTC, 6), 'America/Sao_Paulo')
}

const SetTimeZone = (localDateTime) => {
  const newDate = new Date(localDateTime)
  return fromZonedTime(newDate, 'America/Sao_Paulo')
}

export default Object.freeze({ SetTimeZone, UTCToTimeZone })