import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { SweetAlert } from '../../components/sweetalert';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../context/auth';
import { URL } from '../../config/url';
import { LoadingSkeleton } from '../../components/LoadingSkeleton';
import HeaderLabel from '../../components/HeaderLabel';
import { formatMoney } from '../../utilities/formatMoneyValue';
import CriarUsuarioInterno from '../criar_usuario_interno';

const UsuariosCadastro = () => {
  const { handleSubmit, register, setFocus, setValue, reset } = useForm();
  const { sessionCurrent } = useAuth();
  const [loading, setLoading] = useState(false);

  const params = useParams()
  const navigate = useNavigate()

  // useEffect(() => {
  //   setFocus('nome')
  //   if (params.id) {
  //     setLoading(true)
  //     Contas.listId(params.id)
  //       .then((data) => {
  //         setValue('nome', data.data.nome);
  //         setValue('ativo', data.data.ativo);
  //         setValue('saldo', formatMoney(data.data.saldo));
  //       })
  //       .catch((error) => {
  //         SweetAlert.error(error.response.data.message, 'Conta');
  //       })
  //       .finally(() => { setLoading(false) })

  //   } else {
  //     setValue('saldo', 'R$ 0,00')
  //   }
  // }, []);

  return (
    <>
      {/* {loading === true ? <Loading /> : ''} */}
      <Container>
        <CriarUsuarioInterno />
      </Container>
    </>
  );
}

export default UsuariosCadastro;
