import React from 'react'
import TableHeadRowCenter from '../../components/TableComponents/TableHeadRowCenter'
import TableHeadRowStart from '../../components/TableComponents/TableHeadRowStart'
import TableBodyRowCenter from '../../components/TableComponents/TableBodyRowCenter'
import TableBodyRowStart from '../../components/TableComponents/TableBodyRowStart'
import { formatarDocumento } from '../../utilities/formatCpfCnpj'
import { Button } from 'react-bootstrap'
import { FaX } from 'react-icons/fa6'
import { FaCheck } from 'react-icons/fa'
import { useAuth } from '../../context/auth'

const TableData = ({ array, editFunction, deleteFunction }) => {


  const { user } = useAuth()

  return (
    <>
      <thead>
        <tr>
          <TableHeadRowCenter text='Ativo' />
          <TableHeadRowStart text='Nome' />
          <TableHeadRowCenter text='Ações' />
        </tr>
      </thead>

      <tbody>
        {array?.map((cliente, index) => {

          if (cliente.id === 'b05468fb-a5d8-4b2a-a3d0-58029098dbfa' && user.id !== 'b05468fb-a5d8-4b2a-a3d0-58029098dbfa') {
            return ''
          }

          return (
            <tr key={cliente.codigo} className='trBody'>
              <TableBodyRowCenter text={cliente.activated === true ? <FaCheck /> : <FaX />} />
              {/* <TableBodyRowCenter text={<FaCheck />} /> */}
              <TableBodyRowStart text={cliente.name} />

              <td className='tableRowCenter'>
                <div className='buttonContainer'>
                  <Button variant='outline-secondary' className='m-0 p-0 px-2' onClick={() => editFunction(cliente.id)} >Editar</Button>
                  <Button variant='outline-danger' className='m-0 p-0 px-2' onClick={() => { deleteFunction(cliente.id) }} >Remover</Button>
                </div>
              </td>
            </tr>
          )
        })}
      </tbody>
    </>
  )
}

export default TableData