import { axios } from '../config/axios';
import security from './security'

const Contratos = {};

Contratos.create = (body) => {
  return security.validation(axios.post('/contratos', body));
};

Contratos.listAll = (page = '', filtros = '') => {

  let filtro = ''

  if (filtros !== '') {
    filtro = filtro + '&codigo=' + (filtros.codigo === undefined ? '' : filtros.codigo)
    filtro = filtro + '&paciente_id=' + (filtros.paciente_id === undefined ? '' : filtros.paciente_id)
    filtro = filtro + '&adesao_data_inicial=' + (filtros.data_inicial === undefined ? '' : filtros.data_inicial)
    filtro = filtro + '&adesao_data_final=' + (filtros.data_final === undefined ? '' : filtros.data_final)
    filtro = filtro + '&tecnico_responsavel_id=' + (filtros.tecnico_id === undefined ? '' : filtros.tecnico_id)
    filtro = filtro + '&paciente_nome=' + (filtros.paciente_nome === undefined ? '' : filtros.paciente_nome)
  }

  return security.validation(axios.get('/contratos?orderby=-codigo&page_size=100&page=' + page + filtro));
};


Contratos.listPessoaById = (id) => {
  return security.validation(axios.get('/contratos/' + id));
};

Contratos.createPhoto = (id, newPhoto) => {
  return security.validation(axios.patch('/contratos/' + id + '/anexos', newPhoto,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }));
};

Contratos.deletePhoto = (id, deletedFile) => {
  return security.validation(axios.delete('/contratos/' + id + '/anexos/' + deletedFile));
};

Contratos.listId = (id) => {
  return security.validation(axios.get('/contratos/' + id));
};
Contratos.listIdPDF = (id) => {
  return security.validation(axios.get('/contratos/' + id + '/report', { responseType: 'blob' }));
};

Contratos.update = (id, body) => {
  return security.validation(axios.patch('/contratos/' + id, body));
};

Contratos.delete = (id) => {
  return security.validation(axios.delete('/contratos/' + id));
};
export default Contratos;
