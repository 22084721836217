export const formatCPF = (e, setValue, input) => {
  // Remove caracteres não numéricos
  const value = e.target.value.replace(/\D/g, '');

  // Limita o comprimento do valor ao tamanho máximo de um CPF
  const limitedValue = value.slice(0, 11);

  // Formata o valor como CPF
  let newData = limitedValue
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');

  setValue(input, newData);
};
