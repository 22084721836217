import { axios } from '../config/axios';
import security from './security'

const Status = {};

Status.create = (body) => {
  return security.validation(axios.post('/status-agendamento', body));
};

Status.listAll = (page = '', filtros = '') => {

  let filtro = ''

  if (filtros !== '') {
    filtro = '&nome=' + (filtros.nome === undefined ? '' : filtros.nome?.toUpperCase())
  }

  return security.validation(axios.get('/status-agendamento?orderby=sequencia&page_size=10&page=' + page + filtro));
};

Status.listAllAtivos = (page = '', filtros = '') => {

  let filtro = ''

  if (filtros !== '') {
    filtro = '&nome=' + (filtros.nome === undefined ? '' : filtros.nome?.toUpperCase())
    filtro = filtro + '&email=' + (filtros.email === undefined ? '' : filtros.email.toUpperCase())
    filtro = filtro + '&cpf=' + (filtros.cpf === undefined ? '' : filtros.cpf)
    filtro = filtro + '&ativo=' + (filtros.ativo === undefined ? '' : filtros.ativo)
  }

  return security.validation(axios.get('/status-agendamento?orderby=sequencia&ativo=true&page_size=10&page=' + page + filtro));
};

Status.createPhoto = (id, newPhoto) => {
  return security.validation(axios.patch('/status-agendamento/' + id + '/foto', newPhoto,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }));
};

Status.listId = (id) => {
  return security.validation(axios.get('/status-agendamento/' + id));
};

Status.update = (id, body) => {
  return security.validation(axios.patch('/status-agendamento/' + id, body));
};

Status.delete = (id) => {
  return security.validation(axios.delete('/status-agendamento/' + id));
};

Status.createPhoto = (id, newPhoto) => {
  return security.validation(axios.patch('/status-agendamento/' + id + '/imagem', newPhoto,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }));
};

Status.deletePhoto = (id) => {
  return security.validation(axios.delete('/status-agendamento/' + id + '/imagem'));
};


export default Status;
