import React from 'react'
import { Modal, Row, Form, Button } from 'react-bootstrap'
import { IoSearch } from "react-icons/io5";
import InputTecnico from '../Inputs/InputTecnico'

const ModalExamesProntuario = ({ showModal, handleCloseModal, register, handleShowTecnico, setValue, showDeleteButton, handleDelete, currentIndex, salvar, loading, setLoading, getValues }) => {
  return (

    <Modal show={showModal} centered size='xl'>
      <Modal.Header closeButton onHide={handleCloseModal} />
      <Modal.Body>

        <Row className='pb-2'>
          <InputTecnico
            setValue={setValue}
            loading={loading}
            setLoading={setLoading}
            getValues={getValues}
            register={register}
            registerNameId='tecnico_exame_id'
            registerName='tecnico_exame'
          />

          <Form.Group className="col-12 col-lg-2">
            <Form.Label>Data da solicitação</Form.Label>
            <Form.Control size='sm' type="date" {...register('data_solicitacao')} />
          </Form.Group>


        </Row>



        <p className='m-0 p-0' style={{ fontSize: 25, color: 'gray' }}>Cervical</p>
        <div style={{ width: '100%', height: 2, backgroundColor: 'gray' }}></div>

        <Row className='pb-2 mt-2'>
          <Form.Group className="col-12 col-lg-3">
            <Form.Label>AP Transoral</Form.Label>
            <Form.Control size='sm' type='text' {...register('cervical_ap_transoral_descricao')} />
          </Form.Group>
          <Form.Group className="col-12 col-lg-3">
            <Form.Label>AP</Form.Label>
            <Form.Control size='sm' type='text' {...register('cervical_ap_descricao')} />
          </Form.Group>
          <Form.Group className="col-12 col-lg-3">
            <Form.Label>Lateral</Form.Label>
            <Form.Control size='sm' type='text' {...register('cervical_lateral_descricao')} />
          </Form.Group>
          <Form.Group className="col-12 col-lg-3">
            <Form.Label>Oblíquo</Form.Label>
            <Form.Control size='sm' type='text' {...register('cervical_obliquo_descricao')} />
          </Form.Group>
        </Row>

        <p className='m-0 p-0' style={{ fontSize: 25, color: 'gray' }}>Dorsal</p>
        <div style={{ width: '100%', height: 2, backgroundColor: 'gray' }}></div>

        <Row className='pb-2 mt-2'>
          <Form.Group className="col-12 col-lg-3">
            <Form.Label>AP</Form.Label>
            <Form.Control size='sm' type='text' {...register('dorsal_ap_descricao')} />
          </Form.Group>
          <Form.Group className="col-12 col-lg-3">
            <Form.Label>Lateral</Form.Label>
            <Form.Control size='sm' type='text' {...register('dorsal_lateral_descricao')} />
          </Form.Group>
        </Row>

        <p className='m-0 p-0' style={{ fontSize: 25, color: 'gray' }}>Lombar</p>
        <div style={{ width: '100%', height: 2, backgroundColor: 'gray' }}></div>

        <Row className='pb-2 mt-2'>
          <Form.Group className="col-12 col-lg-3">
            <Form.Label>AP Lombopélvica</Form.Label>
            <Form.Control size='sm' type='text' {...register('lombar_ap_lombopelvica_descricao')} />
          </Form.Group>
          <Form.Group className="col-12 col-lg-3">
            <Form.Label>Lateral</Form.Label>
            <Form.Control size='sm' type='text' {...register('lombar_lateral_descricao')} />
          </Form.Group>
          <Form.Group className="col-12 col-lg-3">
            <Form.Label>Oblíquo</Form.Label>
            <Form.Control size='sm' type='text' {...register('lombar_obliquo_descricao')} />
          </Form.Group>
        </Row>

        <p className='m-0 p-0' style={{ fontSize: 25, color: 'gray' }}>Outros</p>
        <div style={{ width: '100%', height: 2, backgroundColor: 'gray' }}></div>

        <Row className='pb-2 mt-2'>
          <Form.Group className="col-12 col-lg-3">
            <Form.Label>1- Nome</Form.Label>
            <Form.Control size='sm' type='text' {...register('outros_1_nome')} />
          </Form.Group>
          <Form.Group className="col-12 col-lg-3">
            <Form.Label>2- Nome</Form.Label>
            <Form.Control size='sm' type='text' {...register('outros_2_nome')} />
          </Form.Group>
          <Form.Group className="col-12 col-lg-3">
            <Form.Label>3- Nome</Form.Label>
            <Form.Control size='sm' type='text' {...register('outros_3_nome')} />
          </Form.Group>
        </Row>
        <Row className='pb-2 mt-2'>
          <Form.Group className="col-12 col-lg-3">
            <Form.Label>1- Descrição</Form.Label>
            <Form.Control size='sm' type='text' {...register('outros_1_descricao')} />
          </Form.Group>
          <Form.Group className="col-12 col-lg-3">
            <Form.Label>2- Descrição</Form.Label>
            <Form.Control size='sm' type='text' {...register('outros_2_descricao')} />
          </Form.Group>
          <Form.Group className="col-12 col-lg-3">
            <Form.Label>3- Descrição</Form.Label>
            <Form.Control size='sm' type='text' {...register('outros_3_descricao')} />
          </Form.Group>
        </Row>

      </Modal.Body>
      <Modal.Footer className={`d-flex ${showDeleteButton === true ? 'justify-content-between' : 'justify-content-end'}`}>
        {showDeleteButton === true ? <Button
          variant="outline-danger"
          // size='sm'
          onClick={() => handleDelete(currentIndex)}
        >
          Excluir
        </Button> : ''}
        <Button onClick={() => salvar()}>Salvar</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalExamesProntuario