const SUBHOST = process.env.REACT_APP_SUBHOST

const URL = {
  index: SUBHOST + '/',
  // home: SUBHOST + '/home',
  home: SUBHOST + '/agenda',

  login: SUBHOST + '/login',
  login_recuperar_senha: SUBHOST + '/login/recuperar-senha',
  login_recuperar_senha_confirmar: SUBHOST + '/login/recuperar-senha/confirmar',
  login_criar_usuario: SUBHOST + '/login/criar-usuario',
  login_confirmar_usuario: SUBHOST + '/registrar/confirmar',

  registrar: SUBHOST + '/registrar',
  registrar_confirmar: SUBHOST + '/registrar/confirmar',

  perfil: SUBHOST + '/perfil',
  perfil_confirmar_email: SUBHOST + 'perfil/confirmar_email',

  pacientes_lista: SUBHOST + '/pacientes',
  pacientes_cadastro: SUBHOST + '/pacientes/cadastro',

  perguntas_lista: SUBHOST + '/perguntas',
  perguntas_cadastro: SUBHOST + '/perguntas/cadastro',

  usuarios_lista: SUBHOST + '/usuarios',
  usuarios_cadastro: SUBHOST + '/usuarios/cadastro',

  calendario: SUBHOST + '/agenda',
  imagem: SUBHOST + '/imagem',
  camera: SUBHOST + '/camera',

  tipo_pagamento_lista: SUBHOST + '/tipo-pagamento',
  tipo_pagamento_cadastro: SUBHOST + '/tipo-pagamento/cadastro',

  tipo_atendimento_lista: SUBHOST + '/tipo-atendimento',
  tipo_atendimento_cadastro: SUBHOST + '/tipo-atendimento/cadastro',

  prontuario_lista: SUBHOST + '/prontuario',
  prontuario_cadastro: SUBHOST + '/prontuario/cadastro',

  tecnico_lista: SUBHOST + '/tecnico',
  tecnico_cadastro: SUBHOST + '/tecnico/cadastro',

  contrato_lista: SUBHOST + '/contratos',
  contrato_cadastro: SUBHOST + '/contratos/cadastro',

  financeiro_lista: SUBHOST + '/financeiro',
  financeiro_cadastro: SUBHOST + '/financeiro/cadastro',

  status_lista: SUBHOST + '/status',
  status_cadastro: SUBHOST + '/status/cadastro',

  versao_cadastro: SUBHOST + '/versao/cadastro',

};

export { URL, SUBHOST };
